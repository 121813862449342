import React from 'react'

const play = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" className={`size-${size}`}>
    <g id="play-button" transform="translate(0 -0.002)">
      <path id="Path_11964" data-name="Path 11964" d="M27.492,19.277l-13-9c-.457-.331-.5-.258-1,0s-1,.439-1,1v18c0,.567.495.744,1,1,.214.108.764,1,1,1,.308,0-.264-.806,0-1l13-9c.393-.29,1-.525,1-1S27.882,19.56,27.492,19.277Z" transform="translate(12.508 9.725)" className={`fill-${color}`}/>
      <path id="Path_11965" data-name="Path 11965" d="M30,0A30,30,0,1,0,60,30,29.993,29.993,0,0,0,30,0Zm0,55A25,25,0,1,1,55,30,25,25,0,0,1,30,55Z" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default play
