import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { UserContext } from '~/contexts'

import LayoutAuth from '~/components/LayoutAuth'
import Button from '~/components/Button'
import Input from '~/components/Input'

import { validation, Blocker } from '~/utils'

import '../styles.scss'

const AuthForgotPassword = () => {
  const history = useHistory()

  const servicesUser = useContext(UserContext)

  // eslint-disable-next-line
  const blocker = new Blocker()
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')

  const onSubmit = e => {
    e.preventDefault()

    setLoading(true)

    const body = {
      email
    }

    servicesUser.exec.forgotPassword({ body })
      .then(response => {
        if(response.ok) {
          toast.success('Verifique em seu email as intruções para recuperar sua senha!')
          history.replace('/auth/login')
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        let errMessage = 'Um erro ocorreu, tente novamente!'
        if (err?.message === 'This email does not exist.') {
          errMessage = 'Email não cadastrado em nossa plataforma!'
        }

        toast.error(errMessage)
      })
      .then(() => setLoading(false))
  }

  useEffect(() => {
    setDisabledSubmit(blocker?.value !== false)
  }, [blocker])

  return (
    <LayoutAuth>
      <h3 className="auth-title">Recuperar Senha</h3>

      <form className="auth-form" onSubmit={onSubmit}>
        <Input
          theme="dark"
          id="auth-forgot-password-email"
          label="Digite seu e-mail"
          placeholder="Ex: seu@email.com"
          name="email"
          value={email}
          onChange={setEmail}
          onValidate={[validation.email, blocker.validation]}
        />

        <Button
          loading={loading}
          disabled={disabledSubmit}
          type="submit"
          color="primary"
          size="medium"
          label="Confirmar e-mail"
          className="mt-3"
        />
      </form>

      <p className="auth-subaction"><Link to="/auth/login">Efetuar login</Link></p>
    </LayoutAuth>
  )
}

export default AuthForgotPassword
