import React from 'react'

const camera = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-9293 7677)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(9293 -7677)" fill="none"/>
      <g id="Group_9842" data-name="Group 9842" transform="translate(9126 -7938)">
        <g id="camera" transform="translate(169 264)">
          <path id="Path_8084" data-name="Path 8084" d="M1,6A2,2,0,0,1,3,4h.93a2,2,0,0,0,1.664-.891l.813-1.219A2,2,0,0,1,8.07,1H11.93a2,2,0,0,1,1.664.891l.813,1.219A2,2,0,0,0,16.07,4H17a2,2,0,0,1,2,2v9a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2Z" transform="translate(-1 -1)" fill="none" className={`stroke-${color}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Path_8085" data-name="Path 8085" d="M13,10a3,3,0,1,1-3-3A3,3,0,0,1,13,10Z" transform="translate(-1 -1)" fill="none" className={`stroke-${color}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>
      </g>
    </g>
  </svg>
)

export default camera
