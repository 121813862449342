import React from 'react'
import ReactPaginate from 'react-paginate'

import './styles.scss'

const Pagination = ({ currentPage, pageCount, onChange }) => (
  <ReactPaginate
    pageCount={pageCount}
    forcePage={currentPage}
    onPageChange={onChange}

    previousLabel="Anterior"
    nextLabel="Próximo"
    breakLabel="..."
    breakClassName="break-me"
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    containerClassName="pagination"
    activeClassName="active"
  />
)

export default Pagination
