import React from 'react'

const dashboard = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" transform="translate(5186 7813)">
      <path id="Path_12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="Path_12049" d="M658,871h6v8h-6Zm14-5h6v13h-6Zm-7-5h6v18h-6Zm-5,12v4h2v-4Zm7-10v14h2V863Zm7,5v9h2v-9Z" transform="translate(-5843 -8672)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default dashboard
