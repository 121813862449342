import React, { useContext, useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useHistory } from 'react-router'

import { UserContext } from '~/contexts'
import { Storage } from '~/services'

const RouteLogged = (props) => {
  const history = useHistory()

  const servicesUser = useContext(UserContext)

  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    if (!Storage.get({ key: 'sessionUser' })?.jwt) {
      history.replace('/auth/login')
      return
    }

    if (props?.role) {
      const roleFromUser = Storage.get({ key: 'sessionUser' })?.user?.role?.id
      if (!props.role.find(role => role === roleFromUser)) {
        history.replace('/')
        return
      }
    }

    setIsLogged(true)
  }, [servicesUser, history, props])

  return (
    isLogged &&
    <Route {...props} />
  )
}

export default RouteLogged
