import React from 'react'

const search = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-6793 17301)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(6793 -17301)" fill="none"/>
      <path id="Union_33" data-name="Union 33" d="M1668.19,10371.6l-3.537-3.537-.033-.035a9.017,9.017,0,1,1,1.412-1.414l.038.036,3.535,3.534a1,1,0,1,1-1.413,1.416ZM1652,10361a7,7,0,0,0,11.88,5.021l.141-.141A7,7,0,1,0,1652,10361Z" transform="translate(5144.052 -27651.949)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default search
