import React from 'react'

const report = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="horas" d="M10,0a10,10,0,1,0,3.827.761A10,10,0,0,0,10,0Zm0,18a8,8,0,1,1,5.657-2.343A8,8,0,0,1,10,18ZM10,4A1,1,0,0,0,9,5V9.42L6.9,10.63a1,1,0,0,0,1,1.74l2.6-1.5.09-.09.16-.13a.863.863,0,0,0,.1-.16.891.891,0,0,0,.08-.17.65.65,0,0,0,.05-.2L11,10V5a1,1,0,0,0-1-1Z" transform="translate(-5185 -7812)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default report
