import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'

import LayoutMain from '~/components/LayoutMain'
import PageTitle from '~/components/PageTitle'
import AddItemCard from '~/components/AddItemCard'
import ItemTraining from '~/components/ItemTraining'
import SideContent from '~/components/SideContent'

import { JourneyContext } from '~/contexts'

import Detail from './Detail'

const Training = () => {
  const servicesJourney = useContext(JourneyContext)

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  const getData = useCallback(async () => {
    setLoading(true)

    const response = await servicesJourney.exec.getTrainings()
    if (!response?.errMessage) {
      setItems(response)
    } else {
      setItems([])
    }

    setLoading(false)
  }, [servicesJourney])

  const handleChangeActiveTraining = (value, id) => {
    const body = {
      active: value
    }

    servicesJourney.exec.updateTraining({ body, id })
      .then(res => getData())
      .catch(err => {
        toast.error('Não foi possível alterar o status do treinamento!')
      })
  }

  useEffect(getData, [getData])

  return (
    <LayoutMain>
      <PageTitle />

      <Row>
        <Col xs="4">
          <AddItemCard
            label="Adicionar vídeo"
            onClick={() => setSelectedItem(true)}
          />
        </Col>
        {
          loading ? (
            <Col xs="8">
              <div className="empty-state">
                <Spinner size="lg" color="dark" />
                <h4 className="mt-3">Obtendo dados...</h4>
              </div>
            </Col>
          ) : (items?.length <= 0) ? (
            <Col xs="8">
              <div className="empty-state">
                <h4>Nenhum registro encontrado!</h4>
              </div>
            </Col>
          ) : (
            items.map((item, key) => (
              <Col xs="4" key={key}>
                <ItemTraining
                  item={item}
                  onChangeStatus={handleChangeActiveTraining}
                  onClick={() => setSelectedItem(item)}
                />
              </Col>
            ))
          )
        }
      </Row>

      <SideContent
        opened={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      >
        {
          selectedItem &&
          <Detail
            content={selectedItem}
            onRefreshAll={() => getData(0)}
            onClose={() => setSelectedItem(null)}
          />
        }
      </SideContent>
    </LayoutMain>
  )
}

export default Training

