import React, { useState, useEffect, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Toggle from '~/components/Toggle'
import Input from '~/components/Input'
import Button from '~/components/Button'

import { JourneyContext } from '~/contexts'
import { validation, Blocker } from '~/utils'

import './styles.scss'

const Detail = ({ content, onRefreshAll, onClose }) => {
  // eslint-disable-next-line
  const blocker = new Blocker()

  const servicesJourney = useContext(JourneyContext)

  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [item] = useState(content)
  const [rule, setRule] = useState(content?.rule || '')
  const [value, setValue] = useState(content?.value || '')
  const [status, setStatus] = useState(content?.status || false)

  const handleChangeActiveDriver = value => {
    setStatus(value)

    if(item?.id) {
      const body = {
        status: value
      }

      updateRule(body)
    }
  }

  const onSubmit = () => {
    const body = {
      rule,
      value
    }

    if(item?.id) {
      updateRule(body)
    } else {
      createRule({ ...body, status })
    }
  }

  const createRule = body => {
    setLoadingSubmit(true)

    servicesJourney.exec.createGiftRule({ body })
      .then(() => {
        toast.success('Regra criada com sucesso!')
        onRefreshAll()
        onClose()
      })
      .catch(err => toast.error('Não foi possível criar a regra!'))
      .then(() => setLoadingSubmit(false))
  }

  const updateRule = body => {
    setLoadingSubmit(true)

    servicesJourney.exec.updateGiftRule({ body, id: item.id })
      .then(() => {
        toast.success('Regra atualizada com sucesso!')
        onRefreshAll()
      })
    .catch(err => toast.error('Não foi possível atualizar a regra!'))
    .then(() => setLoadingSubmit(false))
  }

  useEffect(() => {
    setDisabledSubmit(blocker?.value !== false)
  }, [blocker])

  return (
    <div className="gift-rules-detail">
      <div className="gift">
        <div className="gift-detail">
          <h3 className="gift-name">
            { item.id ? 'Editar' : 'Adicionar' } regra
          </h3>
        </div>
        <div className="gift-action">
          <p>Ativar / Inativar</p>
          <Toggle
            value={status}
            onChange={handleChangeActiveDriver}
          />
        </div>
      </div>

      <Row>
        <Col xs="12">
          <Input
            textarea
            label="Descrição do brinde"
            value={rule}
            onChange={setRule}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs="4">
          <Input
            type="number"
            label="Valor"
            value={value}
            onChange={setValue}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs={{ offset: 4, size: 4 }} className="d-flex align-items-center justify-content-end">
          <Button
            label={`${item.id ? 'Salvar edição' : 'Adicionar'}`}
            color="primary"
            loading={loadingSubmit}
            disabled={disabledSubmit}
            onClick={onSubmit}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Detail
