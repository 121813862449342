import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { UserContext } from '~/contexts'

import LayoutAuth from '~/components/LayoutAuth'
import Button from '~/components/Button'
import Input from '~/components/Input'

import { validation, Blocker } from '~/utils'

import '../styles.scss'

const AuthLogin = () => {
  const history = useHistory()

  const servicesUser = useContext(UserContext)

  // eslint-disable-next-line
  const blocker = new Blocker()
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [loading, setLoading] = useState(false)

  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')

  const onSubmit = e => {
    e.preventDefault()

    setLoading(true)

    const body = {
      password
    }

    const withEmail = isNaN(identifier)

    if (withEmail) {
      body['identifier'] = identifier
    } else {
      body['cpf'] = identifier
    }

    servicesUser.exec
    [withEmail ? 'login' : 'loginCpf']({ body })
      .then(res => {
        if(res?.id) {
          history.push('/')
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        let errMessage = 'Um erro ocorreu, tente novamente!'
        if (err?.message === 'Acesso não autorizado!') {
          errMessage = 'Acesso não autorizado!'
        } else if (err?.message === 'Identifier or password invalid.') {
          errMessage = 'Usuário ou senhas incorretos!'
        }

        toast.error(errMessage)
      })
      .then(() => setLoading(false))
  }

  useEffect(() => {
    setDisabledSubmit(blocker?.value !== false)
  }, [blocker])

  return (
    <LayoutAuth>
      <form className="auth-form" onSubmit={onSubmit}>
        <Input
          theme="dark"
          id="auth-login-identifier"
          label="E-mail ou CPF"
          placeholder="Ex: seu@email.com ou 12345678900"
          name="identifier"
          value={identifier}
          onChange={setIdentifier}
          onValidate={[validation.required, blocker.validation]}
        />

        <Input
          theme="dark"
          id="auth-login-password"
          label="Senha"
          placeholder="••••••••"
          type="password"
          name="password"
          value={password}
          onChange={setPassword}
          onValidate={[validation.required, blocker.validation]}
        />

        <Button
          loading={loading}
          disabled={disabledSubmit}
          type="submit"
          color="primary"
          size="medium"
          label="Entrar"
          className="mt-3"
        />
      </form>

      <p className="auth-subaction">Esqueceu sua senha? <Link to="/auth/recuperar-senha">Recuperar</Link></p>
    </LayoutAuth>
  )
}

export default AuthLogin
