import React from 'react'

const trash = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(496 19450)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(-496 -19450)" fill="none"/>
      <path id="Group_15" data-name="Group 15" d="M7,16a1,1,0,0,0,1-1V9A1,1,0,1,0,6,9v6a1,1,0,0,0,1,1ZM17,4H13V3a3,3,0,0,0-3-3H8A3,3,0,0,0,5,3V4H1A1,1,0,1,0,1,6H2V17a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V6h1a1,1,0,0,0,0-2ZM7,3A1,1,0,0,1,8,2h2a1,1,0,0,1,1,1V4H7Zm7,14a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V6H14Zm-3-1a1,1,0,0,0,1-1V9a1,1,0,0,0-2,0v6a1,1,0,0,0,1,1Z" transform="translate(-494 -19449)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default trash
