import React from 'react'

const check = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-9293 7677)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(9293 -7677)" fill="none"/>
      <g id="Validation_Marker" data-name="Validation Marker" transform="translate(9105 -7717.722)">
        <g id="Frame" transform="translate(189 41.722)" className={`fill-${color}`} stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
          <rect width="20" height="20" stroke="none"/>
          <rect x="0.5" y="0.5" width="19" height="19" fill="none"/>
        </g>
        <path id="Shape" d="M7.167,16.791a1.233,1.233,0,0,1-.971-.475l-4.93-6.163A1.233,1.233,0,1,1,3.208,8.637l3.959,4.919L16.058,2.474A1.233,1.233,0,1,1,18,3.992L8.14,16.318A1.233,1.233,0,0,1,7.167,16.791Z" transform="translate(189.228 42.465)" className={`fill-${color}`}/>
      </g>
    </g>
  </svg>
)

export default check

