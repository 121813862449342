import React, { useState, useEffect, useContext, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Toggle from '~/components/Toggle'
import Input from '~/components/Input'
import DatePicker from '~/components/Datepicker'
import Button from '~/components/Button'
import Icon from '~/components/Icon'

import { JourneyContext, AppContext } from '~/contexts'
import { validation, Blocker, format } from '~/utils'

import placeholder from '~/images/training-placeholder.png'

import './styles.scss'

const Detail = ({ content, onRefreshAll, onClose }) => {
  // eslint-disable-next-line
  const blocker = new Blocker()

  let inputVideo = useRef()
  let inputThumbnail = useRef()

  const servicesJourney = useContext(JourneyContext)
  const servicesApp = useContext(AppContext)

  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  const [item, setItem] = useState(content)
  const [form, setForm] = useState({
    title: content?.title || '',
    description: content?.description || '',
    publishStartDate: content?.publishStartDate ? new Date(content?.publishStartDate+'T12:00:00.000Z') : null,
    publishEndDate: content?.publishEndDate ? new Date(content?.publishEndDate+'T12:00:00.000Z') : null,
    active: content?.active || false,
  })
  const [video, setVideo] = useState((content?.file?.[0] || null))
  const [thumbnail, setThumbnail] = useState((content?.thumbnail || null))

  const handleChangeForm = (field, value) => {
    setForm({ ...form, [field]: value })
  }

  const handleChangeActiveTraining = value => {
    handleChangeForm('active', value)

    if(item?.id) {
      const body = {
        active: value
      }

      updateTraining(body)
    }
  }

  const onSubmit = async () => {
    setLoadingSubmit(true)

    const { title, description, publishStartDate, publishEndDate, active } = form

    const body = {
      title,
      description,
      publishStartDate,
      publishEndDate
    }

    if (video && !video?.id) {
      const response = await servicesApp.exec.postImage({ file: video })

      if (!response?.[0]?.id) {
        setLoadingSubmit(false)
        toast.error('Não foi possível enviar o vídeo!')
        return
      }

      body['file'] = { id: response[0].id }
    }

    if (thumbnail && !thumbnail?.id) {
      const response = await servicesApp.exec.postImage({ file: thumbnail })

      if (!response?.[0]?.id) {
        setLoadingSubmit(false)
        toast.error('Não foi possível enviar a thumbnail!')
        return
      }

      body['thumbnail'] = { id: response[0].id }
    }

    if(item?.id) {
      updateTraining(body)
    } else {
      createTraining({ ...body, active })
    }
  }

  const createTraining = body => {
    servicesJourney.exec.createTraining({ body })
      .then(res => {
        setItem(res)
        toast.success('Treinamento adicionado com sucesso!')
        onRefreshAll()
        onClose()
      })
      .catch(err => toast.error('Não foi possível adicionar o treinamento!'))
      .then(() => setLoadingSubmit(false))
  }

  const updateTraining = body => {
    servicesJourney.exec.updateTraining({ body, id: item.id })
      .then(res => {
        setItem(res)
        toast.success('Treinamento atualizado com sucesso!')
        onRefreshAll()
      })
    .catch(err => toast.error('Não foi possível atualizar o treinamento!'))
    .then(() => setLoadingSubmit(false))
  }

  useEffect(() => {
    setDisabledSubmit((blocker?.value !== false) || !video || !thumbnail)
  }, [blocker, video, thumbnail])

  return (
    <div className="training-detail">
      <div className="preview-image">
        <img
          src={
            (item?.thumbnail?.formats?.small?.url || item?.thumbnail?.url)
              ? format.urlFile(item?.thumbnail?.formats?.small?.url || item?.thumbnail?.url)
              : placeholder
          }
          alt="Treinamento"
        />

        {
          (item.file?.[0]?.url) &&
          <div className="play link" onClick={() => setShowVideo(true)}>
            <Icon name="play" color="white" size="hugest" />
          </div>
        }
      </div>

      <div className="training">
        <div className="training-detail">
          <h3 className="training-name">
            { item.id ? 'Editar' : 'Adicionar' } vídeo
          </h3>
        </div>
        <div className="training-action">
          <p>Ativar / Inativar</p>
          <Toggle
            value={form.active}
            onChange={handleChangeActiveTraining}
          />
        </div>
      </div>

      <Row>
        <Col xs="4">
          <Input
            label="Título"
            value={form.title}
            onChange={value => handleChangeForm('title', value)}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs="4">
          <div
            className="input-file video"
            onClick={() => inputVideo?.current?.click()}
          >
            <div>
              {
                video?.name ? video.name : 'Selecione um vídeo'
              }
            </div>
            <Icon
              name="upload"
              color="black"
            />
          </div>
          <input
            name="img"
            type="file"
            accept="video/*"
            className="d-none"
            ref={inputVideo}
            onChange={e => setVideo(e.target.files[0])}
          />
        </Col>
        <Col xs="4">
          <div
            className="input-file thumbnail"
            onClick={() => inputThumbnail?.current?.click()}
          >
            <div>
              {
                thumbnail?.name ? thumbnail.name : 'Selecione a thumbnail'
              }
            </div>
            <Icon
              name="upload"
              color="black"
            />
          </div>
          <input
            name="img"
            type="file"
            accept="image/*"
            className="d-none"
            ref={inputThumbnail}
            onChange={e => setThumbnail(e.target.files[0])}
          />
        </Col>
        <Col xs="6">
          <DatePicker
            label="Data de publicação"
            placeholder="De"
            value={form.publishStartDate}
            onChange={value => handleChangeForm('publishStartDate', value)}
            dateFormat="dd MMMM yyyy"
            onValidate={[validation.dateRequired, blocker.validation]}
          />
        </Col>
        <Col xs="6">
          <DatePicker
            label="⠀"
            placeholder="Até"
            value={form.publishEndDate}
            onChange={value => handleChangeForm('publishEndDate', value)}
            dateFormat="dd MMMM yyyy"
            onValidate={[validation.dateRequired, blocker.validation]}
          />
        </Col>
        <Col xs="12">
          <Input
            textarea
            label="Descrição do vídeo"
            value={form.description}
            onChange={value => handleChangeForm('description', value)}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs={{ offset: 8, size: 4 }} className="d-flex flex-column">
          <Button
            label={`${item.id ? 'Salvar edição' : 'Adicionar'}`}
            color="primary"
            loading={loadingSubmit}
            disabled={disabledSubmit}
            onClick={onSubmit}
          />
        </Col>
      </Row>

      {
        showVideo &&
        <div className="big-video" onClick={() => setShowVideo(false)}>
          <div
            className="big-video-close"
            onClick={() => setShowVideo(false)}
          >
            <Icon
              name="close"
              color="white"
              size="larger"
            />
          </div>
          <div className="video text-center" onClick={e => e.stopPropagation()}>
            <video src={format.urlFile(item?.file?.[0]?.url)} controls />
          </div>
        </div>
      }
    </div>
  )
}

export default Detail
