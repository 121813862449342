import React, { createContext, useContext } from 'react'

import { UserContext } from './user'

import {
  getCompanies,
  countCompanies,
  createCompany,
  editCompany,

  getJourneysByDate,

  getJourneys,
  editJourney,
  countJourneys,

  newCostReport,
  editCostReport,
  getCostReport,

  getHelps,
  countHelps,
  countHelpsWaiting,
  getHelp,
  updateHelp,

  getTrainings,
  createTraining,
  updateTraining,

  getGifts,
  createGift,
  updateGift,

  getGiftRules,
  updateGiftRule,
  createGiftRule
} from '~/services'

export const JourneyContext = createContext()

export function JourneyProvider({ children }) {
  const serviceUser = useContext(UserContext)

  const exec = {
    getCompanies(params) {
      return getCompanies({ ...params, token: serviceUser.state?.jwt })
    },
    countCompanies(params) {
      return countCompanies({ ...params, token: serviceUser.state?.jwt })
    },
    createCompany(params) {
      return createCompany({ ...params, token: serviceUser.state?.jwt })
    },
    editCompany(params) {
      return editCompany({ ...params, token: serviceUser.state?.jwt })
    },
    getJourneys(params) {
      return getJourneys({
        ...params,
        company: serviceUser.state?.user?.role?.id === 4 ? serviceUser.state?.user?.company?.id : null,
        token: serviceUser.state?.jwt
      })
    },
    getJourneysByDate(params) {
      return getJourneysByDate({
        ...params,
        company: serviceUser.state?.user?.role?.id === 4 ? serviceUser.state?.user?.company?.id : null,
        token: serviceUser.state?.jwt
      })
    },
    editJourney(params) {
      return editJourney({ ...params, token: serviceUser.state?.jwt })
    },
    countJourneys(params) {
      return countJourneys({
        ...params,
        company: serviceUser.state?.user?.role?.id === 4 ? serviceUser.state?.user?.company?.id : null,
        token: serviceUser.state?.jwt
      })
    },
    newCostReport(params) {
      return newCostReport({
        ...params,
        token: serviceUser.state?.jwt
      })
    },
    editCostReport(params) {
      return editCostReport({
        ...params,
        token: serviceUser.state?.jwt
      })
    },
    getCostReport(params) {
      return getCostReport({
        ...params,
        token: serviceUser.state?.jwt
      })
    },
    getHelps(params) {
      return getHelps({
        ...params,
        company: serviceUser.state?.user?.role?.id === 4 ? serviceUser.state?.user?.company?.id : null,
        token: serviceUser.state?.jwt
      })
    },
    countHelps(params) {
      return countHelps({
        ...params,
        company: serviceUser.state?.user?.role?.id === 4 ? serviceUser.state?.user?.company?.id : null,
        token: serviceUser.state?.jwt
      })
    },
    countHelpsWaiting(params) {
      return countHelpsWaiting({
        ...params,
        company: serviceUser.state?.user?.role?.id === 4 ? serviceUser.state?.user?.company?.id : null,
        token: serviceUser.state?.jwt
      })
    },
    getHelp(params) {
      return getHelp({ ...params, token: serviceUser.state?.jwt })
    },
    updateHelp(params) {
      return updateHelp({ ...params, token: serviceUser.state?.jwt })
    },
    getTrainings(params) {
      return getTrainings({ ...params, token: serviceUser.state?.jwt })
    },
    createTraining(params) {
      return createTraining({ ...params, token: serviceUser.state?.jwt })
    },
    updateTraining(params) {
      return updateTraining({ ...params, token: serviceUser.state?.jwt })
    },
    getGifts(params) {
      return getGifts({ ...params, token: serviceUser.state?.jwt })
    },
    createGift(params) {
      return createGift({ ...params, token: serviceUser.state?.jwt })
    },
    updateGift(params) {
      return updateGift({ ...params, token: serviceUser.state?.jwt })
    },
    getGiftRules(params) {
      return getGiftRules({ ...params, token: serviceUser.state?.jwt })
    },
    updateGiftRule(params) {
      return updateGiftRule({ ...params, token: serviceUser.state?.jwt })
    },
    createGiftRule(params) {
      return createGiftRule({ ...params, token: serviceUser.state?.jwt })
    }
  }

  return (
    <JourneyContext.Provider value={{ exec }}>
      { children }
    </JourneyContext.Provider>
  )
}
