import React, { createContext, useContext } from 'react'

import { UserContext } from './user'

import {
  postImage,
  getTerms,
  getPrivacy
} from '~/services'

export const AppContext = createContext()

export function AppProvider({ children }) {
  const serviceUser = useContext(UserContext)

  const exec = {
    postImage(params) {
      return postImage({ ...params, token: serviceUser.state?.jwt })
    },
    getTerms() {
      return getTerms()
    },
    getPrivacy() {
      return getPrivacy()
    }
  }

  return (
    <AppContext.Provider value={{ exec }}>
      { children }
    </AppContext.Provider>
  )
}
