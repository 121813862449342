import React from 'react'
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap'

import Icon from '~/components/Icon'
import Toggle from '~/components/Toggle'

import { format } from '~/utils'

import './styles.scss'

const ItemTraining = ({
  item,
  onChangeStatus,
  onClick
}) => (
  <div onClick={() => onClick?.()} className="link">
    <Card className="item-training-card">
      {
        item.thumbnail?.formats?.small?.url || item.thumbnail?.url ? (
            <CardImg
              top width="100%" height="250"
              src={format.urlFile(item.thumbnail?.formats?.small?.url || item.thumbnail?.url)}
              alt={item.title}
            />
        ) : (
            <div className="placeholder-image" />
        )
      }
      <CardBody>
        <div className="d-flex flex-row">
          <CardTitle tag="p" className="title">
            { item.title }
          </CardTitle>
          <div onClick={e => e.stopPropagation()}>
            <Toggle
              value={item.active}
              onChange={(value) => onChangeStatus(value, item.id)}
            />
          </div>
        </div>
        <CardText className="description">
          { item?.description }
        </CardText>
      </CardBody>

      {
        (item.thumbnail?.formats?.small?.url || item.thumbnail?.url) &&
        <div className="play">
          <Icon name="play" color="white" size="hugest" />
        </div>
      }
    </Card>
  </div>
)

export default ItemTraining
