import React from 'react'

const download = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-6793 17301)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(6793 -17301)" fill="none"/>
      <path id="Path_12050" data-name="Path 12050" d="M1493,2848h5l-6,6-6-6h5v-7h2Zm-9,9h16v-7h2v8a.99.99,0,0,1-1,1h-18a.99.99,0,0,1-1-1v-8h2Z" transform="translate(5312 -20140)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default download
