import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { UserContext } from '~/contexts'

import LayoutAuth from '~/components/LayoutAuth'
import Button from '~/components/Button'
import Input from '~/components/Input'

import { validation, Blocker } from '~/utils'

import '../styles.scss'

const AuthResetPassword = () => {
  const history = useHistory()

  const code = useParams()?.code

  if (!code) {
    toast.info('Código para recuperar sua senhã não foi encontrado!')
    history.replace('/auth/login')
  }

  const servicesUser = useContext(UserContext)

  // eslint-disable-next-line
  const blocker = new Blocker()
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [loading, setLoading] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const onSubmit = e => {
    e.preventDefault()

    setLoading(true)

    const body = {
      code,
      password,
      passwordConfirmation
    }

    servicesUser.exec.resetPassword({ body })
      .then(response => {
        if(response?.user?.id) {
          toast.success('Sua senha foi atualizada com sucesso!')
          history.replace('/auth/login')
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        let errMessage = 'Um erro ocorreu, tente novamente!'
        if (err?.message === 'Passwords do not match.') {
          errMessage = 'As senhas informadas não conferem!'
        } else if (err?.message === 'Incorrect code provided.') {
          errMessage = 'O código informado é inválido! Solicite uma nova recuperação de senha!'
          history.replace('/auth/recuperar-senha')
        }

        toast.error(errMessage)
      })
      .then(() => setLoading(false))
  }

  useEffect(() => {
    setDisabledSubmit(blocker?.value !== false)
  }, [blocker])

  return (
    <LayoutAuth>
      <h3 className="auth-title">Recuperar Senha</h3>

      <form className="auth-form" onSubmit={onSubmit}>
        <Input
          theme="dark"
          id="auth-reset-password"
          label="Nova senha"
          placeholder="••••••••"
          type="password"
          name="password"
          value={password}
          onChange={setPassword}
          onValidate={[validation.password, blocker.validation]}
        />

        <Input
          theme="dark"
          id="auth-reset-password-confirm"
          label="Confirme a senha"
          placeholder="••••••••"
          type="password"
          name="password"
          value={passwordConfirmation}
          onChange={setPasswordConfirmation}
          toConfirm={password}
          onValidate={[validation.passwordConfirm, blocker.validation]}
        />

        <Button
          loading={loading}
          disabled={disabledSubmit}
          type="submit"
          color="primary"
          size="medium"
          label="Confirmar e-mail"
          className="mt-3"
        />
      </form>
    </LayoutAuth>
  )
}

export default AuthResetPassword
