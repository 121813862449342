import moment from "moment"
import * as XLSX from 'xlsx'
const getFilledArray = (number, string) => number > 0 ? Array(number).fill(string || '') : []

const addLogWarningHeader = (data, {
  dayMealCount,
  dayStopCount,
  dayWaitCount
}) => [
    [
      'Empresa',
      'Motorista',
      ...getFilledArray(dayMealCount),
      ...getFilledArray(dayStopCount),
      ...getFilledArray(dayWaitCount),
      ''
    ],
    [
      data?.company || '',
      data?.driver || '',
      ...getFilledArray(dayMealCount),
      ...getFilledArray(dayStopCount),
      ...getFilledArray(dayWaitCount),
      ''
    ]
  ]


const addLogModelHeader = ({
  dayMealCount,
  dayStopCount,
  dayWaitCount
}) => [
    'Motorista',
    'Empresa',
    'DATA',
    'Início jornada',
    ...getFilledArray(dayStopCount, 'Tempo de descanso (30 minutos a cada 4 horas)'),
    ...getFilledArray(dayMealCount, 'Intervalo para refeição (mínimo 1 hora)'),
    ...getFilledArray(dayWaitCount, 'Tempo de espera (carga/descarga ou posto fiscal)'),
    'Final jornada'
  ]

const formatDate = ({ begins_at, ends_at }) => `${moment(begins_at).format("HH:mm:ss")} - ${moment(ends_at).format("HH:mm:ss")}`

const getAddLogBody = (data, {
  dayMealCount,
  dayStopCount,
  dayWaitCount
}) => data.map(({
  date,
  begins_at,
  ends_at,
  stops,
  userPermissions
}) => {

  const coffeStops = stops.filter(({ mode }) => mode === 'coffee')
  const mealStops = stops.filter(({ mode }) => mode === 'meal')
  const waitStops = stops.filter(({ mode }) => mode === 'wait')
  const coffeDiff = dayStopCount - coffeStops.length
  const mealDiff = dayMealCount - mealStops.length
  const waitDiff = dayWaitCount - waitStops.length
  const row = [
    userPermissions?.name || '',
    userPermissions?.company || '',
    moment(date).format("DD/MM/yyyy"),
    moment(begins_at).format("HH:mm:ss"),
    ...coffeStops.map(formatDate),
    ...getFilledArray(coffeDiff, ''),
    ...mealStops.map(formatDate),
    ...getFilledArray(mealDiff, ''),
    ...waitStops.map(formatDate),
    ...getFilledArray(waitDiff, ''),
    ends_at ? moment(ends_at).format("HH:mm:ss") : ''
  ]

  return row
})

export const generateAddLogModel = (data, filename) => {
  let stops = data.map((item) => {
    return item?.stops || []
  })

  // console.log("🐌🧲 ~ file: generate-xlsx.js:58 ~ generateAddLogModel ~ stops:", stops)
  let dayMealCount = 0
  let dayStopCount = 0
  let dayWaitCount = 0
  stops.map((item) => {
    dayMealCount = Math.max(item.filter(({ mode }) => mode === 'meal').length, dayMealCount)
    dayStopCount = Math.max(item.filter(({ mode }) => mode === 'coffee').length, dayStopCount)
    dayWaitCount = Math.max(item.filter(({ mode }) => mode === 'wait').length, dayWaitCount)
  })

  const body = [
    addLogModelHeader(
      {
        dayMealCount,
        dayStopCount,
        dayWaitCount
      }
    )
  ].concat(getAddLogBody(data, {
    dayMealCount,
    dayStopCount,
    dayWaitCount
  }))
  generateAndSaveXLSX(body, filename)
}

async function generateAndSaveXLSX(body, filename = '') {
  let wb = XLSX.utils.book_new()
  let ws = XLSX.utils.aoa_to_sheet(body)
  XLSX.utils.book_append_sheet(wb, ws, "History")
  XLSX.writeFile(wb, `${filename || 'jornadas_exportadas'}.xlsx`)

}

const sascarHeader = [
  'Empresa',
  'ID motorista',
  'Nome motorista',
  // 'Evento',
  'Data início',
  // 'Localização inicial',
  // 'Km inicial',
  'Data final',
  // 'Distância percorrida',
  // 'km rodado',
  // 'Descanso dia anterior',
  'Total jornada',
  'Total condução',
  'Total espera',
  'Parada descanso',
  'Total refeição',
  // 'Max cond continua',
  // 'Duração',
  // 'Placa'
]

const customTableHeader = [
  'Data',
  'Início',
  'Fim',
  'Normais',
  'Normais Noturnas',
  'Extras',
  'Extras Noturnas',
  'Feriados e DSR',
  'Feriados e DSR Noturna',
  'Espera 30%',
  'Faltantes',
  'Refeição Não Apontada',
  'Total de Horas Extras',
  'Horas de Repouso',
  'Horas de Condução',
  'Horas de Descanso',
  'Refeição'
]

const insertOptionalZero = (number) => `0${number}`.slice(-2)

const getHoursMin = (minutes = 0) => `${insertOptionalZero(parseInt(minutes / 60))}:${insertOptionalZero(parseInt(minutes % 60))}`

const getSascarBodyData = (data) => {
  const body = []

  data.forEach(({
    begins_at,
    ends_at,
    stops,
    userPermissions
  }) => {

    let totalCoffee = 0
    let totalWait = 0
    let totalMeal = 0
    stops.forEach((item) => {
      const { mode } = item
      const beginsAt = item.begins_at
      const endsAt = item.ends_at
      const difference = Math.abs(moment(endsAt).diff(moment(beginsAt), 'minutes'))
      // console.log("🐌🧲 ~ file: generate-xlsx.js:191 ~ stops.forEach ~ difference:", difference)
      if (mode === 'coffee') {
        totalCoffee += difference
        return
      }
      if (mode === 'meal') {
        totalMeal += difference
        return
      }
      totalWait += difference
    })

    const totalDifference = ends_at ?
      Math.abs(moment(ends_at).diff(moment(begins_at), 'minutes'))
      : ''
    const row = [
      userPermissions.company || '',
      userPermissions?.id || '',
      userPermissions?.name,
      // 'Início de viagem',
      moment(begins_at).format('DD/MM/yyyy'),
      ends_at ? moment(ends_at).format('DD/MM/yyyy') : '',
      typeof totalDifference === 'number' ?
        getHoursMin(totalDifference)
        : '',
      typeof totalDifference === 'number' ?
        getHoursMin(totalDifference - totalWait - totalCoffee - totalMeal)
        : '',
      getHoursMin(totalWait),
      getHoursMin(totalCoffee),
      getHoursMin(totalMeal)
    ]
    body.push(row)
  })

  return body
}

const getCustomTableBody = (data) => {
  const body = [];

  data.forEach(({
    begins_at,
    ends_at,
    stops,
    userPermissions
  }) => {

    let totalCoffee = 0;
    let totalWait = 0;
    let totalMeal = 0;
    let totalExtras = 0;
    let totalRest = 0;
    let totalDriving = 0;
    let totalBreak = 0;

    stops.forEach((item) => {
      const { mode } = item;
      const beginsAt = item.begins_at;
      const endsAt = item.ends_at;
      const difference = Math.abs(moment(endsAt).diff(moment(beginsAt), 'minutes'));

      if (mode === 'coffee') {
        totalCoffee += difference;
        return;
      }
      if (mode === 'meal') {
        totalMeal += difference;
        return;
      }
      if (mode === 'extra') {
        totalExtras += difference;
        return;
      }
      if (mode === 'rest') {
        totalRest += difference;
        return;
      }
      if (mode === 'driving') {
        totalDriving += difference;
        return;
      }
      if (mode === 'break') {
        totalBreak += difference;
        return;
      }
      totalWait += difference;
    });

    const totalDifference = ends_at ?
      Math.abs(moment(ends_at).diff(moment(begins_at), 'minutes'))
      : '';

    const row = [
      moment(begins_at).format('DD/MM/yyyy'), // Data
      moment(begins_at).format('HH:mm'), // Início
      ends_at ? moment(ends_at).format('HH:mm') : '', // Fim
      getHoursMin(totalDifference - totalExtras - totalRest - totalDriving - totalBreak), // Normais
      '', // Normais Noturnas (implementação depende de regras específicas)
      getHoursMin(totalExtras), // Extras
      '', // Extras Noturnas (implementação depende de regras específicas)
      '', // Feriados e DSR (implementação depende de regras específicas)
      '', // Feriados e DSR Noturna (implementação depende de regras específicas)
      getHoursMin(totalWait * 0.3), // Espera 30%
      '', // Faltantes (implementação depende de regras específicas)
      getHoursMin(totalMeal), // Refeição Não Apontada
      '', // Total de Horas Extras (implementação depende de regras específicas)
      getHoursMin(totalRest), // Horas de Repouso
      getHoursMin(totalDriving), // Horas de Condução
      getHoursMin(totalBreak), // Horas de Descanso
      getHoursMin(totalCoffee) // Refeição
    ];

    body.push(row);
  });

  return body;
};


export const generateSascarModel = (data, fileName) => {

  const body = [
    [
      ...sascarHeader
    ]
  ].concat(getSascarBodyData(data))
  generateAndSaveXLSX(body, fileName)
}

export const generateCustomTable = (data, filename) => {
  const body = [
    [
      ...customTableHeader
    ],
    ...getCustomTableBody(data)
  ]
  generateAndSaveXLSX(body, filename)
}