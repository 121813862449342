import React from 'react'
import InputMask from 'react-input-mask'

import Icon from '~/components/Icon'

import './styles.scss'

const Input = ({
  inputClassName,
  className,
  textarea,
  mask,
  id,
  label,
  name,
  onChange,
  placeholder,
  required,
  theme = 'light',
  type,
  value,
  disabled,
  onValidate = [],
  toConfirm,
  icon,
  ...rest
}) => {
  const errorMessage = onValidate.reduce((hasError, condition) => {
    return condition((toConfirm ? { value, toConfirm } : value), hasError) || hasError
  }, false)

  return (
    <div className={`input input-${theme} ${className || ''}`}>
      {
        label &&
        <label htmlFor={id}>{ label }</label>
      }

      <div className="input-section">
        {
          textarea ? (
            <textarea
              id={id}
              name={name}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              value={value || ''}
              onChange={e => onChange(e.target.value)}
              autoComplete="off"
              rows="6"
              className={inputClassName || ''}
              {...rest}
            />
          ) : (
            mask ? (
                <InputMask
                  mask={mask}
                  id={id}
                  type={type}
                  name={name}
                  disabled={disabled}
                  required={required}
                  placeholder={placeholder}
                  value={value || ''}
                  onChange={e => onChange(e.target.value)}
                  autoComplete="off"
                  className={inputClassName || ''}
                  {...rest}
                />
            ) : (
              <input
                id={id}
                type={type}
                name={name}
                disabled={disabled}
                required={required}
                placeholder={placeholder}
                value={value || ''}
                onChange={e => onChange(e.target.value)}
                autoComplete="off"
                className={inputClassName || ''}
                {...rest}
              />
            )
          )
        }

        {
          icon &&
          <Icon
            name={icon}
            size="large"
            color={theme === 'light' ? 'secondary-dark' : 'white'}
            className={`input-icon ${theme}`}
          />
        }
      </div>

      {
        (errorMessage && value !== '')
          ? <div className="error-message">{ errorMessage }</div>
          : null
      }
    </div>
  )
}

export default Input
