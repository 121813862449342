import React from 'react'
import { Row, Col } from 'reactstrap'

import LayoutTerms from '~/components/LayoutTerms'

const Support = () => {
  return (
    <LayoutTerms>
      <Row>
        <Col xs="12">
          <h3 className="mt-3 text-center">Suporte</h3>
        </Col>

        <Col
          lg={{ size: 6, offset: 3 }}
          md={{ size: 8, offset: 2 }}
          xs={{ size: 12, offset: 0 }}
          className="mt-5"
        >
          <h5>Qual é a principal finalidade do app?</h5>
          <p className="mb-5">Bino app, uma ferramenta desenvolvida para ajudar a gerenciar o cartão de ponto, itinerário diário, socorro e treinamento do motorista profissional, obedecendo as leis vigentes no pais.</p>

          <h5>Como me cadastro como motorista?</h5>
          <p className="mb-5">Você deve ser motorista de uma empresa parceira do Bino.</p>

          <h5>O que acontece ao realizar uma jornada pelo app?</h5>
          <p className="mb-5">Em uma jornada, é possível obter os horários de ínicio e final de jornada, bem como horário de suas pausas, e suas respectivas localizações.</p>

          <h5>Como solicitar ajuda durante uma jornada?</h5>
          <p className="mb-5">A tecla S.O.S enviará ao gerente da empresa, uma descrição do problema ocorrido e sua localização para que a empresa possa lhe auxiliar.</p>

          <h5>Como justificar uma falta?</h5>
          <p className="mb-5">Em seu perfil temos um menu dedicado para que seja informado o motivo e bem como anexar seu atestado médico ou outro tipo de documento que possa justificar sua ausência.</p>

          <h5>Canal de atendimento</h5>
          <p className="mb-5">Sempre que precisar de ajuda, registrar uma crítica, sugestão ou elogio, sinta-se à vontade para nos enviar um email para <b><a href="mailto:maisqueponto@gmail.com">maisqueponto@gmail.com</a></b>.</p>
        </Col>
      </Row>
    </LayoutTerms>
  )
}

export default Support
