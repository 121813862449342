let lastRequest = {}
let lastTime = new Date()
let lastResponse = {}

export function preventBomb(request) {
  const isSameRequest = requestCheck(request, lastRequest)
  const timeBetween = new Date() - lastTime
  const isShortTime = timeBetween < 500

  if(isSameRequest && isShortTime) {
    return true
  }

  lastRequest = request
  lastTime = new Date()

  return false
}

export function saveLastResponse(response) {
  lastResponse = response
}

export function getLastResponse() {
  return lastResponse
}

function requestCheck(request1, request2) {
  if(
    request1.destination === request2.destination &&
    request1.request.body === request2.request.body &&
    request1.request.method === request2.request.method
  ) {
    return true
  } else {
    return false
  }
}
