import React from 'react'

const phone = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-9293 7677)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(9293 -7677)" fill="none"/>
      <path id="Path_12050" data-name="Path 12050" d="M1098.786,1210.387a12.72,12.72,0,0,0,4.828,4.828l1.076-1.5a1.243,1.243,0,0,1,.721-.489,1.261,1.261,0,0,1,.868.122,13.833,13.833,0,0,0,5.6,1.662,1.236,1.236,0,0,1,.795.391,1.194,1.194,0,0,1,.33.831v5.451a1.241,1.241,0,0,1-1.1,1.222c-.648.061-1.3.1-1.956.1A18.945,18.945,0,0,1,1091,1204.056c0-.66.037-1.308.1-1.955a1.241,1.241,0,0,1,1.222-1.1h5.451a1.193,1.193,0,0,1,.831.33,1.236,1.236,0,0,1,.391.795,13.832,13.832,0,0,0,1.662,5.6,1.262,1.262,0,0,1,.122.868,1.243,1.243,0,0,1-.489.721Zm-3.092-.807,2.322-1.65a16.486,16.486,0,0,1-1.332-4.485h-3.227c-.012.208-.012.4-.012.611a16.494,16.494,0,0,0,16.5,16.5c.208,0,.4,0,.611-.012v-3.227a16.49,16.49,0,0,1-4.485-1.332l-1.65,2.322a17.732,17.732,0,0,1-1.943-.917l-.073-.049a15.249,15.249,0,0,1-5.744-5.744l-.049-.073A17.7,17.7,0,0,1,1095.693,1209.58Z" transform="translate(8202 -8878)" className={`fill-${color}`} />
    </g>
  </svg>
)

export default phone
