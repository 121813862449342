import React from 'react'

const journey = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="Union_33" data-name="Union 33" d="M1651,10372a1,1,0,0,1-1-1v-16a1,1,0,0,1,1-1h4v-1a1,1,0,1,1,2,0v1h6v-1a1,1,0,1,1,2,0v1h4a1,1,0,0,1,1,1v16a1,1,0,0,1-1,1Zm1-2h16v-8h-16Zm0-10h16v-4h-3v1a1,1,0,0,1-2,0v-1h-6v1a1,1,0,0,1-2,0v-1h-3Zm12,6v-2h2v2Zm-5,0v-2h2v2Zm-5,0v-2h2v2Z" transform="translate(-6835 -18164)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default journey
