import { APP_API_URL } from '~/config'

import { DateUtils } from './date'

export const format = {
  removeMask: value => isNaN(value) ? value?.replace(/[^\d]+/g, '') : value,

  dateToApi: date => DateUtils.parseDate(date),

  urlFile: path => {
    const baseUrl = APP_API_URL
    return baseUrl + path
  },

  cpf: cpf => {
    if(cpf.length !== 11) return false
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
  },

  cnpj: cnpj => {
    if (cnpj.length !== 14) return false
    cnpj = cnpj.replace(/\D/g, "")
    cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2")
    cnpj = cnpj.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cnpj
  },

  phone: phone => {
    if (phone.length !== 10 && phone.length !== 11) return false
    phone = phone.replace(/\D/g, "")
    phone = phone.replace(/^(\d)/, "($1")
    phone = phone.replace(/(.{3})(\d)/, "$1) $2")
    if (phone.length === 9) {
      phone = phone.replace(/(.{1})$/, "-$1")
    } else if (phone.length === 10) {
      phone = phone.replace(/(.{2})$/, "-$1")
    } else if (phone.length === 11) {
      phone = phone.replace(/(.{3})$/, "-$1")
    } else if (phone.length === 12) {
      phone = phone.replace(/(.{4})$/, "-$1")
    } else if (phone.length > 12) {
      phone = phone.replace(/(.{4})$/, "-$1")
    }
    return phone
  },

  money: (value, m = 'R$', n = 2, x = 3, s = '.', c = ',') => {
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
    let num = Number(value).toFixed(Math.max(0, ~~n))

    return m + ' ' + (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
  }
}
