import { RegexExpressions } from './regex'

const validation = {
  cpf: (cpf = '') => {
    const invalid = 'O CPF não é valido!'

    cpf = cpf?.replace(/[^\d]+/g, '')
    if (
      cpf?.length !== 11
      || cpf === '00000000000'
      || cpf === '11111111111'
      || cpf === '22222222222'
      || cpf === '33333333333'
      || cpf === '44444444444'
      || cpf === '55555555555'
      || cpf === '66666666666'
      || cpf === '77777777777'
      || cpf === '88888888888'
      || cpf === '99999999999'
    ) return invalid

    let sum = 0, rest

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    rest = (sum * 10) % 11
    if ((rest === 10) || (rest === 11)) rest = 0
    if (rest !== parseInt(cpf.substring(9, 10))) return invalid

    sum = 0

    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    rest = (sum * 10) % 11
    if ((rest === 10) || (rest === 11)) rest = 0
    if (rest !== parseInt(cpf.substring(10, 11))) return invalid

    return false
  },

  cnpj: (cnpj = '') => {
    const invalid = 'O CNPJ não é valido!'

    let resultado

    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (
      cnpj.length != 14
      || cnpj == '00000000000000'
      || cnpj == '11111111111111'
      || cnpj == '22222222222222'
      || cnpj == '33333333333333'
      || cnpj == '44444444444444'
      || cnpj == '55555555555555'
      || cnpj == '66666666666666'
      || cnpj == '77777777777777'
      || cnpj == '88888888888888'
      || cnpj == '99999999999999'
    ) return invalid

    let tamanho = cnpj.length - 2,
      numeros = cnpj.substring(0, tamanho),
      digitos = cnpj.substring(tamanho),
      soma = 0,
      pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) return invalid

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) return invalid

    return false
  },

  email: (email = '') =>
    !RegexExpressions.email.test(email) ? 'O email não é valido!' : false,

  fullName: (name = '') => {
    const fulln = name?.split(' ')
    return !(fulln?.length > 1 && fulln[0]?.length >= 2 && fulln[1]?.length >= 2) ? 'Digite nome e sobrenome!' : false
  },

  password: (password = '') =>
    password?.length < 6 ? 'Senha muito curta. Use 6 digitos ou mais!' : false,

  passwordConfirm: ({ value, toConfirm }) =>
    value !== toConfirm ? 'Senha e confirmação não conferem!' : false,

  phoneNumber: (phoneNumber = '') => {
    if (phoneNumber && RegexExpressions.phoneNumber.test(phoneNumber)){
      phoneNumber = phoneNumber.replace(/[^\d]/g, '')
      const validDDDs = ['11', '12', '13', '14', '15', '16', '17', '18', '19', '21', '22', '24', '27', '28', '31', '32', '33', '34', '35', '37', '38', '41', '42', '43', '44', '45', '46', '47', '48', '49', '51', '53', '54', '55', '61', '62', '63', '64', '65', '66', '67', '68', '69', '71', '73', '74', '75', '77', '79', '81', '82', '83', '84', '85', '86', '87', '88', '89', '91', '92', '93', '94', '95', '96', '97', '98', '99']
      const ddd = phoneNumber.slice(0, 2)

      const isValid = validDDDs.find(item => item === ddd)
      if(isValid) return false
    }

    return 'O telefone informado não é valido!'
  },

  required: (value = '') =>
    !value?.length ? 'Campo obrigatório!' : false,

  dateRequired: (value) =>
    (!value && value !== null) ? 'Campo obrigatório!' : false,

  driversLimit: (limit = '') =>
    limit < 20 ? 'O limite mínimo é de 20 motoristas!' : false,
}

export default validation
