import React, { useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import GoogleMapReact from 'google-map-react'
import { toast } from 'react-toastify'

import Button from '~/components/Button'
import Input from '~/components/Input'
import Icon from '~/components/Icon'

import avatar from '~/images/avatar.jpg'

import { JourneyContext } from '~/contexts'
import { exportData, format, DateUtils } from '~/utils'
import { API_KEY_GOOGLE_MAPS } from '~/config'

import './styles.scss'

const Detail = ({ content, onRefreshAll, onClose }) => {
  const servicesJourney = useContext(JourneyContext)

  const [item, setItem] = useState(content)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const updateItem = () => {
    servicesJourney.exec.getHelp({ id: content.id })
      .then(res => setItem(res))
      .catch(err => {
        onClose()
        toast.error('Falha ao obter informações do motorista!')
      })
  }

  const resolveHelp = () => {
    if(item.status !== 'waiting') return

    setLoadingSubmit(true)

    const body = {
      status: 'solved'
    }

    servicesJourney.exec.updateHelp({ body, id: item.id })
      .then(() => {
        toast.success('Ocorrência finalizada com sucesso!')
        updateItem()
        onRefreshAll()
      })
      .catch(err => toast.error('Não foi possível finalizar a ocorrência!'))
      .then(() => setLoadingSubmit(false))
  }

  const handleExport = () => {
    exportData(item, `ocorrencia-${item.userPermissions.name.replaceAll(' ', '_')}-${format.dateToApi(new Date(item.begins_at))}`)
  }

  return (
    <div className="help-detail">
      <div className="user">
        <div className="user-detail">
          <div className="user-avatar">
            <img
              src={
                item.userPermissions?.avatarImage?.url
                  ? format.urlFile(item.userPermissions?.avatarImage?.url)
                  : avatar
              }
              alt={item.userPermissions.name}
            />
          </div>
          <h3 className="user-name">
            {item.userPermissions.name}
            <br />
            #{item.id}{' '}
            {
              item.status === 'waiting' && <span className="badge badge-danger">Pendente</span>
            }
            {
              item.status === 'solved' && <span className="badge badge-success">Atendido</span>
            }
            {
              item.status === 'canceled' && <span className="badge badge-success">Cancelado</span>
            }
          </h3>
        </div>
        <div className="user-action">
          <Button
            outline
            label="Exportar"
            icon="download"
            color="primary"
            onClick={() => handleExport()}
          />
        </div>
      </div>


      <Row>
        <Col xs="6">
          <Input
            label="Data"
            value={DateUtils.fullDate(new Date(item.begins_at))}
            disabled
            inputClassName="text-capitalize"
          />
        </Col>
        <Col xs="6">
          <Input
            label="Horário"
            value={DateUtils.hourMinutes(new Date(item.begins_at))}
            disabled
          />
        </Col>
        <Col xs="12">
          <Input
            label="Localização"
            value={item.location || 'Não informada'}
            disabled
          />
        </Col>
        <Col xs="12" className="mb-3">
          <Input
            label="Descrição da ocorrência"
            value={item.description || 'Não informada'}
            disabled
            textarea
          />
        </Col>
        <Col xs="8" className="fw-bold text-larger d-flex align-items-center">
          <Icon
            name="phone"
            color="primary"
            className="me-3"
          />
          { format.phone(item.userPermissions.phone) }
        </Col>
        <Col xs="4" className="d-flex justify-content-end flex-column">
          {
            (item.status === 'waiting') &&
            <Button
              label="Finalizar ocorrência"
              color="primary"
              onClick={() => resolveHelp()}
              loading={loadingSubmit}
            />
          }
        </Col>
      </Row>

      <div className="detail-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY_GOOGLE_MAPS }}
          defaultCenter={{
            lat: item?.locationCoords?.latitude,
            lng: item?.locationCoords?.longitude,
          }}
          defaultZoom={11}
        >
          <Icon
            lat={item?.locationCoords?.latitude}
            lng={item?.locationCoords?.longitude}
            name="locationPin"
            color="primary"
          />
        </GoogleMapReact>
      </div>

    </div>
  )
}

export default Detail
