import React from 'react'

const driver = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="Path_12049" data-name="Path 12049" d="M1784.97,1700a3.5,3.5,0,0,1-5.76,2.15,3.444,3.444,0,0,1-1.17-2.15H1777v-12a.99.99,0,0,1,1-1h14a.99.99,0,0,1,1,1v2h3l3,4.06V1700h-2.03a3.507,3.507,0,0,1-6.94,0Zm6.03-11h-12v8.05a3.546,3.546,0,0,1,1.42-.88,3.5,3.5,0,0,1,4.24,1.83h5.68a3.676,3.676,0,0,1,.66-.95Zm2,6h4v-.28l-2.01-2.72H1793Zm.5,6a1.5,1.5,0,1,0-1.06-.44A1.5,1.5,0,0,0,1793.5,1701Zm-10.5-1.5a1.563,1.563,0,0,0-.11-.57,1.541,1.541,0,0,0-.82-.82,1.532,1.532,0,0,0-1.14,0,1.541,1.541,0,0,0-.82.82,1.563,1.563,0,0,0-.11.57,1.5,1.5,0,0,0,3,0Z" transform="translate(-6963 -9497.005)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default driver
