import React from 'react'
import { Table, Spinner } from 'reactstrap'

import './styles.scss'

const _Table = ({
  columns,
  items,
  loading,
  selectedRow,
  onRowClick,
  disableEmptyState,
  ...rest
}) => (
  loading ? (
    <div className="empty-state">
      <Spinner size="lg" color="dark" />
      <h4 className="mt-3">Obtendo dados...</h4>
    </div>
  ) : items.length ? (
      <Table className="table" {...rest}>
        <thead>
          <tr>
            {
              columns.map((item, key) => (
                <th key={key}>{item.label}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            items.map((item, key) => (
              <tr key={key} onClick={() => onRowClick?.(item)} className={`${onRowClick ? 'row-hover' : ''} ${selectedRow?.id === item?.id ? 'row-selected' : ''}`}>
                {
                  columns.map((_item, _key) => (
                    <td key={`${key}-${_key}`}>
                      {
                        _item?.cell
                          ? _item.cell(item)
                          : item[_item.selector] || 'Não informado'
                      }
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </Table>
    ) : !disableEmptyState && (
      <div className="empty-state">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    )
)

export default _Table
