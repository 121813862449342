import React from 'react'

const arrowRight = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9839" data-name="Group 9839" transform="translate(4590 6110)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-4590 -6110)" fill="none"/>
      <g id="Group_9838" data-name="Group 9838" transform="translate(-4561.875 -6042.5) rotate(180)">
        <path id="Union_10" data-name="Union 10" d="M6.879,14.746.141,7.974A.59.59,0,0,1,.008,7.49a.608.608,0,0,1,.215-.48L6.887.178a.813.813,0,0,1,1.061.04.628.628,0,0,1,.008.954l-6.2,6.357,6.353,6.385a.631.631,0,0,1-.174.94A.848.848,0,0,1,7.458,15,.751.751,0,0,1,6.879,14.746Z" transform="translate(13 49)" className={`fill-${color}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      </g>
    </g>
  </svg>
)

export default arrowRight
