import React, { useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Tabs from '~/components/Tabs'
import Toggle from '~/components/Toggle'
import Button from '~/components/Button'
import Input from '~/components/Input'
import Checkbox from '~/components/Checkbox'

import avatar from '~/images/avatar.jpg'

import { UserContext } from '~/contexts'
import { format, DateUtils } from '~/utils'

import './styles.scss'

const Detail = ({ content, onRefreshAll, onClose }) => {
  const servicesUser = useContext(UserContext)

  const [activeTab, setActiveTab] = useState(0)
  const [item, setItem] = useState(content)

  const weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

  const updateItem = () => {
    servicesUser.exec.getDriver({ id: content.id })
      .then(res => setItem(res))
      .catch(err => {
        onClose()
        toast.error('Falha ao obter informações do motorista!')
      })
  }

  const handleChangeActiveDriver = (value, id) => {
    const body = {
      blocked: !value
    }

    servicesUser.exec.updateDriver({ body, id })
      .then(() => {
        toast.success('Status do motorista atualizado com sucesso!')
        updateItem()
        onRefreshAll()
      })
      .catch(err => toast.error('Não foi possível alterar o status do motorista!'))
  }

  const getWorkload = () => {
    const p1 = DateUtils.differenceInHours(DateUtils.toTime(item.lunchStart), DateUtils.toTime(item.workHourStart))
    const p2 = DateUtils.differenceInHours(DateUtils.toTime(item.workHourEnd), DateUtils.toTime(item.lunchEnd))
    return `${p1 + p2}h`
  }

  return (
    <div className="driver-detail">
      <div className="user">
        <div className="user-detail">
          <div className="user-avatar">
            <img
              src={
                item?.avatarImage?.url
                  ? format.urlFile(item?.avatarImage?.url)
                  : avatar
              }
              alt={item.name}
            />
          </div>
          <h3 className="user-name">
            {item.name}
            <br />
            #{item.id}
          </h3>
        </div>
        <div className="user-action">
          <p>Ativar / Inativar</p>
          <Toggle
            value={!item.blocked}
            onChange={value => handleChangeActiveDriver(value, item.id)}
          />
        </div>
      </div>

      <Tabs
        active={activeTab}
        options={[
          { label: 'Dados pessoais', value: 0 },
          { label: 'Documentação', value: 1 },
          { label: 'Config. de horário', value: 2 },
          { label: 'Jornadas', value: 3 }
        ]}
        onChange={value => setActiveTab(value)}
      />

      {
        activeTab === 0 &&
        <Row>
          <Col xs="6">
            <Input
              label="Email"
              value={item?.email}
              disabled
            />
          </Col>
          <Col xs="6">
            <Input
              label="Telefone"
              value={item?.phone ? format.phone(item.phone) : ''}
              disabled
            />
          </Col>
          <Col xs="6">
            <Input
              label="CPF"
              value={format.cpf(item?.cpf || '')}
              disabled
            />
          </Col>
          <Col xs="6">
            <Input
              label="Data de Nascimento"
              value={item?.birthDate ? DateUtils.formatDate(item?.birthDate) : ''}
              disabled
            />
          </Col>
          <Col xs="4">
            <Input
              label="CNH"
              value={item?.cnh}
              disabled
            />
          </Col>
          <Col xs="4">
            <Input
              label="Categoria"
              value={item?.cnhCategory}
              disabled
            />
          </Col>
          <Col xs="4">
            <Input
              label="Validade"
              value={item?.cnhValidate ? DateUtils.formatDate(item?.cnhValidate) : ''}
              disabled
            />
          </Col>
          <Col xs="3">
            <Input
              label="CEP"
              value={item?.zipcode}
              disabled
            />
          </Col>
          <Col xs="3">
            <Input
              label="Número"
              value={item?.number}
              disabled
            />
          </Col>
          <Col xs="6">
            <Input
              label="Complemento"
              value={item?.complement}
              disabled
            />
          </Col>
          {
            (item?.address || item?.district || item?.uf || item?.city) &&
            <Col xs="12">
              <p className="text-gray-dark">
                {item?.address}
                {(item?.address && item?.district) && ' - '}
                {item?.district}<br />
                {item?.city}
                {(item?.city && item?.uf) && ' - '}
                {item?.uf}
              </p>
            </Col>
          }
        </Row>
      }

      {
        activeTab === 1 &&
        <Row className="docs">
          {
            item.documents?.length ? (
              item.documents.map((_item, key) => (
                <Col xs="12" key={key} className="doc-item">
                  <div className="doc-label">
                    {_item.label}
                  </div>
                  <div className="detail">
                    Tipo de arquivo: {_item.file.ext.replace('.', '').toUpperCase()}
                  </div>
                  <div className="detail">
                    Enviado em: {DateUtils.formatDate(new Date(_item.file.created_at), "dd/MM/yyyy 'às' HH:mm")}
                  </div>
                  <a
                    href={format.urlFile(_item.file.url)}
                    download={_item.file.name}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      label="Baixar documento"
                      color="primary"
                      icon="download"
                    />
                  </a>
                </Col>
              ))
            ) : (
              <Col xs="12">Nenhum documento enviado pelo motorista!</Col>
            )
          }
        </Row>
      }

      {
        activeTab === 2 &&
        <Row>
          <Col xs="6">
            <Input
              label="Categoria"
              value={item?.profileCategory?.name}
              disabled
            />
          </Col>
          <Col xs="6">
            <Input
              label="Turno"
              value={item?.profileShifts?.[0]?.name}
              disabled
            />
          </Col>
          <Col xs="6">
            <Input
              label="Carga horária"
              value={(item?.workHourStart && item?.workHourEnd) ? `${DateUtils.hourMinutes(item?.workHourStart)} - ${DateUtils.hourMinutes(item?.workHourEnd)}` : ''}
              disabled
            />
          </Col>
          <Col xs="6">
            <Input
              label="Horário de refeição"
              value={(item?.lunchStart && item?.lunchEnd) ? `${DateUtils.hourMinutes(item?.lunchStart)} - ${DateUtils.hourMinutes(item?.lunchEnd)}` : ''}
              disabled
            />
          </Col>
          <Col xs="12">
            <p className="text-black text-medium mt-2">Dias de trabalho</p>
          </Col>
          {
            weekDays.map((_item, key) => (
              <Col xs="6" key={key}>
                <Checkbox
                  label={_item}
                  checked={item?.profileWorkDays ? item.profileWorkDays.find(__item => __item.name === _item) : false}
                  disabled
                />
              </Col>
            ))
          }
        </Row>
      }

      {
        activeTab === 3 &&
        <Row>
          {
            weekDays.map((_item, key) => (
              <Col xs="12" key={key}>
                <div className="journey-item">
                  <div className="day">
                    {_item.slice(0, 3).toUpperCase()}
                  </div>
                  <div className="detail">
                    {
                      item?.profileWorkDays?.find(__item => __item.name === _item) ? (
                        <>
                          <p>
                            {DateUtils.hourMinutes(item.workHourStart)} {' > '} {DateUtils.hourMinutes(item.lunchStart)}
                            {' - '}
                            {DateUtils.hourMinutes(item.workHourEnd)} {' > '} {DateUtils.hourMinutes(item.lunchEnd)}
                          </p>
                          <p>
                            Carga horária: {getWorkload()}
                          </p>
                        </>
                      ) : 'Não há trabalho nesse dia'
                    }
                  </div>
                </div>
              </Col>
            ))
          }
        </Row>
      }
    </div>
  )
}

export default Detail
