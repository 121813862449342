import React, { useState, useEffect, useContext, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Toggle from '~/components/Toggle'
import Input from '~/components/Input'
import Icon from '~/components/Icon'
import Button from '~/components/Button'

import { JourneyContext, AppContext } from '~/contexts'
import { validation, Blocker, format } from '~/utils'

import placeholder from '~/images/gift-placeholder.png'

import './styles.scss'

const Detail = ({ content, onRefreshAll, onClose }) => {
  // eslint-disable-next-line
  const blocker = new Blocker()

  let inputImage = useRef()

  const servicesJourney = useContext(JourneyContext)
  const servicesApp = useContext(AppContext)

  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [item, setItem] = useState(content)
  const [form, setForm] = useState({
    name: content?.name || '',
    value: content?.value || '',
    description: content?.description || '',
    status: content?.status || false,
  })
  const [image, setImage] = useState(content?.images?.[0] || null)

  const handleChangeForm = (field, value) => {
    setForm({ ...form, [field]: value })
  }

  const handleChangeActiveGift = value => {
    handleChangeForm('status', value)

    if(item?.id) {
      const body = {
        status: value
      }

      updateGift(body)
    }
  }

  const onSubmit = async () => {
    setLoadingSubmit(true)

    const { name, description, value, status } = form

    const body = {
      name,
      description,
      value
    }

    if(image && !image?.id) {
      const response = await servicesApp.exec.postImage({ file: image })

      if (!response?.[0]?.id) {
        setLoadingSubmit(false)
        toast.error('Não foi possível enviar a imagem!')
        return
      }

      body['images'] = [{ id: response[0].id }]
    }

    if(item?.id) {
      updateGift(body)
    } else {
      createGift({ ...body, status })
    }
  }

  const createGift = body => {
    servicesJourney.exec.createGift({ body })
      .then(res => {
        setItem(res)
        toast.success('Brinde adicionado com sucesso!')
        onRefreshAll()
        onClose()
      })
      .catch(err => toast.error('Não foi possível adicionar o brinde!'))
      .then(() => setLoadingSubmit(false))
  }

  const updateGift = body => {
    servicesJourney.exec.updateGift({ body, id: item.id })
      .then(res => {
        setItem(res)
        toast.success('Brinde atualizado com sucesso!')
        onRefreshAll()
      })
    .catch(err => toast.error('Não foi possível atualizar o brinde!'))
    .then(() => setLoadingSubmit(false))
  }

  useEffect(() => {
    setDisabledSubmit((blocker?.value !== false) || !image)
  }, [blocker, image])

  return (
    <div className="gift-detail">
      <div className="preview-image">
        <img
          src={
            (item?.images?.[0]?.formats?.small?.url || item?.images?.[0]?.url)
              ? format.urlFile(item?.images?.[0]?.formats?.small?.url || item?.images?.[0]?.url)
              : placeholder
          }
          alt="Brinde"
        />
      </div>

      <div className="gift">
        <div className="gift-detail">
          <h3 className="gift-name">
            { item.id ? 'Editar' : 'Adicionar' } brinde
          </h3>
        </div>
        <div className="gift-action">
          <p>Ativar / Inativar</p>
          <Toggle
            value={form.status}
            onChange={handleChangeActiveGift}
          />
        </div>
      </div>

      <Row>
        <Col xs="5">
          <Input
            label="Nome do brinde"
            value={form.name}
            onChange={value => handleChangeForm('name', value)}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs="3">
          <Input
            type="number"
            label="Valor"
            value={String(form.value)}
            onChange={value => handleChangeForm('value', value)}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs="4">
          <div
            className="input-file"
            onClick={() => inputImage?.current?.click()}
          >
            <div>
              {
                image?.name ? image.name : 'Selecione uma foto'
              }
            </div>
            <Icon
              name="upload"
              color="black"
            />
          </div>
          <input
            name="img"
            type="file"
            accept="image/*"
            className="d-none"
            ref={inputImage}
            onChange={e => setImage(e.target.files[0])}
          />
        </Col>
        <Col xs="12">
          <Input
            textarea
            label="Descrição do brinde"
            value={form.description}
            onChange={value => handleChangeForm('description', value)}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs={{ offset: 8, size: 4 }} className="d-flex flex-column">
          <Button
            label={`${item.id ? 'Salvar edição' : 'Adicionar'}`}
            color="primary"
            loading={loadingSubmit}
            disabled={disabledSubmit}
            onClick={onSubmit}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Detail
