import React from 'react'

import Button from '~/components/Button'

import './styles.scss'

const AddItemCard = ({
  label,
  onClick
}) => (
  <div className="add-item-card">
    <Button
      label={label}
      onClick={onClick}
      icon="more"
      color="primary"
      outline
    />
  </div>
)

export default AddItemCard
