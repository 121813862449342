export class Blocker {
  constructor() {
    this.value = false

    this.validation = (_, errorMessage) => {
      if (errorMessage) {
        this.value = errorMessage
      }

      return errorMessage
    }
  }
}
