import React from 'react'

const absence = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="Path_12049" data-name="Path 12049" d="M51,95a3,3,0,0,1-3-3V84a3,3,0,0,1,3-3,1,1,0,0,0,0-2,5,5,0,0,0-5,5v8a5,5,0,0,0,5,5,1,1,0,0,0,0-2Zm7.71-3.29a1,1,0,0,0,0-1.42L56.41,88l2.3-2.29a1,1,0,1,0-1.42-1.42L55,86.59l-2.29-2.3a1,1,0,1,0-1.42,1.42L53.59,88l-2.3,2.29a1,1,0,1,0,1.42,1.42L55,89.41l2.29,2.3a1,1,0,0,0,1.42,0ZM59,79a1,1,0,0,0,0,2,3,3,0,0,1,3,3v8a3,3,0,0,1-3,3,1,1,0,0,0,0,2,5,5,0,0,0,5-5V84a5,5,0,0,0-5-5Z" transform="translate(-5230 -7890)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default absence
