import React from 'react'
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap'

import Toggle from '~/components/Toggle'

import { format } from '~/utils'

import './styles.scss'

const ItemGift = ({
  item,
  onChangeStatus,
  onClick
}) => (
  <div onClick={() => onClick?.()} className="link">
    <Card className="item-gift-card">
      {
        item?.images[0]?.formats?.small?.url || item?.images[0]?.url ? (
          <CardImg
            top width="100%" height="232"
            src={format.urlFile(item?.images[0]?.formats?.small?.url || item?.images[0]?.url)}
            alt={item.title}
          />
        ) : <div className="placeholder-image" />
      }
      <CardBody>
        <div className="d-flex flex-row">
          <CardTitle tag="p" className="title">
            { item.name }
          </CardTitle>
          <div onClick={e => e.stopPropagation()}>
            <Toggle
              value={item.status}
              onChange={(value) => onChangeStatus(value, item.id)}
            />
          </div>
        </div>
        <CardSubtitle className="text-gray-dark">
          Valor: { item.value } pontos
        </CardSubtitle>
        <CardText className="description">
          { item?.description }
        </CardText>
      </CardBody>
    </Card>
  </div>
)

export default ItemGift
