import React, { useEffect, useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Table from '~/components/Table'
import Button from '~/components/Button'
import Input from '~/components/Input'

import { UserContext } from '~/contexts'
import { validation, Blocker } from '~/utils'

import '../Detail/styles.scss'

const CrudAdmins = ({ company }) => {
  const columnsTable = [
    {
      label: 'Nome',
      selector: 'name'

    },
    {
      label: 'Email',
      selector: 'email'
    }
  ]

  const blocker = new Blocker()

  const servicesUser = useContext(UserContext)

  const [admins, setAdmins] = useState([])
  const [adminToEdit, setAdminToEdit] = useState(null)
  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: ''
  })

  const handleChangeForm = (field, value) => {
    setForm({ ...form, [field]: value })
  }

  const onSubmit = () => {
    setLoadingSubmit(true)

    if(adminToEdit?.id) {
      const body = {
        name: form.name,
        email: form.email,
        username: form.email
      }

      if(form?.password) {
        body['password'] = form.password
      }

      servicesUser.exec.editUser({ body, id: adminToEdit.id })
        .then(res => {
          toast.success('Administrador editado com sucesso!')
          getAdmins()
          cancelEdit()
        })
        .catch(err => toast.error('Não foi possível editar o administrador!'))
        .then(() => setLoadingSubmit(false))
    } else {
      const body = {
        ...form,
        username: form.email,
        role: { id: 4 },
        company: { id: company.id }
      }

      servicesUser.exec.createUser({ body })
        .then(res => {
          toast.success('Administrador criado com sucesso!')
          getAdmins()
          resetForm()
        })
        .catch(err => toast.error('Não foi possível criar o administrador!'))
        .then(() => setLoadingSubmit(false))
    }
  }

  const getAdmins = () => {
    setLoadingTable(true)

    servicesUser.exec.getAdminsFromCompany({ companyId: company.id })
      .then(res => {
        setAdmins(res)
      })
      .catch(err => toast.error('Não foi possível encontrar administradores desta empresa!'))
      .then(() => setLoadingTable(false))
  }

  const resetForm = () => {
    setForm({
      name: '',
      email: '',
      password: ''
    })
  }

  const cancelEdit = () => {
    resetForm()
    setAdminToEdit(null)
  }

  useEffect(() => {
    if(company?.id) {
      getAdmins()
    } else {
      setAdmins([])
    }
    // eslint-disable-next-line
  }, [company])

  useEffect(() => {
    if(adminToEdit?.id) {
      setForm({
        name: adminToEdit.name,
        email: adminToEdit.email,
        password: ''
      })
    } else {
      resetForm()
    }
  }, [adminToEdit])

  useEffect(() => {
    setDisabledSubmit(blocker?.value !== false)
  }, [blocker])

  return (
    <div className="company-detail mt-5">
      <div className="user">
        <div className="user-detail">
          <h5 className="user-name">
            Administradores da empresa
          </h5>
        </div>
      </div>

      <div>
        <Table
          columns={columnsTable}
          items={admins}
          loading={loadingTable}
          onRowClick={row => setAdminToEdit(row)}
          disableEmptyState
        />
      </div>

      {
        !loadingTable &&
        <form>
          <Row className="mt-4">
            <Col xs="4">
              <Input
                label="Nome"
                value={form.name || ''}
                onChange={value => handleChangeForm('name', value)}
                onValidate={[validation.required, blocker.validation]}
              />
            </Col>
            <Col xs="4">
              <Input
                label="Email"
                value={form.email}
                onChange={value => handleChangeForm('email', value)}
                onValidate={[validation.email, blocker.validation]}
              />
            </Col>
            <Col xs="4">
              <Input
                label={adminToEdit ? 'Nova senha' : 'Senha'}
                type="password"
                value={form.password || ''}
                onChange={value => handleChangeForm('password', value)}
                onValidate={!adminToEdit ? [validation.required, blocker.validation] : []}
              />
            </Col>
            {
              adminToEdit &&
              <Col xs={{ offset: 4, size: 4 }} className="d-flex flex-column mt-3">
                <Button
                  label="Cancelar edição"
                  color="primary"
                  onClick={cancelEdit}
                  outline
                />
              </Col>
            }
            <Col xs={{ offset: adminToEdit ? 0 : 8, size: 4 }} className="d-flex flex-column mt-3">
              <Button
                label={adminToEdit ? 'Editar' : 'Adicionar'}
                color="primary"
                loading={loadingSubmit}
                disabled={disabledSubmit}
                onClick={onSubmit}
              />
            </Col>
          </Row>
        </form>
      }
    </div>
  )
}

export default CrudAdmins
