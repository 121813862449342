import { json2csv } from 'json-2-csv'

export const exportData = (items, fileName) => {
  json2csv(
    items,
    (err, csv) => {
      if(err || !csv) return

      const exportedFilename = fileName + '.csv' || 'export.csv'

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilename)
      } else {
        const link = document.createElement("a")
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", exportedFilename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    {
      expandArrayObjects: true
    }
  )
}
