import React from 'react'

const locationDuo = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-9293 7677)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(9293 -7677)" fill="none"/>
      <path id="Path_12050" data-name="Path 12050" d="M1929.88,1834.88a3.5,3.5,0,0,0,.938-1.777,3.454,3.454,0,0,0-1.471-3.545,3.441,3.441,0,0,0-3.831,0,3.455,3.455,0,0,0-1.471,3.545,3.442,3.442,0,0,0,.948,1.777l2.439,2.439Zm-2.449,5.233-3.841-3.841a5.4,5.4,0,0,1-1.481-2.785,5.313,5.313,0,0,1,.306-3.13,5.425,5.425,0,0,1,10.032,0,5.312,5.312,0,0,1,.306,3.13,5.4,5.4,0,0,1-1.481,2.785l-3.841,3.841Zm0-6.2a1.483,1.483,0,1,1,1.047-.434A1.481,1.481,0,0,1,1927.431,1833.912Zm8.887,12.293,2.449-2.439a3.5,3.5,0,0,0,.938-1.777,3.455,3.455,0,0,0-1.471-3.545,3.441,3.441,0,0,0-3.831,0,3.454,3.454,0,0,0-1.471,3.545,3.441,3.441,0,0,0,.948,1.777Zm3.841-1.047-3.841,3.841-3.841-3.841a5.4,5.4,0,0,1-1.481-2.785,5.312,5.312,0,0,1,.306-3.13,5.425,5.425,0,0,1,10.032,0,5.312,5.312,0,0,1,.306,3.13,5.4,5.4,0,0,1-1.481,2.785Zm-3.841-2.36a1.483,1.483,0,1,1,1.047-.434A1.48,1.48,0,0,1,1936.318,1842.8Z" transform="translate(7372.126 -9504)" className={`fill-${color}`} />
    </g>
  </svg>
)

export default locationDuo
