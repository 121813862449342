import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Form, FormGroup, Label } from 'reactstrap'
import Button from '~/components/Button'
import { format, DateUtils, numerize } from '~/utils'
import avatar from '~/images/avatar.jpg'
import Input from '~/components/Input'
import './styles.scss'
import DatePicker from '~/components/Datepicker'
import { UserContext } from '~/contexts'
import * as XLSX from 'xlsx';
import { JourneyContext } from '../../../contexts/journeys'
import moment from "moment"

const Report = () => {
  const [drivers, setDrivers] = useState([])
  const [filteredDrivers, setFilteredDrivers] = useState([])
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [journeys, setJourneys] = useState([])
  const [filter, setFilter] = useState('')
  const servicesUser = useContext(UserContext)
  const servicesJourney = useContext(JourneyContext)


  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const driversList = await servicesUser.exec.getDrivers()
        //console.log(driversList)
        setDrivers(driversList)
        calculateHoursWithHolidays(await servicesJourney.exec.getJourneysByDate({ beginsAt: startDate.getTime(), endsAt: endDate.getTime(), userId: selectedDriver.id }), new Date(startDate.getTime()).getFullYear())
      } catch (error) {
        console.log("error", error)
      }
    }
    fetchDrivers()


  }, [])

  const handleDriverSearch = (e) => {
    console.log(e)
    const query = e.toLowerCase()
    setFilter(query)
    setFilteredDrivers(
      drivers.filter(driver =>
        driver.name.toLowerCase().includes(query) || driver.id.toString().includes(query)
      )
    )
  }

  const handleSelectDriver = (driver) => {
    setSelectedDriver(driver)
    setFilter(driver.name)
    setFilteredDrivers([])
  }

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    // Obtém dia, mês e ano da data
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    // Formata a data no formato desejado
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  function calculateHours2(data, holidays) {
    let lastEnd = null; // Variável para armazenar o fim da última jornada
    const results = data.map((entry) => {
      const { begins_at, ends_at, stops, steps } = entry;
      const begins_at_mom = moment(begins_at);
      const ends_at_mom = moment(ends_at);

      // Formate a data no formato desejado
      //const formattedDate = date.format('DD/MM/YYYY HH:mm:ss');

      let normais = moment.utc(moment(ends_at_mom).diff(moment(begins_at_mom)));


      // Definir o limite máximo de 7 horas (em milissegundos)
      const maxNormais = moment.duration(7, 'hours');
      let extras = moment.duration(0);

      if (normais > maxNormais) {
        // Se o tempo normal exceder 7 horas, calcular o tempo extra
        extras = moment.duration(normais - maxNormais);
        normais = moment.utc(maxNormais.asMilliseconds());
      }

      //extras = moment.utc(extras.asMilliseconds())
      // Calcular horas noturnas (considerando período noturno das 22:00 às 05:00)
      const nightStart = moment(begins_at_mom).set({ hour: 22, minute: 0 });
      const nightEnd = moment(begins_at_mom).set({ hour: 5, minute: 0 }).add(1, 'day');
      let normaisNoturnas = moment.duration(0);
      let extrasNoturnas = moment.duration(0);

      if (begins_at_mom.isBetween(nightStart, nightEnd) || ends_at_mom.isBetween(nightStart, nightEnd)) {
        const overlapStart = moment.max(begins_at_mom, nightStart);
        const overlapEnd = moment.min(ends_at_mom, nightEnd);
        const overlapDuration = moment.duration(overlapEnd.diff(overlapStart));

        if (extras.asMilliseconds() > 0) {
          if (overlapDuration > extras) {
            extrasNoturnas = extras;
            normaisNoturnas = overlapDuration.subtract(extras);
          } else {
            extrasNoturnas = overlapDuration;
          }
        } else {
          normaisNoturnas = overlapDuration;
        }
      }

      // Calcular horas em feriados e DSR
      const isHolidayOrDSR = holidays.includes(begins_at_mom.format('YYYY-MM-DD')) || begins_at_mom.day() === 0; // Verifica se é feriado ou domingo
      let feriadosEDSRNormais = moment.utc(moment.duration(0));
      let feriadosEDSRNoturnas = moment.utc(moment.duration(0));
  
      if (isHolidayOrDSR) {
        feriadosEDSRNormais = normais;
        feriadosEDSRNoturnas = moment.utc(normaisNoturnas.asMilliseconds());
      }

      // Convert stops times from milliseconds to Date objects
      let espera30 = moment.duration(0), refeicao = moment.duration(0), coffee = moment.duration(0), ajuda = moment.duration(0), naoApontado = moment.duration(0);

      stops.forEach(stop => {
        const begins_at_stop = moment(stop.begins_at);
        const ends_at_stop = moment(stop.ends_at);
        const duration = moment.duration(ends_at_stop.diff(begins_at_stop));

        switch (stop.mode) {
          case 'help':
            ajuda.add(duration);
            break;
          case 'meal':
            refeicao.add(duration);
            break;
          case 'coffee':
            coffee.add(duration);
            break;
          default:
            espera30.add(duration);
        }
      });

      // Calcular horas de condução
      let horasConducao = moment.duration(normais).add(extras).subtract(refeicao).subtract(espera30);

      // Calcular horas de repouso
      let horasRepouso = moment.duration(0);
      if (lastEnd) {
        horasRepouso = moment.duration(begins_at_mom.diff(lastEnd));
      }
      lastEnd = ends_at_mom; // Atualizar o fim da última jornada

      extras = moment.utc(extras.asMilliseconds())
      //normais = moment.utc(normais.asMilliseconds())
      normaisNoturnas = moment.utc(normaisNoturnas.asMilliseconds())
      extrasNoturnas = moment.utc(extrasNoturnas.asMilliseconds())
      feriadosEDSRNormais = feriadosEDSRNormais
      feriadosEDSRNoturnas = feriadosEDSRNoturnas
      espera30 = moment.utc(espera30.asMilliseconds())
      refeicao = moment.utc(refeicao.asMilliseconds())
      ajuda = moment.utc(ajuda.asMilliseconds())
      coffee = moment.utc(coffee.asMilliseconds())
      naoApontado = moment.utc(naoApontado.asMilliseconds())
      horasConducao = moment.utc(horasConducao.asMilliseconds())
      horasRepouso = moment.utc(horasRepouso.asMilliseconds())
 
      //console.log(begins_at_mom.format('DD/MM/YYYY'), begins_at_mom.format('HH:mm'), ends_at_mom.format('HH:mm'), normais.format('HH:mm'), extras.format('HH:mm'), normaisNoturnas.format('HH:mm'), extrasNoturnas.format('HH:mm'), feriadosEDSRNormais.format('HH:mm'), feriadosEDSRNoturnas.format('HH:mm'));



      return {
        date: begins_at_mom.format('DD/MM/YYYY'),
        startTime: begins_at_mom.format('HH:mm'),
        endTime: ends_at_mom.format('HH:mm'),
        normais: normais.format('HH:mm'),
        normaisNoturnas: normaisNoturnas.format('HH:mm'),
        extras: extras.format('HH:mm'),
        extrasNoturnas: extrasNoturnas.format('HH:mm'),
        feriadosEDSR: feriadosEDSRNormais.format('HH:mm'),
        feriadosEDSRNoturna: feriadosEDSRNoturnas.format('HH:mm'),
        espera30: espera30.format('HH:mm'),
        faltantes: ajuda.format('HH:mm'),
        refeicao: refeicao.format('HH:mm'),
        naoApontado: naoApontado.format('HH:mm'),
        totalHorasExtras: extras.add(extrasNoturnas).format('HH:mm'),
        horasRepouso: horasRepouso.format('HH:mm'),
        horasConducao: horasConducao.format('HH:mm'),
        horasDescanso: moment.utc(moment.duration(0, 'hours').asMilliseconds()).format('HH:mm'),
      };
    })
    return results;
  }


  const handleGenerateReport = async () => {
    if (!selectedDriver || !startDate || !endDate) {
      alert('Por favor, preencha todos os campos.');
      return;
    }


    try {
      const data = calculateHours2(await servicesJourney.exec.getJourneysByDate({ beginsAt: startDate.getTime(), endsAt: endDate.getTime(), userId: selectedDriver.id }), ['2024-04-01'], ['2024-04-01'])

      if (!data || data.length === 0) {
        alert('Nenhum dado encontrado para o período selecionado.');
        return;
      }

      // Formata os dados para o Excel
      const formattedData = data.map(item => ({
        'Data': item.date,
        'Início': item.startTime,
        'Fim': item.endTime,
        'Normais': item.normais,
        'Normais Noturnas': item.normaisNoturnas,
        'Extras': item.extras,
        'Extras Noturnas': item.extrasNoturnas,
        'Feriados e DSR': item.feriadosEDSR,
        'Feriados e DSR Noturna': item.feriadosEDSRNoturna,
        'Espera 30%': item.espera30,
        'Faltantes': item.faltantes,
        'Refeição': item.refeicao,
        'Não Apontad': item.naoApontado,
        'Total de Horas Extras': item.totalHorasExtras,
        'Horas de Repouso': item.horasRepouso,
        'Horas de Condução': item.horasConducao,
        'Horas de Descanso': item.horasDescanso,
        'Refeição': item.refeicao
      }));

      // Cria uma nova planilha
      const ws = XLSX.utils.json_to_sheet(formattedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Relatorio');

      // Converte a planilha para um arquivo Blob
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Cria um link temporário para o arquivo
      const url = window.URL.createObjectURL(blob);

      // Cria um elemento <a> para o download
      const a = document.createElement('a');
      a.href = url;

      // Nome personalizado para o arquivo
      a.download = `Relatorio_tempo_direcao_${formatDate(startDate)}_${formatDate(endDate)}.xlsx`;

      // Dispara o download
      a.click();

      // Revoga o objeto URL para liberar memória
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getTotal = (array, mode) => {
    let total = 0
    for (let i = 0; i < array.length; i++) {
      if (mode) {
        if (array[i].mode === mode) {
          total += array[i].current === true ? 0 : (array[i].ends_at - array[i].begins_at)
        }
      } else {
        total += array[i].current === true ? 0 : (array[i].ends_at - array[i].begins_at)
      }
    }
    const parsedTotal = DateUtils.intervalToDuration({ end: total })
    return `Total: ${numerize(parsedTotal.hours)}h${numerize(parsedTotal.minutes)}`
  }

  async function getHolidays(year) {
    const url = `https://brasilapi.com.br/api/feriados/v1/${year}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching holidays: ${response.statusText}`);
      }
      const holidays = await response.json();
      return holidays.map(holiday => holiday.date);
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  
  async function calculateHoursWithHolidays(data, year) {
    const holidays = await getHolidays(year);
    console.log(holidays)
    return calculateHours2(data, holidays);
  }

  return (
    <div className="autocomplete-report">
      <div>
        <FormGroup>
          <Label for="driverSelect">Selecione o Motorista</Label>
          <Input
            label="Pesquisar (ID, nome ou CPF)"
            placeholder="Procurar motoristas"
            value={filter}
            onChange={handleDriverSearch}
            icon="search"
          />
          {filteredDrivers.length > 0 && (
            <ul className="driver-list">
              {filteredDrivers.map(driver => (
                <li key={driver.id} onClick={() => handleSelectDriver(driver)}>
                  {driver.id} - {driver.name}
                </li>
              ))}
            </ul>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="startDate">Data de Início</Label>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            id="startDate"
            placeholderText="Selecione a data de início"
            dateFormat="dd/MM/yyyy"
          />
        </FormGroup>
        <FormGroup>
          <Label for="endDate">Data de Fim</Label>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            id="endDate"
            placeholderText="Selecione a data de fim"
            dateFormat="dd/MM/yyyy"
          />
        </FormGroup>
        <Button
          outline
          label="Gerar Relatório"
          icon="download"
          color="primary"
          onClick={handleGenerateReport}
          className="w-100"
        />
      </div>

      {journeys.length > 0 && (
        <div className="journeys-report">
          {journeys.map((item, index) => (
            <div key={index} className="help-detail">
              <div className="user">
                <div className="user-detail">
                  <div className="user-avatar">
                    <img
                      src={
                        item.userPermissions?.avatarImage?.url
                          ? format.urlFile(item.userPermissions?.avatarImage?.url)
                          : avatar
                      }
                      alt={item.userPermissions.name}
                    />
                  </div>
                  <h3 className="user-name">
                    {item.userPermissions.name}
                    <br />
                    #{item.id}{' '}
                  </h3>
                </div>
              </div>

              <Row className="mb-3">
                <Col xs="12" className="mb-2">
                  <h5 className="text-primary fw-bold">
                    Tempo de Jornada
                  </h5>
                </Col>
                <Col xs="12" className="text-black mb-1">
                  {item.steps.map((_item, _key) => (
                    <span key={_key}>
                      {DateUtils.hourMinutes(_item.begins_at)} {' > '} {DateUtils.hourMinutes(_item.ends_at)}
                    </span>
                  )).reduce((prev, curr, _key) => [prev, <span key={`${_key}--`}>&nbsp; — &nbsp;</span>, curr])}
                  <br />
                  {getTotal(item.steps)}
                </Col>
              </Row>
              {/* Você pode adicionar mais seções para diferentes tipos de paradas, similar ao componente Report original */}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Report
