import React from 'react'

import './styles.scss'

const Toggle = ({ value, onChange }) => (
  <label className="switch">
    <input
      checked={value}
      onChange={() => onChange(!value)}
      type="checkbox"
    />
    <span className="slider round"></span>
  </label>
)

export default Toggle

