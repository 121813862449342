import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { UserContext } from '~/contexts'

import Icon from '~/components/Icon'
import SideContent from '~/components/SideContent'

import avatar from '~/images/avatar.jpg'
import logo from '~/images/logo.png'

import { screens } from '~/data'
import { format } from '~/utils'

import UserDetail from './UserDetail'

import './styles.scss'

const LayoutMain = ({ children }) => {
  const location = useLocation()

  const [selectedItem, setSelectedItem] = useState(null)

  const servicesUser = useContext(UserContext)
  const { user } = servicesUser.state

  const checkPathnameActive = path => {
    if(path.length > 1) {
      return !!location.pathname.match(path)
    } else {
      return location.pathname === path
    }
  }

  return (
    <div className="main-layout">
      <header>
        <div className="container header-content">
          <div>
            <img src={logo} alt="Bino" className="logo" />
          </div>
          <div className="logout" onClick={() => servicesUser.exec.logout()}>
            Sair
          </div>
        </div>
      </header>
      <div className="main-container">
        <div className="sidemenu">
          <div
            className="user-info link"
            onClick={() => setSelectedItem(user)}
          >
            <div className="user-avatar">
              <img
                src={
                  user?.avatarImage?.url
                    ? format.urlFile(user?.avatarImage?.url)
                    : avatar
                }
                alt={user.name}
              />
            </div>
            <h3 className="user-name">
              { user.name }
            </h3>
          </div>
          {
            screens
              .filter(item => item?.role ? item.role.find(role => role === user.role.id) : true)
              .map((item, key) => (
                <Link
                  to={item.path}
                  className={`menu-item ${ checkPathnameActive(item.path) && 'selected'}`}
                  key={key}
                >
                  <Icon name={ item.icon } size="larger" />
                  <div className="label">
                    { item.label }
                  </div>
                </Link>
              ))
          }
        </div>
        <div className="main-content container">
          { children }
        </div>
      </div>

      <SideContent
        opened={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      >
        {
          selectedItem &&
          <UserDetail
            content={selectedItem}
            onClose={() => setSelectedItem(null)}
          />
        }
      </SideContent>
    </div>
  )
}

export default LayoutMain
