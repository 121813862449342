import React from "react";

function chart({ color, size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 512 512"
    >
      <g transform="translate(42.667 85.333)">
        <path d="M341.333 0l85.334 85.333v256H0V0h341.333zm-10.666 42.667h-288v256H384V96l-53.333-53.333zm-224 42.666v132.258l61.057-76.321 65.215 32.597 47.925-43.129 14.272 15.857-58.737 52.863-62.81-31.394-53.265 66.602h221.01V256h-256V85.333h21.333z" className={`fill-${color}`}></path>
      </g>
    </svg>
  );
}

export default chart;