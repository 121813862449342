import React from 'react'

import background from '~/images/auth-background.png'
import logo from '~/images/logo.png'

import './styles.scss'

const LayoutAuth = ({ children }) => (
  <div
    className="auth-layout"
    style={{ backgroundImage: `url(${background})` }}
  >
    <div className="auth-content">
      <img src={logo} alt="Bino" className="auth-logo" />

      { children }
    </div>
  </div>
)

export default LayoutAuth
