import React, { createContext, useReducer, useEffect } from 'react'

import { EventBus } from '~/utils'

import {
  Storage,

  login,
  loginCpf,
  forgotPassword,
  resetPassword,
  newUserPassword,

  getUserById,
  createUser,
  editUser,
  getAdminsFromCompany,

  getTimeSettings,
  deleteTimeSetting,
  countTimeSetting,
  getAbsences,
  countAbsences,

  getDrivers,
  getDriver,
  countDrivers,
  updateDriver
} from '~/services'

const storageKey = 'sessionUser'

const initialState = {
  jwt: null,
  user: null
}

export const UserContext = createContext(initialState)

function reducer(state, { type, value }) {
  switch (type) {
    case 'login':
      return {
        jwt: value?.jwt,
        user: { ...value?.user }
      };
    case 'updateSessionUser':
      return {
        ...state,
        user: { ...value }
      };
    case 'logout':
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export function UserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const exec = {
    login(params) {
      return login({ ...params })
        .then(sessionUser => {
          // 3 = backoffice - Bino admin - role
          // 4 = company - company admin - role
          if (sessionUser?.user?.role?.id === 3 || sessionUser?.user?.role?.id === 4) {
            dispatch({ type: 'login', value: sessionUser })
            Storage.save({ key: storageKey, value: sessionUser })
            return sessionUser?.user
          } else {
            throw new Error('Acesso não autorizado!')
          }
        })
        .catch(err => {
          dispatch({ type: 'logout' })
          Storage.delete({ key: storageKey })

          throw err
        })
    },
    loginCpf(params) {
      return loginCpf({ ...params })
        .then(sessionUser => {
          dispatch({ type: 'login', value: sessionUser })
          Storage.save({ key: storageKey, value: sessionUser })
          return sessionUser?.user
        })
        .catch(err => {
          dispatch({ type: 'logout' })
          Storage.delete({ key: storageKey })

          throw err
        })
    },
    loggedUser() {
      return getUserById({ id: state?.user?.id, token: state?.jwt })
        .then(sessionUser => {
          dispatch({ type: 'updateSessionUser', value: sessionUser })
          Storage.save({ key: storageKey, value: { ...state, user: { ...sessionUser } } })
          return sessionUser
        })
        .catch(err => {
          dispatch({ type: 'logout' })
          Storage.delete({ key: storageKey })

          throw err
        })
    },
    getUser(params) {
      return getUserById({ ...params, token: state?.jwt })
    },
    createUser(params) {
      return createUser({ ...params, token: state?.jwt })
    },
    editUser(params) {
      return editUser({ ...params, token: state?.jwt })
    },
    getAdminsFromCompany(params) {
      return getAdminsFromCompany({ ...params, token: state?.jwt })
    },
    logout() {
      dispatch({ key: 'logout' })
      Storage.delete({ key: storageKey })
      window.location = '/auth/login'
      return true
    },
    forgotPassword(params) {
      return forgotPassword({ ...params })
    },
    resetPassword(params) {
      return resetPassword({ ...params })
    },
    newUserPassword(params) {
      return newUserPassword({ ...params, token: state?.jwt  })
    },

    getDrivers(params) {
      return getDrivers({
        ...params,
        company: state?.user?.role?.id === 4 ? state?.user?.company?.id : null,
        token: state?.jwt
      })
    },
    getDriver(params) {
      return getDriver({ ...params, token: state?.jwt })
    },
    countDrivers(params) {
      return countDrivers({
        ...params,
        company: state?.user?.role?.id === 4 ? state?.user?.company?.id : null,
        token: state?.jwt
      })
    },
    updateDriver(params) {
      return updateDriver({ ...params, token: state?.jwt})
    },
    getTimeSettings(params) {
      return getTimeSettings({
        ...params,
        company: state?.user?.role?.id === 4 ? state?.user?.company?.id : null,
        token: state?.jwt
      })
    },
    deleteTimeSetting(params) {
      return deleteTimeSetting({ ...params, token: state?.jwt })
    },
    countTimeSetting(params) {
      return countTimeSetting({
        ...params,
        company: state?.user?.role?.id === 4 ? state?.user?.company?.id : null,
        token: state?.jwt
      })
    },
    getAbsences(params) {
      return getAbsences({
        ...params,
        company: state?.user?.role?.id === 4 ? state?.user?.company?.id : null,
        token: state?.jwt
      })
    },
    countAbsences(params) {
      return countAbsences({
        ...params,
        company: state?.user?.role?.id === 4 ? state?.user?.company?.id : null,
        token: state?.jwt
      })
    },
  }

  EventBus.$override('logout', exec.logout)

  useEffect(() => {
    const sessionUser = Storage.get({ key: storageKey })
    if(sessionUser?.jwt && sessionUser?.user) {
      dispatch({ type: 'login', value: sessionUser })
    } else {
      dispatch({ type: 'logout' })
    }
  }, [])

  return (
    <UserContext.Provider value={{ state, dispatch, exec, storageKey }}>
      { children }
    </UserContext.Provider>
  )
}
