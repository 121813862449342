import React from 'react'

const close = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={`size-${size}`}>
    <rect id="Rectangle_3026" data-name="Rectangle 3026" width="3.143" height="25.141" rx="1.571" transform="translate(17.778 0) rotate(45)" className={`fill-${color}`}/>
    <rect id="Rectangle_3027" data-name="Rectangle 3027" width="3.143" height="25.141" rx="1.571" transform="translate(20 17.778) rotate(135)" className={`fill-${color}`}/>
  </svg>
)

export default close
