import React from 'react'

const timeSetting = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="Union_33" data-name="Union 33" d="M-1566,10172h2a8,8,0,0,0,8,8,8,8,0,0,0,8-8,8,8,0,0,0-8-8,7.972,7.972,0,0,0-6.241,3H-1561a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-4a1,1,0,0,1-1-1v-4a1,1,0,0,1,1-1,1,1,0,0,1,1,1v2a9.98,9.98,0,0,1,8-4,10,10,0,0,1,10,10,10.006,10.006,0,0,1-10,10A10.005,10.005,0,0,1-1566,10172Zm6.787,3.3a1,1,0,0,1-.36-.536,1.02,1.02,0,0,1,.043-.646,1.027,1.027,0,0,1,.429-.483l2.1-1.208V10168a1,1,0,0,1,.294-.708,1,1,0,0,1,.705-.291,1,1,0,0,1,.708.291,1,1,0,0,1,.294.708v5l-.02.12a.612.612,0,0,1-.051.2.874.874,0,0,1-.079.171.875.875,0,0,1-.1.158l-.161.131-.089.089-2.6,1.5a1.011,1.011,0,0,1-.5.131h-.011A.981.981,0,0,1-1559.213,10175.295Z" transform="translate(-3619 -17974)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default timeSetting
