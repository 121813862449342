import { UnauthorizedError, UnknownError } from '~/classes'
import { EventBus } from '~/utils/bus'
import { resolveUri } from '~/utils/file'

export function requestErrorHandler(err) {
  if (err.message === 'Unauthorized' || err.message === '401') {
    EventBus.$emit('logout')
    throw new UnauthorizedError()
  }

  if (err.message === 'UNKNOWN_ERROR') {
    throw new UnknownError()
  }

  if (err instanceof Error) {
    throw err
  }
}

export function getErrorInfo({
  statusCode,
  error,
  message
}) {
  if (message) {
    if (typeof message === 'string') {
      return message
    }

    if (typeof message === 'object') {
      return message?.[0]?.messages?.[0]?.message
    }
  }

  return error || statusCode || 'UNKNOWN_ERROR'
}

export function filePreparation(file) {
  const uri = resolveUri(file)
  const name = uri.split('/').pop()

  const match = /\.(\w+)$/.exec(name)

  const type = match ? `image/${match[1]}` : `image`

  return { uri, name, type }
}
