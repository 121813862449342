import React from 'react'

const upload = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-9293 7677)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(9293 -7677)" fill="none"/>
      <path id="Path_12050" data-name="Path 12050" d="M1435,2953h18v2h-18Zm10-13.17V2951h-2v-11.17l-6.07,6.07-1.41-1.41L1444,2936l8.49,8.48-1.42,1.42-6.07-6.07Z" transform="translate(7860 -10611)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default upload
