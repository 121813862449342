export const paginationOptions = [
  {
    value: 10,
    label: '10'
  },
  {
    value: 25,
    label: '25'
  },
  {
    value: 50,
    label: '50'
  }
]
