import React from 'react'
import Date, { registerLocale } from 'react-datepicker'

import ptBR from 'date-fns/locale/pt-BR'

import './styles.scss'

registerLocale('pt-BR', ptBR)

const DatePicker = ({
  className,
  id,
  label,
  onChange,
  placeholder,
  theme = 'light',
  value,
  disabled,
  isClearable = true,
  onValidate = [],
  ...rest
}) => {
  const errorMessage = onValidate.reduce((hasError, condition) => {
    return condition(value, hasError) || hasError
  }, false)

  return (
    <div className={`datepicker datepicker-${theme} ${className || ''}`}>
      {
        label &&
        <label htmlFor={id}>{label}</label>
      }

      <div className="datepicker-section">
        <Date
          locale="pt-BR"
          selected={value}
          onChange={onChange}
          placeholderText={placeholder}
          isClearable={isClearable}
          {...rest}
        />
      </div>

      {
        (errorMessage && value !== '')
          ? <div className="error-message">{errorMessage}</div>
          : null
      }
    </div>
  )
}

export default DatePicker
