import React from 'react'

import Icons from './svgs'

import './styles.scss'

const Icon = ({ name = 'driver', color, size = 'larger', className }) => {
  const Component = Icons[name]

  return (
    <span className={`icon ${className || ''}`}>
      <Component
        color={color}
        size={size}
      />
    </span>
  )
}

export default Icon
