import React, { useState, useEffect, useContext, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Button from '~/components/Button'
import Input from '~/components/Input'

import avatar from '~/images/avatar.jpg'

import { UserContext, AppContext } from '~/contexts'
import { format, validation, Blocker } from '~/utils'

import './styles.scss'

const Detail = ({ content }) => {
  // eslint-disable-next-line
  const blockerProfile = new Blocker()
  // eslint-disable-next-line
  const blockerPassword = new Blocker()

  const servicesUser = useContext(UserContext)
  const servicesApp = useContext(AppContext)

  const [item, setItem] = useState(content)

  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [loadingPassword, setLoadingPassword] = useState(false)

  const [disabledSubmitProfile, setDisabledSubmitProfile] = useState(false)
  const [disabledSubmitPassword, setDisabledSubmitPassword] = useState(false)

  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  let inputImage = useRef()

  const onChangeAvatar = async file => {
    setLoadingAvatar(true)

    const response = await servicesApp.exec.postImage({ file })

    if(!response?.[0]?.id) {
      setLoadingAvatar(false)
      toast.error('Não foi possível enviar a imagem!')
      return
    }

    const body = { avatarImage: { id: response[0].id } }

    servicesUser.exec
      .updateDriver({ body, id: item.id })
      .then(res => {
        toast.success('Avatar atualizado com sucesso!')
        handleChangeProfileForm('avatarImage', res?.avatarImage)

        servicesUser.exec.loggedUser()
      })
      .catch(err => {
        toast.error('Não foi possível atualizar o avatar!')
      })
      .then(() => setLoadingAvatar(false))
  }

  const handleChangeProfileForm = (field, value) => {
    setItem({ ...item, [field]: value })
  }

  const handleChangePasswordForm = (field, value) => {
    setPasswordForm({ ...passwordForm, [field]: value })
  }

  const onSubmitProfile = () => {
    setLoadingProfile(true)

    const { name, email, cpf, phone } = item

    const body = {
      name,
      email,
      cpf: cpf.replace(/[^\d]+/g, ''),
      phone: phone.replace(/[^\d]+/g, '')
    }

    servicesUser.exec
      .updateDriver({ body, id: item.id })
      .then(res => {
        toast.success('Perfil atualizado com sucesso!')

        servicesUser.exec.loggedUser()
      })
      .catch(err => {
        let errMessage = 'Um erro ocorreu, tente novamente!'
        if (err?.message === 'Email already taken') {
          errMessage = 'Email já cadastrado!'
        }

        toast.error(errMessage)
      })
      .then(() => setLoadingProfile(false))
  }

  const onSubmitPassword = () => {
    setLoadingPassword(true)

    const { currentPassword, newPassword } = passwordForm

    const body = {
      userId: item.id,
      currentPassword,
      newPassword
    }

    servicesUser.exec
      .newUserPassword({ body })
      .then(res => {
        toast.success('Senha atualizada com sucesso!')

        setPasswordForm({
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        })
      })
      .catch(err => {
        let errMessage = 'Um erro ocorreu, tente novamente!'
        if (err?.message === 'Old password does not match.') {
          errMessage = 'Sua senha atual não é valida!'
        }

        toast.error(errMessage)
      })
      .then(() => setLoadingPassword(false))
  }

  useEffect(() => {
    setDisabledSubmitProfile(blockerProfile?.value !== false)
  }, [blockerProfile])

  useEffect(() => {
    setDisabledSubmitPassword(blockerPassword?.value !== false)
  }, [blockerPassword])

  return (
    <div className="user-detail">
      <div className="user">
        <div className="user-detail">
          <div className="user-avatar">
            <img
              src={
                item?.avatarImage?.url
                  ? format.urlFile(item?.avatarImage?.url)
                  : avatar
              }
              alt={item.name}
            />
          </div>
          <Button
            color="primary"
            icon="camera"
            className="button-add-image"
            onClick={() => inputImage?.current?.click()}
            loading={loadingAvatar}
          />
          <input
            name="img"
            type="file"
            accept="image/*"
            className="d-none"
            ref={inputImage}
            onChange={e => onChangeAvatar(e.target.files[0])}
          />
        </div>
      </div>

      <Row className="mt-3">
        <Col xs="12">
          <h5 className="text-secondary fw-bold">Editar Usuário</h5>
        </Col>
        <Col xs="6">
          <Input
            label="Nome"
            value={item.name}
            onChange={value => handleChangeProfileForm('name', value)}
            onValidate={[validation.required, blockerProfile.validation]}
          />
        </Col>
        <Col xs="6">
          <Input
            label="Email"
            value={item.email}
            onChange={value => handleChangeProfileForm('email', value)}
            onValidate={[validation.required, blockerProfile.validation]}
          />
        </Col>
        <Col xs="6">
          <Input
            mask="999.999.999-99"
            label="CPF"
            value={item.cpf}
            onChange={value => handleChangeProfileForm('cpf', value)}
            onValidate={[validation.cpf, blockerProfile.validation]}
          />
        </Col>
        <Col xs="6">
          <Input
            mask="(99) 99999-9999"
            label="Telefone"
            value={item.phone}
            onChange={value => handleChangeProfileForm('phone', value)}
            onValidate={[validation.phoneNumber, blockerProfile.validation]}
          />
        </Col>
        <Col xs={{ offset: 9, size: 3 }} className="d-flex flex-column mt-2">
          <Button
            label="Salvar perfil"
            color="primary"
            onClick={onSubmitProfile}
            loading={loadingProfile}
            disabled={disabledSubmitProfile}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col xs="12">
          <h5 className="text-secondary fw-bold">Alterar senha de acesso</h5>
        </Col>
        <Row>
          <Col xs="6">
            <Input
              label="Senha atual"
              type="password"
              value={passwordForm.currentPassword}
              onChange={value => handleChangePasswordForm('currentPassword', value)}
            onValidate={[validation.required, blockerPassword.validation]}
            />
          </Col>
        </Row>
        <Col xs="6">
          <Input
            label="Nova senha"
            type="password"
            value={passwordForm.newPassword}
            onChange={value => handleChangePasswordForm('newPassword', value)}
            onValidate={[validation.password, blockerPassword.validation]}
          />
        </Col>
        <Col xs="6">
          <Input
            label="Repetir senha"
            type="password"
            value={passwordForm.confirmPassword}
            onChange={value => handleChangePasswordForm('confirmPassword', value)}
            onValidate={[validation.passwordConfirm, blockerPassword.validation]}
            toConfirm={passwordForm.newPassword}
          />
        </Col>
        <Col xs={{ offset: 9, size: 3 }} className="d-flex flex-column mt-2">
          <Button
            label="Salvar senha"
            color="primary"
            onClick={onSubmitPassword}
            loading={loadingPassword}
            disabled={disabledSubmitPassword}
          />
        </Col>
      </Row>

      {
        (item.role.id === 4 && item.company.id) &&
        <Row className="mt-5">
          <Col xs="12">
            <h5 className="text-secondary fw-bold">Informações sobre a empresa</h5>
          </Col>
          <Col xs="8">
            <Input
              label="Razão Social"
              value={item.company.legalName}
              disabled
            />
          </Col>
          <Col xs="4">
            <Input
              mask="99.999.999/9999-99"
              label="CNPJ"
              value={item.company.cnpj}
              disabled
            />
          </Col>
          <Col xs="8">
            <Input
              label="Nome Fantasia"
              value={item.company.fantasyName}
              disabled
            />
          </Col>
          <Col xs="4">
            <Input
              label="Código"
              value={item.company.code}
              disabled
            />
          </Col>
          <Col xs="8">
            <Input
              label="E-mail"
              value={item.company.email}
              disabled
            />
          </Col>
          <Col xs="4">
            <Input
              label="Limite de motoristas"
              value={item.company?.driversLimit || 0}
              disabled
            />
          </Col>
        </Row>
      }
    </div>
  )
}

export default Detail
