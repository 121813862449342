import { APP_API_URL } from '~/config'
import {
  getErrorInfo,
  preventBomb,
  getLastResponse,
  saveLastResponse
} from '~/utils'

export async function API({
  externalUrl,
  endpoint = '',
  method = 'get',
  body,
  formData,
  headers,
  responseType = 'json'
}, token) {
  const baseUrl = APP_API_URL

  const defaultHeader = formData ? {} : {
    'Content-Type': 'application/json'
  }

  if(token) {
    defaultHeader['Authorization'] = `Bearer ${token}`
  }

  const destination = externalUrl || (baseUrl + endpoint)
  const request = {
    method,
    headers: {
      ...defaultHeader,
      ...headers
    },
    body: body ? JSON.stringify(body) : formData || null
  }

  if (preventBomb({ destination, request })) {
    return getLastResponse()
  }

  const response = await fetch(destination, request)
    .then(rawResponse => {
      if(rawResponse.ok) {
        return rawResponse
      } else {
        throw rawResponse
      }
    })
    .catch(rawResponse => {
      return rawResponse[responseType]()
        .then(errBody => {
          if(typeof errBody === 'string') {
            return new Error(errBody)
          } else {
            const message = getErrorInfo(errBody)
            return new Error(message)
          }
        })
        .catch(err => {
          switch (true) {
            case !!rawResponse.message:
              return new Error(rawResponse.message)
            case !!rawResponse.statusText:
              return new Error(rawResponse.statusText)
            case !!rawResponse.status:
              return new Error(rawResponse.status)
            case !!rawResponse:
              return new Error(rawResponse)
            case err instanceof Error:
              return err
            default:
              return new Error('UNKNOWN_ERROR')
          }
        })
        .then(parsedError => {
          throw parsedError
        })
    })
    .then(rawResponse => {
      return rawResponse[responseType]().catch(err => true)
    })
    .then(parsedResponse => {
      return parsedResponse
    })

  saveLastResponse(response)
  return response
}
