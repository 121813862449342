import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInYears from 'date-fns/differenceInYears'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import intervalToDuration from 'date-fns/intervalToDuration'

export const DateUtils = {
  differenceInDays: (time1, time2) => differenceInDays(time1, time2),

  differenceInMilliseconds: (time1, time2) => differenceInMilliseconds(time1, time2),

  differenceInSeconds: (time1, time2) => differenceInSeconds(time1, time2),

  differenceInMinutes: (time1, time2) => differenceInMinutes(time1, time2),

  differenceInHours: (time1, time2) => differenceInHours(time1, time2),

  fullDate: date => format(date || new Date(), 'E, dd MMM yyyy', { locale: ptBR }),

  formatDate: (date, mask = 'dd MMM yyyy') => {
    const dt = typeof date === 'string' ? date.includes('Z') ? null : date.split('-') : null
    return format(
      dt ? new Date(dt[0], (dt[1] - 1), dt[2]) : date ? new Date(date) : new Date(),
      mask,
      { locale: ptBR }
    )
  },

  formatDateWithTime: date => {
    const dt = typeof date === 'string' ? date.includes('Z') ? null : date.split('-') : null
    return format(
      dt ? new Date(dt[0], (dt[1] - 1), dt[2]) : date ? new Date(date) : new Date(),
      'dd MMMM yyyy, HH:mm',
      { locale: ptBR }
    )
  },

  intervalToDuration: ({ start = 0, end = 0 }) => intervalToDuration({ start, end }),

  rangeDate: (startDate, endDate, steps = 1) => {
    const dateArray = []
    let currentDate = new Date(startDate)

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate))
      currentDate.setUTCDate(currentDate.getUTCDate() + steps)
    }

    return dateArray
  },

  toDate: date => {
    const dt = typeof date === 'string' ? date.includes('Z') ? null : date.split('-') : null
    return dt ? new Date(dt[0], (dt[1] - 1), dt[2]) : date ? new Date(date) : new Date()
  },

  toTime: time => {
    const tm = typeof time === 'string' ? new Date() : null

    if (tm) {
      tm.setHours(time.slice(0, 2))
      tm.setMinutes(time.slice(3, 5))
    }

    return tm ? tm : time ? new Date(time) : new Date()
  },

  parseDate: date => {
    const dt = typeof date === 'string' ? date.includes('Z') ? null : date.split('-') : null
    return format(dt ? new Date(dt[0], (dt[1] - 1), dt[2]) : date ? new Date(date) : new Date(), 'yyyy-MM-dd')
  },

  hourMinutes: time => {
    const tm = typeof time === 'string' ? new Date() : null

    if(tm) {
      tm.setHours(time.slice(0, 2))
      tm.setMinutes(time.slice(3, 5))
    }

    return format(tm ? tm : time ? new Date(time) : new Date(), 'HH:mm')
  },

  validBirthDate: date => {
    const dt = typeof date === 'string' ? date.includes('Z') ? null : date.split('-') : null
    return differenceInYears(new Date(), (dt ? new Date(dt[0], (dt[1] - 1), dt[2]) : new Date(date))) >= 18
  }
}
