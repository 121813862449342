import React from 'react'

const training = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="Path_12049" data-name="Path 12049" d="M372,2524a10,10,0,1,1,10-10A10,10,0,0,1,372,2524Zm0-2a7.995,7.995,0,1,0-5.657-2.34A8,8,0,0,0,372,2522Zm-1.378-11.59,4.879,3.26a.329.329,0,0,1,.131.14.407.407,0,0,1,0,.38.329.329,0,0,1-.131.14l-4.88,3.26a.465.465,0,0,1-.2.06.383.383,0,0,1-.208-.05.371.371,0,0,1-.154-.14.392.392,0,0,1-.057-.21v-6.5a.392.392,0,0,1,.057-.21.474.474,0,0,1,.155-.15.373.373,0,0,1,.208-.04A.364.364,0,0,1,370.622,2510.41Z" transform="translate(-5547 -10316)" className={`fill-${color}`} />
    </g>
  </svg>
)

export default training
