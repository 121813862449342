import { API } from './api'
import { requestErrorHandler } from '~/utils'

// user
export function login({ body }) {
  const { identifier, password } = body

  return API({
    endpoint: '/auth/local',
    method: 'post',
    body: {
      identifier,
      password
    }
  }).catch(requestErrorHandler)
}

export function loginCpf({ body }) {
  const { cpf, password } = body

  return API({
    endpoint: '/login-cpf',
    method: 'post',
    body: {
      cpf,
      password
    }
  }).catch(requestErrorHandler)
}

export function forgotPassword({ body }) {
  const { email } = body

  return API({
    endpoint: '/auth/forgot-password',
    method: 'post',
    body: {
      email
    }
  }).catch(requestErrorHandler)
}

export function resetPassword({ body }) {
  const { code, password, passwordConfirmation } = body

  return API({
    endpoint: '/auth/reset-password',
    method: 'post',
    body: {
      code,
      password,
      passwordConfirmation
    }
  }).catch(requestErrorHandler)
}

export function newUserPassword({ body, token }) {
  return API({
    endpoint: '/update-password',
    method: 'post',
    body
  }, token).catch(requestErrorHandler)
}


// general
export function getUserById({ id, token }) {
  return API({
    endpoint: `/users/${id}`
  }, token).catch(requestErrorHandler)
}

export function createUser({ body, token }) {
  return API({
    endpoint: '/users',
    method: 'post',
    body
  }, token).catch(requestErrorHandler)
}

export function editUser({ body, id, token }) {
  return API({
    endpoint: `/users/${id}`,
    method: 'put',
    body
  }, token).catch(requestErrorHandler)
}

export function getAdminsFromCompany({ companyId, token }) {
  return API({
    endpoint: `/users?role.id=4&company.id=${companyId}`
  }, token).catch(requestErrorHandler)
}



// companies
export function countCompanies({ filter = '', token }) {
  return API({
    endpoint: `/companies/count?${filter}`
  }, token).catch(requestErrorHandler)
}

export function getCompanies({ filter = '', token }) {
  return API({
    endpoint: `/companies?_sort=legalName:ASC&${filter}`
  }, token).catch(requestErrorHandler)
}

export function createCompany({ body, token }) {
  return API({
    endpoint: '/companies',
    method: 'post',
    body
  }, token).catch(requestErrorHandler)
}

export function editCompany({ body, id, token }) {
  return API({
    endpoint: `/companies/${id}`,
    method: 'put',
    body
  }, token).catch(requestErrorHandler)
}


// drivers
export function countDrivers({ filter = '', company, token }) {
  return API({
    endpoint: `/users/count?role.id=1&${filter}${company ? '&company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function getDrivers({ filter = '', company, token }) {
  return API({
    endpoint: `/users?role.id=1&_sort=name:ASC&${filter}${company ? '&company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function getDriver({ id, token }) {
  return API({
    endpoint: `/users/${id}`
  }, token).catch(requestErrorHandler)
}

export function updateDriver({ body, id, token }) {
  return API({
    method: 'put',
    body,
    endpoint: `/users/${id}`
  }, token).catch(requestErrorHandler)
}


// time setting
export function countTimeSetting({ filter = '', company, token }) {
  return API({
    endpoint: `/hour-adjustments/count?${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function getTimeSettings({ filter = '', company, token }) {
  return API({
    endpoint: `/hour-adjustments?_sort=created_at:DESC&${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function deleteTimeSetting({ id, token }) {
  return API({
    method: 'delete',
    endpoint: `/hour-adjustments/${id}`
  }, token).catch(requestErrorHandler)
}


// absences
export function countAbsences({ filter = '', company, token }) {
  return API({
    endpoint: `/registry-absences/count?${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function getAbsences({ filter = '', company, token }) {
  return API({
    endpoint: `/registry-absences?_sort=created_at:DESC&${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}


// journey
export function countJourneys({ filter = '', company, token }) {
  return API({
    endpoint: `/journeys/count?${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function getJourneys({ filter = '', company, token }) {
  return API({
    endpoint: `/journeys?_sort=created_at:DESC&${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function getJourneysByDate({ beginsAt, endsAt, userId, filter = '', company, token }) {
  let dateFilter = '';
  
  if (beginsAt) {
    dateFilter += `&begins_at_gt=${beginsAt}`;
  }
  
  if (endsAt) {
    dateFilter += `&ends_at_lt=${endsAt}`;
  }
  
  return API({
    endpoint: `/journeys?_sort=created_at:DESC${filter ? `&${filter}` : ''}${company ? `&userPermissions.company.id=${company}` : ''}${userId ? `&_where[userPermissions.id]=${userId}` : ''}${dateFilter}`
  }, token).catch(requestErrorHandler);
}


export function editJourney({ body, id, token }) {
  return API({
    body,
    method: 'put',
    endpoint: `/journeys/${id}`
  }, token).catch(requestErrorHandler)
}

export function newCostReport({ body, token }) {
  return API({
    body,
    method: 'post',
    endpoint: '/cost-reports',
  }, token).catch(requestErrorHandler)
}

export function editCostReport({ body, id, token }) {
  return API({
    method: 'put',
    endpoint: `/cost-reports/${id}`,
    body: {
      ...body,
      totalValueNormalHourly06To17: null,
      totalValueExtraHourly18To05: null,
      totalValueNightly: null,
      totalValueWeekend: null,
      totalValueWait: null
    }
  }, token).catch(requestErrorHandler)
}

export function getCostReport({ filter = '', token }) {
  return API({
    endpoint: `/cost-reports?${filter}`
  }, token).catch(requestErrorHandler)
}


// help
export function getHelps({ filter = '', company, token }) {
  return API({
    endpoint: `/helps?_sort=created_at:DESC&${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function countHelps({ filter = '', company, token }) {
  return API({
    endpoint: `/helps/count?${filter}${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function countHelpsWaiting({ company, token }) {
  return API({
    endpoint: `/helps/count?status=waiting${company ? '&userPermissions.company.id=' + company : ''}`
  }, token).catch(requestErrorHandler)
}

export function getHelp({ id, token }) {
  return API({
    endpoint: `/helps/${id}`
  }, token).catch(requestErrorHandler)
}


export function updateHelp({ body, id, token }) {
  const { status } = body

  return API({
    method: 'put',
    body: { status },
    endpoint: `/helps/${id}`
  }, token).catch(requestErrorHandler)
}


// trainings
export function getTrainings({ filter = '', token }) {
  return API({
    endpoint: `/trainings?_sort=created_at:DESC&${filter}`
  }, token).catch(requestErrorHandler)
}

export function createTraining({ body, token }) {
  return API({
    method: 'post',
    body,
    endpoint: '/trainings'
  }, token).catch(requestErrorHandler)
}


export function updateTraining({ body, id, token }) {
  return API({
    method: 'put',
    body,
    endpoint: `/trainings/${id}`
  }, token).catch(requestErrorHandler)
}


// gifts
export function getGifts({ filter = '', token }) {
  return API({
    endpoint: `/gifts?_sort=created_at:DESC&${filter}`
  }, token).catch(requestErrorHandler)
}

export function createGift({ body, token }) {
  return API({
    method: 'post',
    body,
    endpoint: '/gifts'
  }, token).catch(requestErrorHandler)
}

export function updateGift({ body, id, token }) {
  return API({
    method: 'put',
    body,
    endpoint: `/gifts/${id}`
  }, token).catch(requestErrorHandler)
}

export function getGiftRules({ filter = '', token }) {
  return API({
    endpoint: `/rules-gifts?_sort=created_at:DESC&${filter}`
  }, token).catch(requestErrorHandler)
}

export function updateGiftRule({ body, id, token }) {
  return API({
    method: 'put',
    body,
    endpoint: `/rules-gifts/${id}`
  }, token).catch(requestErrorHandler)
}

export function createGiftRule({ body, token }) {
  return API({
    method: 'post',
    body,
    endpoint: '/rules-gifts'
  }, token).catch(requestErrorHandler)
}


// file
export function postImage({ file, token }) {
  const formData = new FormData()
  formData.append('files', file)

  return API({
    endpoint: '/upload',
    method: 'post',
    formData
  }, token).catch(requestErrorHandler)
}


// terms
export function getTerms() {
  return API({
    responseType: 'text',
    endpoint: '/terms-use'
  }).catch(requestErrorHandler)
}

export function getPrivacy() {
  return API({
    responseType: 'text',
    endpoint: '/privacy-policy'
  }).catch(requestErrorHandler)
}
