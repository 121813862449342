import React, { useState, useEffect, useContext, useRef } from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import qs from 'qs'

import Tabs from '~/components/Tabs'
import Input from '~/components/Input'
import Button from '~/components/Button'
import Checkbox from '~/components/Checkbox'
import DatePicker from '~/components/Datepicker'

import avatar from '~/images/avatar.jpg'
import logo from '~/images/logo2.png'
import icon1 from '~/images/report-calendar-1.svg'
import icon2 from '~/images/report-calendar-2.svg'
import icon3 from '~/images/report-calendar-3.svg'
import icon4 from '~/images/report-calendar-4.svg'
import icon5 from '~/images/report-calendar-5.svg'

import { JourneyContext } from '~/contexts'
import { format, DateUtils, numerize } from '~/utils'

import './styles.scss'

const Detail = ({ content, onClose }) => {
  const servicesJourney = useContext(JourneyContext)

  let componentPrintRef = useRef()

  const [user] = useState(content)
  const [journeys, setJourneys] = useState([])
  const [costReport, setCostReport] = useState({})
  const [selectedPeriod, setSelectedPeriod] = useState([])
  const [type, setType] = useState('diario')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedEndDate, setSelectedEndDate] = useState(new Date())
  const [loadingData, setLoadingData] = useState(true)

  const handleChangeDate = date => {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    const endDate = new Date(date)

    if (type === 'diario') {
      endDate.setDate(endDate.getDate() + 1)
    } else if (type === 'semanal') {
      endDate.setDate(endDate.getDate() + 7)
    } else if (type === 'mensal') {
      endDate.setMonth(endDate.getMonth() + 1)
    }

    setSelectedDate(date)
    setSelectedEndDate(endDate)

    const periodAllDates = DateUtils.rangeDate(date, new Date(endDate).setDate(endDate.getDate() - 1))
    setSelectedPeriod(periodAllDates)
  }

  const sum = (arr, key, keyToDiscount) => {
    return arr.map(item => (keyToDiscount ? item[key] - item[keyToDiscount] : item[key])).reduce((prev, curr) => prev + curr, 0)
  }

  const getCostReport = () => {
    const moreParams = {
      'user.id': user.id,
      date_gte: DateUtils.parseDate(selectedDate),
      date_lt: DateUtils.parseDate(selectedEndDate)
    }
    const query = qs.stringify(moreParams)
    const params = { filter: query }

    servicesJourney.exec.getCostReport(params)
      .then(res => {
        if(res?.length) {
          let cost = {
            hasInfo: true,
            totalValueNormalHourly06To17: sum(res, 'totalValueNormalHourly06To17'),
            totalValueExtraHourly18To05: sum(res, 'totalValueExtraHourly18To05', 'totalWorkload18To05'),
            totalValueNightly: sum(res, 'totalValueNightly', 'totalWorkloadNightly22To04'),
            totalValueWeekend: sum(res, 'totalValueWeekend'),
            totalValueWait: sum(res, 'totalValueWait'),
            totalWorkload6To17: sum(res, 'totalWorkload6To17'),
            totalWorkload18To05: sum(res, 'totalWorkload18To05'),
            totalWorkloadNightly22To04: sum(res, 'totalWorkloadNightly22To04'),
            totalWorkloadWeekend: sum(res, 'totalWorkloadWeekend'),
            totalWorkloadWait: sum(res, 'totalWorkloadWait'),
            totalValue: 0,
            totalWorkload: 0
          }

          cost['totalValue'] = cost.totalValueNormalHourly06To17 + cost.totalValueExtraHourly18To05 + cost.totalValueNightly + cost.totalValueWeekend + cost.totalValueWait
          cost['totalWorkload'] = cost.totalWorkload6To17 + cost.totalWorkloadWeekend + cost.totalWorkloadWait

          setCostReport(cost)
        } else {
          setCostReport({})
        }

        setLoadingData(false)
      })
      .catch(err => onClose())
  }

  const timeConvert = (num = 0) => {
    if (num < 0) {
      return '0h'
    }

    const hours = Math.floor(num)
    const minutes = Math.floor((num - hours) * 60)
    return `${hours}h${minutes > 0 ? numerize(minutes) + 'm' : ''}`
  }

  useEffect(() => {
    handleChangeDate(new Date())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleChangeDate(selectedDate)
    // eslint-disable-next-line
  }, [type])

  useEffect(() => {
    setLoadingData(true)

    const moreParams = {
      'userPermissions.id': user.id,
      begins_at_gte: selectedDate.getTime(),
      begins_at_lt: selectedEndDate.getTime()
    }
    const query = qs.stringify(moreParams)
    const params = { filter: query }

    servicesJourney.exec.getJourneys(params)
      .then(res => {
        if (res?.length) {
          const parsed = res.map(item => {
            const start = new Date(item.begins_at)
            start.setHours(0)
            start.setMinutes(0)
            start.setSeconds(0)
            start.setMilliseconds(0)

            return {
              date: start,
              journey: item
            }
          })

          setJourneys(parsed)
        } else {
          setJourneys([])
        }

        getCostReport()
      })
      .catch(err => onClose())
    // eslint-disable-next-line
  }, [user, selectedDate, selectedEndDate, selectedPeriod])

  const JourneyTimeItem = ({ values }) => (
    values?.length > 0 ? (
      values.map((item, key) => (
        <Row key={key} className="journey-time">
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center"
          >
            {DateUtils.formatDate(item.begins_at, 'HH:mm')}
            <span className="journey-time-separator">{' | '}</span>
            {DateUtils.formatDate(item.ends_at, 'HH:mm')}
          </Col>
        </Row>
      ))
    ) : (
      <Col className = "d-flex flex-column align-items-center justify-content-center py-3">NHR</Col>
    )
  )

  const JourneyTimes = ({ date }) => {
    const journeysDay = journeys.filter(_item => _item.date.getTime() === date.getTime())

    return journeysDay?.length > 0 ? (
      journeysDay.map((item, key) => (
        <Row key={key} className="journey-item">
          <Col className="d-flex flex-column align-items-center justify-content-center w20">
            <JourneyTimeItem values={item.journey?.steps || []} />
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center w20 py-3">
            <JourneyTimeItem values={item.journey?.stops?.filter(_item => _item.mode === 'coffee') || []} />
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center w20 py-3">
            <JourneyTimeItem values={item.journey?.stops?.filter(_item => _item.mode === 'meal') || []} />
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center w20 py-3">
            <JourneyTimeItem values={item.journey?.stops?.filter(_item => _item.mode === 'help') || []} />
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center w20 py-3">
            <JourneyTimeItem values={item.journey?.stops?.filter(_item => _item.mode === 'wait') || []} />
          </Col>
        </Row>
      ))
    ) : (
      <Row>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
      </Row>
    )
  }

  return (
    <div className="report-detail">
      <div className="user">
        <div className="user-detail">
          <div className="user-avatar">
            <img
              src={
                user?.avatarImage?.url
                  ? format.urlFile(user?.avatarImage?.url)
                  : avatar
              }
              alt={user.name}
            />
          </div>
          <h3 className="user-name">
            {user.name}
            <br />
            #{user.id}
          </h3>
        </div>
        <div className="user-action">
          <ReactToPrint content={() => componentPrintRef}>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <Button
                  outline
                  label="Exportar"
                  icon="download"
                  color="primary"
                  onClick={handlePrint}
                  disabled={loadingData}
                />
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      </div>

      <Tabs
        active="worked"
        options={[
          { label: 'Relatório do motorista', value: 'worked' }
        ]}
        className="mb-3"
      />

      <Row className="mb-3">
        <Col xs="9" className="d-flex flex-row align-items-center">
          <Checkbox
            label="Diário"
            checked={type === 'diario'}
            onChange={() => setType('diario')}
            className="me-4 mb-0"
          />
          <Checkbox
            label="Semanal"
            checked={type === 'semanal'}
            onChange={() => setType('semanal')}
            className="me-4 mb-0"
          />
          <Checkbox
            label="Mensal"
            checked={type === 'mensal'}
            onChange={() => setType('mensal')}
            className="mb-0"
          />
        </Col>
        <Col xs="3">
          <DatePicker
            label={(type === 'mensal' || type === 'semanal') && 'Apartir de:'}
            value={selectedDate}
            onChange={handleChangeDate}
            dateFormat="dd MMMM yyyy"
            isClearable={false}
          />
        </Col>
      </Row>

      <div ref={el => componentPrintRef = el}>
        <div className="report-page">
          <Row>
            <Col xs="6">
              <img src={logo} alt="Bino" />
            </Col>
            <Col xs="6" className="text-end">
              <div className="report-interval-title">
                Relatório de horas no período:
              </div>
              <div className="report-interval-value">
                {
                  type !== 'diario' ? (
                    <>
                      De {DateUtils.formatDate(selectedDate, 'dd/MM/yyyy')} à {DateUtils.formatDate(new Date(selectedEndDate).setDate(selectedEndDate.getDate() - 1), 'dd/MM/yyyy')}

                    </>
                  ) : (
                    <>
                      Dia {DateUtils.formatDate(new Date(selectedDate), 'dd/MM/yyyy')}
                    </>
                  )
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-5">
              <div className="card-report">
                <Row>
                  <Col xs="4">
                    <Input
                      label="Nome"
                      value={user?.name}
                      disabled
                    />
                  </Col>
                  <Col xs="4">
                    <Input
                      label="Empresa"
                      value={user?.company?.fantasyName}
                      disabled
                    />
                  </Col>
                  <Col xs="4">
                    <Input
                      label="Email"
                      value={user?.email}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Telefone"
                      value={user?.phone ? format.phone(user.phone) : ''}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Data de Nasc."
                      value={user?.birthDate ? DateUtils.formatDate(user?.birthDate) : ''}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="CPF"
                      value={format.cpf(user?.cpf || '')}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="CNH"
                      value={user?.cnh}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Categoria"
                      value={user?.cnhCategory}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Validade"
                      value={user?.cnhValidate ? DateUtils.formatDate(user?.cnhValidate) : ''}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="CEP"
                      value={user?.zipcode}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Número"
                      value={user?.number}
                      disabled
                    />
                  </Col>
                  {
                    user?.complement &&
                    <Col xs="8">
                      <Input
                        label="Complemento"
                        value={user.complement}
                        disabled
                      />
                    </Col>
                  }
                  {
                    (user?.address || user?.district || user?.uf || user?.city) &&
                    <Col xs="12">
                      <p className="text-gray-dark">
                        {user?.address}
                        {(user?.address && user?.district) && ' - '}
                        {user?.district}<br />
                        {user?.city}
                        {(user?.city && user?.uf) && ' - '}
                        {user?.uf}
                      </p>
                    </Col>
                  }
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            {
              loadingData ? (
                <div className="empty-state">
                  <div className="mb-3">Obtendo informações</div>
                  <Spinner size="lg" color="dark" />
                </div>
              ) : (
                <>
                  {
                    (journeys?.length > 0 && selectedPeriod?.length > 0) ? (
                      <Col xs="12" className="mt-5">
                        <div className="card-report calendar">
                          <Row>
                            <Col xs="3" className="calendar-title d-flex align-items-center justify-content-center">
                              Data
                            </Col>
                            <Col xs="9">
                              <Row>
                                <Col className="text-center w20">
                                  <img src={icon1} alt="Relatório" className="calendar-icon" />
                                  <div className="calendar-icon-label">
                                    Em<br />trânsito
                                  </div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={icon2} alt="Relatório" className="calendar-icon" />
                                  <div className="calendar-icon-label">
                                    Pausa para<br />café
                                  </div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={icon3} alt="Relatório" className="calendar-icon" />
                                  <div className="calendar-icon-label">
                                    Pausa para<br />refeições
                                  </div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={icon4} alt="Relatório" className="calendar-icon" />
                                  <div className="calendar-icon-label">
                                    Pausa para<br />S.O.S.
                                  </div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={icon5} alt="Relatório" className="calendar-icon" />
                                  <div className="calendar-icon-label">
                                    Pausa para<br />espera
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          {
                            selectedPeriod.map((item, key) => (
                              <Row className="calendar-day" key={key}>
                                <Col xs="3" className="calendar-date d-flex align-items-center justify-content-center my-3">
                                  {DateUtils.formatDate(item, 'eeee').slice(0, 3)}
                                  {', '}
                                  {DateUtils.formatDate(item, 'dd/MM/yyyy')}
                                </Col>
                                <Col xs="9">
                                  <JourneyTimes date={item} />
                                </Col>
                              </Row>
                            ))
                          }
                        </div>
                      </Col>
                    ) : (
                      <Col xs="12" className="mt-5">
                        <div className="card-report">
                          <div className="empty-label">
                            Nenhum registro encontrado<br />para o período informado
                          </div>
                        </div>
                      </Col>
                    )
                  }
                </>
              )
            }
          </Row>

          {
            costReport?.hasInfo && (
              <>

                <Row>
                  <Col xs="12" className="mt-5">
                    <div className="card-report">
                      <Row>
                        <Col xs="6">
                          <div className="total-title">Total trabalhadas em trânsito</div>
                          <div className="total-value">{timeConvert(costReport?.totalWorkload6To17 + costReport?.totalWorkloadWeekend)}</div>
                        </Col>
                        <Col xs="6">
                          <div className="total-title">Total trabalhados em espera</div>
                          <div className="total-value">{timeConvert(costReport?.totalWorkloadWait)}</div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="mt-2">
                    <div className="card-report">
                      <Row>
                        <Col xs={12}>
                          <div className="subtotal-title text-center mb-2">Horas a receber por categoria</div>
                          <hr />
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Seg à Sáb</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueNormalHourly06To17)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Dom/Feriados</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueWeekend)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Adicional noturno</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueNightly)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Adicional extra</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueExtraHourly18To05)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Tempo de espera</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueWait)}</div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="mt-4">
                    <div className="card-report">
                      <div className="total-title">Total de horas a receber</div>
                      <div className="total-value">{timeConvert(costReport?.totalValue)}</div>
                    </div>
                  </Col>
                </Row>
              </>
            )
          }

          <Row>
            <Col xs="12" className="mt-5">
              NHR = Não há registros
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Detail
