import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import LayoutMain from '~/components/LayoutMain'
import PageTitle from '~/components/PageTitle'
import Tabs from '~/components/Tabs'
import Toggle from '~/components/Toggle'
import Button from '~/components/Button'
import Table from '~/components/Table'
import SideContent from '~/components/SideContent'

import { JourneyContext } from '~/contexts'

import Detail from './Detail'

const GiftRules = () => {
  const history = useHistory()

  const servicesJourney = useContext(JourneyContext)

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  const columnsTable = [
    {
      label: 'Regra',
      cell: row => (
        <span>
          {row.rule}
        </span>
      )
    },
    {
      label: 'Valor',
      cell: row => (
        <span>
          {row.value} pontos
        </span>
      )
    },
    {
      label: 'Ativa',
      cell: row => (
        <div onClick={e => e.stopPropagation()}>
          <Toggle
            value={row.status}
            onChange={value => handleChangeActiveRule(value, row.id)}
          />
        </div>
      )
    }
  ]

  const handleChangeActiveRule = (value, id) => {
    const body = {
      status: value
    }

    servicesJourney.exec.updateGiftRule({ body, id })
      .then(res => getData())
      .catch(err => {
        toast.error('Não foi possível alterar o status da regra!')
      })
  }

  const getData = useCallback(async () => {
    setLoading(true)

    const response = await servicesJourney.exec.getGiftRules()
    if (!response?.errMessage) {
      setItems(response)
    } else {
      setItems([])
    }

    setLoading(false)
  }, [servicesJourney])

  useEffect(getData, [getData])

  return (
    <LayoutMain>
      <PageTitle icon="gift" title="Brindes" />

      <Row>
        <Col xs="6">
          <Tabs
            active="/brindes/regras"
            options={[
              { label: 'Brindes', value: '/brindes' },
              { label: 'Regras', value: '/brindes/regras' }
            ]}
            onChange={value => history.push(value) }
            className="mb-2"
          />
        </Col>
        <Col xs={{ size: 3, offset: 3 }} className="text-end d-flex justify-content-end align-items-end pb-2">
          <Button
            outline
            label="Adicionar regra"
            icon="more"
            color="primary"
            onClick={() => setSelectedItem(true)}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <Table
            columns={columnsTable}
            items={items}
            loading={loading}
            onRowClick={row => setSelectedItem(row)}
          />
        </Col>
      </Row>

      <SideContent
        opened={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      >
        {
          selectedItem &&
          <Detail
            content={selectedItem}
            onRefreshAll={() => getData(0)}
            onClose={() => setSelectedItem(null)}
          />
        }
      </SideContent>
    </LayoutMain>
  )
}

export default GiftRules
