import React from 'react'

const person = ({ color, size }) => (
  <svg xmlns = "http://www.w3.org/2000/svg" viewBox = "0 0 20 20" className={`size-${size}`}>
    <g transform="translate(2150 -1530)">
      <g transform="translate(-14.485 -31)">
        <path d="M2075.485,2323.381v1.9a5.535,5.535,0,0,0-3.967,1.677,5.736,5.736,0,0,0-1.647,4.038H2068a7.7,7.7,0,0,1,2.189-5.39A7.426,7.426,0,0,1,2075.485,2323.381Zm0-.952a5.715,5.715,0,1,1,5.614-5.714A5.657,5.657,0,0,1,2075.485,2322.429Zm0-1.9a3.81,3.81,0,1,0-3.742-3.81A3.775,3.775,0,0,0,2075.485,2320.524Z" transform="translate(-4201 -750)" className={`fill-${color}`}/>
        <path d="M2073.614,2323.381v1.9a5.535,5.535,0,0,1,3.967,1.677,5.736,5.736,0,0,1,1.647,4.038h1.871a7.7,7.7,0,0,0-2.189-5.39A7.426,7.426,0,0,0,2073.614,2323.381Zm0-.952a5.715,5.715,0,1,0-5.614-5.714A5.657,5.657,0,0,0,2073.614,2322.429Zm0-1.9a3.81,3.81,0,1,1,3.742-3.81A3.776,3.776,0,0,1,2073.614,2320.524Z" transform="translate(-4199.129 -750)" className={`fill-${color}`}/>
      </g>
    </g>
  </svg>
)

export default person
