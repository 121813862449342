import React, { useState, useEffect, useContext, useRef } from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import qs from 'qs'

import Tabs from '~/components/Tabs'
import Input from '~/components/Input'
import Button from '~/components/Button'
import Checkbox from '~/components/Checkbox'
import DatePicker from '~/components/Datepicker'

import avatar from '~/images/avatar.jpg'
import logo from '~/images/logo2.png'
import truck from '~/images/report-calendar-1.svg'
import coffe from '~/images/report-calendar-2.svg'
import lunch from '~/images/report-calendar-3.svg'
import sos from '~/images/report-calendar-4.svg'
import wait from '~/images/report-calendar-5.svg'
import moment from "moment"

import { JourneyContext } from '~/contexts'
import { format, DateUtils, numerize } from '~/utils'

import './styles.scss'

const Detail = ({ content, onClose }) => {
  const servicesJourney = useContext(JourneyContext)

  let componentPrintRef = useRef()

  const [user] = useState(content)
  const [journeys, setJourneys] = useState([])
  const [costReport, setCostReport] = useState({})
  const [selectedPeriod, setSelectedPeriod] = useState([])
  const [type, setType] = useState('diario')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedEndDate, setSelectedEndDate] = useState(new Date())
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState({})

  const handleChangeDate = date => {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    const endDate = new Date(date)

    if (type === 'diario') {
      endDate.setDate(endDate.getDate() + 1)
    } else if (type === 'semanal') {
      endDate.setDate(endDate.getDate() + 7)
    } else if (type === 'mensal') {
      endDate.setMonth(endDate.getMonth() + 1)
    }

    setSelectedDate(date)
    setSelectedEndDate(endDate)

    const periodAllDates = DateUtils.rangeDate(date, new Date(endDate).setDate(endDate.getDate() - 1))
    setSelectedPeriod(periodAllDates)
  }

  const timeConvert = (num = 0) => {
    if (num < 0) {
      return '0h'
    }

    const hours = Math.floor(num)
    const minutes = Math.floor((num - hours) * 60)
    return `${hours}h${minutes > 0 ? numerize(minutes) + 'm' : ''}`
  }

  useEffect(() => {
    handleChangeDate(new Date())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleChangeDate(selectedDate)
    // eslint-disable-next-line
  }, [type])

  useEffect(async () => {
    setLoadingData(true)

    /* const moreParams = {
      'userPermissions.id': user.id,
      begins_at_gte: selectedDate.getTime(),
      begins_at_lt: selectedEndDate.getTime()
    }
    const query = qs.stringify(moreParams)
    const params = { filter: query }

    servicesJourney.exec.getJourneys(params)
      .then(res => {
        if (res?.length) {
          const parsed = res.map(item => {
            const start = new Date(item.begins_at)
            start.setHours(0)
            start.setMinutes(0)
            start.setSeconds(0)
            start.setMilliseconds(0)

            return {
              date: start,
              journey: item
            }
          })

          setJourneys(parsed)
        } else {
          setJourneys([])
        }

        getCostReport()
      })
      .catch(err => onClose())
    // eslint-disable-next-line */

    const data = calculateHours2(await servicesJourney.exec.getJourneysByDate({ beginsAt: selectedDate.getTime(), endsAt: selectedEndDate.getTime(), userId: user.id }), ['2024-04-01'], ['2024-04-01'])
    setData(data)
    console.log(data)
    setLoadingData(false)
  }, [user, selectedDate, selectedEndDate, selectedPeriod])

  function calculateHours2(data, holidays) {
    let lastEnd = null; // Variável para armazenar o fim da última jornada
    const results = data.map((entry) => {
      const { begins_at, ends_at, stops, steps } = entry;
      const begins_at_mom = moment(begins_at);
      const ends_at_mom = moment(ends_at);

      // Formate a data no formato desejado
      //const formattedDate = date.format('DD/MM/YYYY HH:mm:ss');

      let normais = moment.utc(moment(ends_at_mom).diff(moment(begins_at_mom)));


      // Definir o limite máximo de 7 horas (em milissegundos)
      const maxNormais = moment.duration(7, 'hours');
      let extras = moment.duration(0);

      if (normais > maxNormais) {
        // Se o tempo normal exceder 7 horas, calcular o tempo extra
        extras = moment.duration(normais - maxNormais);
        normais = moment.utc(maxNormais.asMilliseconds());
      }

      //extras = moment.utc(extras.asMilliseconds())
      // Calcular horas noturnas (considerando período noturno das 22:00 às 05:00)
      const nightStart = moment(begins_at_mom).set({ hour: 22, minute: 0 });
      const nightEnd = moment(begins_at_mom).set({ hour: 5, minute: 0 }).add(1, 'day');
      let normaisNoturnas = moment.duration(0);
      let extrasNoturnas = moment.duration(0);

      if (begins_at_mom.isBetween(nightStart, nightEnd) || ends_at_mom.isBetween(nightStart, nightEnd)) {
        const overlapStart = moment.max(begins_at_mom, nightStart);
        const overlapEnd = moment.min(ends_at_mom, nightEnd);
        const overlapDuration = moment.duration(overlapEnd.diff(overlapStart));

        if (extras.asMilliseconds() > 0) {
          if (overlapDuration > extras) {
            extrasNoturnas = extras;
            normaisNoturnas = overlapDuration.subtract(extras);
          } else {
            extrasNoturnas = overlapDuration;
          }
        } else {
          normaisNoturnas = overlapDuration;
        }
      }

      // Calcular horas em feriados e DSR
      const isHolidayOrDSR = holidays.includes(begins_at_mom.format('YYYY-MM-DD')) || begins_at_mom.day() === 0; // Verifica se é feriado ou domingo
      let feriadosEDSRNormais = moment.utc(moment.duration(0));
      let feriadosEDSRNoturnas = moment.utc(moment.duration(0));

      if (isHolidayOrDSR) {
        feriadosEDSRNormais = normais;
        feriadosEDSRNoturnas = moment.utc(normaisNoturnas.asMilliseconds());
      }

      // Convert stops times from milliseconds to Date objects
      let espera30 = moment.duration(0), refeicao = moment.duration(0), coffee = moment.duration(0), ajuda = moment.duration(0), naoApontado = moment.duration(0);

      stops.forEach(stop => {
        const begins_at_stop = moment(stop.begins_at);
        const ends_at_stop = moment(stop.ends_at);
        const duration = moment.duration(ends_at_stop.diff(begins_at_stop));

        switch (stop.mode) {
          case 'help':
            ajuda.add(duration);
            break;
          case 'meal':
            refeicao.add(duration);
            break;
          case 'coffee':
            coffee.add(duration);
            break;
          default:
            espera30.add(duration);
        }
      });

      // Calcular horas de condução
      let horasConducao = moment.duration(normais).add(extras).subtract(refeicao).subtract(espera30);

      // Calcular horas de repouso
      let horasRepouso = moment.duration(0);
      if (lastEnd) {
        horasRepouso = moment.duration(begins_at_mom.diff(lastEnd));
      }
      lastEnd = ends_at_mom; // Atualizar o fim da última jornada

      extras = moment.utc(extras.asMilliseconds())
      //normais = moment.utc(normais.asMilliseconds())
      normaisNoturnas = moment.utc(normaisNoturnas.asMilliseconds())
      extrasNoturnas = moment.utc(extrasNoturnas.asMilliseconds())
      feriadosEDSRNormais = feriadosEDSRNormais
      feriadosEDSRNoturnas = feriadosEDSRNoturnas
      espera30 = moment.utc(espera30.asMilliseconds())
      refeicao = moment.utc(refeicao.asMilliseconds())
      ajuda = moment.utc(ajuda.asMilliseconds())
      coffee = moment.utc(coffee.asMilliseconds())
      naoApontado = moment.utc(naoApontado.asMilliseconds())
      horasConducao = moment.utc(horasConducao.asMilliseconds())
      horasRepouso = moment.utc(horasRepouso.asMilliseconds())

      //console.log(begins_at_mom.format('DD/MM/YYYY'), begins_at_mom.format('HH:mm'), ends_at_mom.format('HH:mm'), normais.format('HH:mm'), extras.format('HH:mm'), normaisNoturnas.format('HH:mm'), extrasNoturnas.format('HH:mm'), feriadosEDSRNormais.format('HH:mm'), feriadosEDSRNoturnas.format('HH:mm'));



      return {
        date: begins_at_mom.format('DD/MM/YYYY'),
        startTime: begins_at_mom.format('HH:mm'),
        endTime: ends_at_mom.format('HH:mm'),
        normais: normais.format('HH:mm'),
        normaisNoturnas: normaisNoturnas.format('HH:mm'),
        extras: extras.format('HH:mm'),
        extrasNoturnas: extrasNoturnas.format('HH:mm'),
        feriadosEDSR: feriadosEDSRNormais.format('HH:mm'),
        feriadosEDSRNoturna: feriadosEDSRNoturnas.format('HH:mm'),
        espera30: espera30.format('HH:mm'),
        faltantes: ajuda.format('HH:mm'),
        refeicao: refeicao.format('HH:mm'),
        naoApontado: naoApontado.format('HH:mm'),
        totalHorasExtras: extras.add(extrasNoturnas).format('HH:mm'),
        horasRepouso: horasRepouso.format('HH:mm'),
        horasConducao: horasConducao.format('HH:mm'),
        horasDescanso: moment.utc(moment.duration(0, 'hours').asMilliseconds()).format('HH:mm'),
      };
    })
    return results;
  }

  const JourneyTimeItem = ({ values }) => (
    values !=  "00:00" ? (
      <Row className="journey-time">
        <Col
          xs="12"
          className="d-flex align-items-center justify-content-center"
        >
          {values}
        </Col>
      </Row>
    ) : (
      <Col className="d-flex flex-column align-items-center justify-content-center py-3">NHR</Col>
    )
  )

  const JourneyTimes = (old) => {
    //const journeysDay = journeys.filter(_item => _item.date.getTime() === date.getTime())
    const { date } = old
    console.log("here", date.startTime)
    return date ? (
      <Row className="journey-item">
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.startTime} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.endTime} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.normais} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.normaisNoturnas} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.extras} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.extrasNoturnas} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.feriadosEDSR} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.feriadosEDSRNoturna} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.espera30} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.faltantes} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.refeicao} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.naoApontado} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.totalHorasExtras} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.horasRepouso} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.horasConducao} />
        </Col>
        <Col className="d-flex flex-column align-items-center justify-content-center w20">
          <JourneyTimeItem values={date.horasDescanso} />
        </Col>
      </Row>
    ) : (
      <Row>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
        <Col className="d-flex align-items-center justify-content-center w20 py-3">NHR</Col>
      </Row>
    )
  }

  return (
    <div className="report-detail">
      <div className="user">
        <div className="user-detail">
          <div className="user-avatar">
            <img
              src={
                user?.avatarImage?.url
                  ? format.urlFile(user?.avatarImage?.url)
                  : avatar
              }
              alt={user.name}
            />
          </div>
          <h3 className="user-name">
            {user.name}
            <br />
            #{user.id}
          </h3>
        </div>
        <div className="user-action">
          <ReactToPrint content={() => componentPrintRef}>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <Button
                  outline
                  label="Exportar"
                  icon="download"
                  color="primary"
                  onClick={handlePrint}
                  disabled={loadingData}
                />
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      </div>

      <Tabs
        active="worked"
        options={[
          { label: 'Relatório do motorista', value: 'worked' }
        ]}
        className="mb-3"
      />

      <Row className="mb-3">
        <Col xs="9" className="d-flex flex-row align-items-center">
          <Checkbox
            label="Diário"
            checked={type === 'diario'}
            onChange={() => setType('diario')}
            className="me-4 mb-0"
          />
          <Checkbox
            label="Semanal"
            checked={type === 'semanal'}
            onChange={() => setType('semanal')}
            className="me-4 mb-0"
          />
          <Checkbox
            label="Mensal"
            checked={type === 'mensal'}
            onChange={() => setType('mensal')}
            className="mb-0"
          />
        </Col>
        <Col xs="3">
          <DatePicker
            label={(type === 'mensal' || type === 'semanal') && 'Apartir de:'}
            value={selectedDate}
            onChange={handleChangeDate}
            dateFormat="dd MMMM yyyy"
            isClearable={false}
          />
        </Col>
      </Row>

      <div ref={el => componentPrintRef = el}>
        <div className="report-page">
          {/* Foto do motorista/Data */}
          <Row>
            <Col xs="6">
              <img src={logo} alt="Bino" />
            </Col>
            <Col xs="6" className="text-end">
              <div className="report-interval-title">
                Relatório de horas no período:
              </div>
              <div className="report-interval-value">
                {
                  type !== 'diario' ? (
                    <>
                      De {DateUtils.formatDate(selectedDate, 'dd/MM/yyyy')} à {DateUtils.formatDate(new Date(selectedEndDate).setDate(selectedEndDate.getDate() - 1), 'dd/MM/yyyy')}

                    </>
                  ) : (
                    <>
                      Dia {DateUtils.formatDate(new Date(selectedDate), 'dd/MM/yyyy')}
                    </>
                  )
                }
              </div>
            </Col>
          </Row>
          {/* Dados motorista */}
          <Row>
            <Col className="mt-5">
              <div className="card-report">
                <Row>
                  <Col xs="4">
                    <Input
                      label="Nome"
                      value={user?.name}
                      disabled
                    />
                  </Col>
                  <Col xs="4">
                    <Input
                      label="Empresa"
                      value={user?.company?.fantasyName}
                      disabled
                    />
                  </Col>
                  <Col xs="4">
                    <Input
                      label="Email"
                      value={user?.email}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Telefone"
                      value={user?.phone ? format.phone(user.phone) : ''}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Data de Nasc."
                      value={user?.birthDate ? DateUtils.formatDate(user?.birthDate) : ''}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="CPF"
                      value={format.cpf(user?.cpf || '')}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="CNH"
                      value={user?.cnh}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Categoria"
                      value={user?.cnhCategory}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Validade"
                      value={user?.cnhValidate ? DateUtils.formatDate(user?.cnhValidate) : ''}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="CEP"
                      value={user?.zipcode}
                      disabled
                    />
                  </Col>
                  <Col xs="2">
                    <Input
                      label="Número"
                      value={user?.number}
                      disabled
                    />
                  </Col>
                  {
                    user?.complement &&
                    <Col xs="8">
                      <Input
                        label="Complemento"
                        value={user.complement}
                        disabled
                      />
                    </Col>
                  }
                  {
                    (user?.address || user?.district || user?.uf || user?.city) &&
                    <Col xs="12">
                      <p className="text-gray-dark">
                        {user?.address}
                        {(user?.address && user?.district) && ' - '}
                        {user?.district}<br />
                        {user?.city}
                        {(user?.city && user?.uf) && ' - '}
                        {user?.uf}
                      </p>
                    </Col>
                  }
                </Row>
              </div>
            </Col>
          </Row>
          {/* Tabela de horas */}
          <Row>
            {
              loadingData ? (
                <div className="empty-state">
                  <div className="mb-3">Obtendo informações</div>
                  <Spinner size="lg" color="dark" />
                </div>
              ) : (
                <>
                  {
                    (data?.length > 0 && selectedPeriod?.length > 0) ? (
                      <Col xs="12" className="mt-5">
                        <div className="card-report calendar">
                          {/* Header Tabela */}
                          <Row>
                            <Col xs="2" className="calendar-title d-flex align-items-center justify-content-center">
                              Data
                            </Col>
                            <Col xs="10">
                              <Row>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Início" className="calendar-icon" />
                                  <div className="calendar-icon-label">Início</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Fim" className="calendar-icon" />
                                  <div className="calendar-icon-label">Fim</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Normais" className="calendar-icon" />
                                  <div className="calendar-icon-label">Normais</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Normais Noturnas" className="calendar-icon" />
                                  <div className="calendar-icon-label">Normais Noturnas</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Extras" className="calendar-icon" />
                                  <div className="calendar-icon-label">Extras</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Extras Noturnas" className="calendar-icon" />
                                  <div className="calendar-icon-label">Extras Noturnas</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Feriados e DSR" className="calendar-icon" />
                                  <div className="calendar-icon-label">Feriados e DSR</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Feriados e DSR Noturna" className="calendar-icon" />
                                  <div className="calendar-icon-label">Feriados e DSR Noturna</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Espera 30%" className="calendar-icon" />
                                  <div className="calendar-icon-label">Espera 30%</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Faltantes" className="calendar-icon" />
                                  <div className="calendar-icon-label">Faltantes</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Refeição" className="calendar-icon" />
                                  <div className="calendar-icon-label">Refeição</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Não Apontado" className="calendar-icon" />
                                  <div className="calendar-icon-label">Não Apontado</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Total de Horas Extras" className="calendar-icon" />
                                  <div className="calendar-icon-label">Total de Horas Extras</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Horas de Repouso" className="calendar-icon" />
                                  <div className="calendar-icon-label">Horas de Repouso</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Horas de Condução" className="calendar-icon" />
                                  <div className="calendar-icon-label">Horas de Condução</div>
                                </Col>
                                <Col className="text-center w20">
                                  <img src={wait} alt="Horas de Descanso" className="calendar-icon" />
                                  <div className="calendar-icon-label">Horas de Descanso</div>
                                </Col>
                              </Row>
                            </Col>

                          </Row>
                          {/* dados tabela */}
                          {
                            data.map((item, key) => (
                              <Row className="calendar-day" key={key}>
                                <Col xs="2" className="calendar-date d-flex align-items-center justify-content-center my-3">
                                  {item.date}
                                  {/* {DateUtils.formatDate(item, 'eeee').slice(0, 3)}
                                  {', '}
                                  {DateUtils.formatDate(item, 'dd/MM/yyyy')} */}
                                </Col>
                                <Col xs="10">
                                  <JourneyTimes date={item} />
                                </Col>
                              </Row>
                            ))
                          }
                        </div>
                      </Col>
                    ) : (
                      <Col xs="12" className="mt-5">
                        <div className="card-report">
                          <div className="empty-label">
                            Nenhum registro encontrado<br />para o período informado
                          </div>
                        </div>
                      </Col>
                    )
                  }
                </>
              )
            }
          </Row>
          {/* Resumo tabela de horas */}
          {
            costReport?.hasInfo && (
              <>

                <Row>
                  <Col xs="12" className="mt-5">
                    <div className="card-report">
                      <Row>
                        <Col xs="6">
                          <div className="total-title">Total trabalhadas em trânsito</div>
                          <div className="total-value">{timeConvert(costReport?.totalWorkload6To17 + costReport?.totalWorkloadWeekend)}</div>
                        </Col>
                        <Col xs="6">
                          <div className="total-title">Total trabalhados em espera</div>
                          <div className="total-value">{timeConvert(costReport?.totalWorkloadWait)}</div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="mt-2">
                    <div className="card-report">
                      <Row>
                        <Col xs={12}>
                          <div className="subtotal-title text-center mb-2">Horas a receber por categoria</div>
                          <hr />
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Seg à Sáb</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueNormalHourly06To17)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Dom/Feriados</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueWeekend)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Adicional noturno</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueNightly)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Adicional extra</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueExtraHourly18To05)}</div>
                        </Col>
                        <Col className="w20p">
                          <div className="subtotal-title">Tempo de espera</div>
                          <div className="subtotal-value">{timeConvert(costReport?.totalValueWait)}</div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="mt-4">
                    <div className="card-report">
                      <div className="total-title">Total de horas a receber</div>
                      <div className="total-value">{timeConvert(costReport?.totalValue)}</div>
                    </div>
                  </Col>
                </Row>
              </>
            )
          }

          <Row>
            <Col xs="12" className="mt-5">
              NHR = Não há registros
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Detail
