import React from 'react'

import './styles.scss'

const Tabs = ({
  active,
  options = [],
  onChange,
  className
}) => {
  return (
    <div className={`tabs ${className ? className : ''}`}>
      {
        options.map((item, key) => (
          <div
            key={key}
            className={`tab-item ${active === item.value && 'tab-active'}`}
            onClick={() => onChange(item.value)}
          >
            { item.label }
          </div>
        ))
      }
    </div>
  )
}

export default Tabs
