import React from 'react'

const gift = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <g id="Group_9839" data-name="Group 9839" transform="translate(-4832 2095)">
        <path id="Group" d="M14,0a4,4,0,0,1,3.464,6H22V8H20V18a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V8H0V6H4.536A4,4,0,0,1,11,1.355,3.983,3.983,0,0,1,14,0ZM10,8H4v9h6Zm8,0H12v9h6ZM8,2A2,2,0,0,0,7.85,6L8,6h2V4A2,2,0,0,0,8.3,2.023l-.154-.018Zm6,0a2,2,0,0,0-1.995,1.85L12,4V6h2a2,2,0,0,0,1.995-1.85L16,4a2,2,0,0,0-2-2Z" transform="translate(-354 -9906)" className={`fill-${color}`}/>
      </g>
    </g>
  </svg>
)

export default gift
