import React, { useState, useContext, useEffect, useCallback } from 'react'
import { Row, Col } from 'reactstrap'
import qs from 'qs'

import LayoutMain from '~/components/LayoutMain'
import PageTitle from '~/components/PageTitle'
import CardCount from '~/components/CardCount'
import CardChart from '~/components/CardChart'
import Table from '~/components/Table'
import InputSelect from '~/components/InputSelect'
import Pagination from '~/components/Pagination'
import DashboardMap from '~/components/DashboardMap'

import { UserContext, JourneyContext } from '~/contexts'
import { paginationOptions } from '~/data'
import { DateUtils, numerize } from '~/utils'

const Dashboard = () => {
  const servicesUser = useContext(UserContext)
  const servicesJourney = useContext(JourneyContext)

  const [loadingChartHours, setLoadingChartHours] = useState(true)
  const [chartHours, setChartHours] = useState(null)

  const [loadingChartHelps, setLoadingChartHelps] = useState(true)
  const [chartHelps, setChartHelps] = useState(null)

  const [loadingTable, setLoadingTable] = useState(true)
  const [tableJourneys, setTableJourneys] = useState([])
  const [selectedTableJourneys, setSelectedTableJourneys] = useState(null)
  const [tableJourneysTotalItems, setTableJourneysTotalItems] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)

  const todayEnd = new Date(today)
  todayEnd.setDate(todayEnd.getDate() + 1)

  const getTotalWorkedHours = item => {
    let total = 0

    for (let i = 0; i < item.steps.length; i++) {
      total += item.steps[i].current === true ? 0 : (item.steps[i].ends_at - item.steps[i].begins_at)
    }

    return total
  }

  const countTodayHours = async () => {
    const query = qs.stringify({
      _begins_at_null: false,
      _ends_at_null: false,
      begins_at_gte: today.getTime(),
      ends_at_lt: todayEnd.getTime()
    })
    const params = { filter: query }

    const response = await servicesJourney.exec.getJourneys(params)

    if (response?.length) {
      let total = 0

      for (let i = 0; i < response.length; i++) {
        if (response[i].begins_at >= today.getTime() && response[i].ens_at < todayEnd.getTime()) {
          total += getTotalWorkedHours(response[i])
        }
      }

      if (total > 0) {
        const parsedTotal = DateUtils.intervalToDuration({ end: total })
        return `${numerize(parsedTotal.hours)}h${numerize(parsedTotal.minutes)}`
      } else {
        return '0h'
      }
    } else {
      return '0h'
    }
  }

  const getChartHoursInfo = useCallback(async () => {
    const monthBase = new Date()
    monthBase.setDate(1)
    monthBase.setHours(0)
    monthBase.setMinutes(0)
    monthBase.setSeconds(0)
    monthBase.setMilliseconds(0)

    const array = [null, null, null]

    let hoursByMonth = []

    const promises = array.map(async (_, i) => {
      const monthStart = new Date(monthBase)
      monthStart.setMonth(monthStart.getMonth() - i)
      const monthEnd = new Date(monthBase)
      monthEnd.setMonth(monthEnd.getMonth() - (i - 1))

      const query = qs.stringify({
        _begins_at_null: false,
        _ends_at_null: false,
        begins_at_gte: monthStart.getTime(),
        ends_at_lt: monthEnd.getTime()
      })
      const params = { filter: query }

      const response = await servicesJourney.exec.getJourneys(params)

      if (response?.length) {
        let total = 0

        for (let i = 0; i < response.length; i++) {
          total += getTotalWorkedHours(response[i])
        }

        if (total >= 0) {
          const parsedTotal = DateUtils.intervalToDuration({ end: total })
          hoursByMonth = [
            ...hoursByMonth,
            {
              key: i,
              label: `${monthStart.toLocaleString('pt-BR', { month: 'long' })} ${monthStart.getFullYear()}`,
              value: parsedTotal.hours
            }
          ]
        } else {
          hoursByMonth = [
            ...hoursByMonth,
            {
              key: i,
              label: `${monthStart.toLocaleString('pt-BR', { month: 'long' })} ${monthStart.getFullYear()}`,
              value: 0
            }
          ]
        }
      } else {
        hoursByMonth = [
          ...hoursByMonth,
          {
            key: i,
            label: `${monthStart.toLocaleString('pt-BR', { month: 'long' })} ${monthStart.getFullYear()}`,
            value: 0
          }
        ]
      }
    })

    await Promise.all(promises)

    hoursByMonth.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
    const series = hoursByMonth.map(item => item.value)
    const labels = hoursByMonth.map(item => item.label)

    setChartHours({ labels, series })
    setLoadingChartHours(false)
  }, [servicesJourney])

  const getChartHelpsInfo = useCallback(async () => {
    const monthBase = new Date()
    monthBase.setDate(1)
    monthBase.setHours(0)
    monthBase.setMinutes(0)
    monthBase.setSeconds(0)
    monthBase.setMilliseconds(0)

    const array = [null, null, null]

    let helpsByMonth = []

    const promises = array.map(async (_, i) => {
      const monthStart = new Date(monthBase)
      monthStart.setMonth(monthStart.getMonth() - i)
      const monthEnd = new Date(monthBase)
      monthEnd.setMonth(monthEnd.getMonth() - (i - 1))

      const query = qs.stringify({
        created_at_gte: monthStart.getTime(),
        created_at_lt: monthEnd.getTime()
      })
      const params = { filter: query }

      const response = await servicesJourney.exec.countHelps(params)

      helpsByMonth = [
        ...helpsByMonth,
        {
          key: i,
          label: `${monthStart.toLocaleString('pt-BR', { month: 'long' })} ${monthStart.getFullYear()}`,
          value: response || 0
        }
      ]
    })

    await Promise.all(promises)

    helpsByMonth.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
    const series = helpsByMonth.map(item => item.value)
    const labels = helpsByMonth.map(item => item.label)

    setChartHelps({ labels, series })
    setLoadingChartHelps(false)
  }, [servicesJourney])

  const getTableJourneysInfo = useCallback(async ({ newPage, newItemsPerPage }) => {
    setLoadingTable(true)

    if (newPage >= 0 && newPage !== null) {
      setPage(newPage)
    }

    if (newItemsPerPage >= 0 && newItemsPerPage !== null) {
      setItemsPerPage(newItemsPerPage)
    }

    const query = qs.stringify({
      _start: Math.ceil(((newPage >= 0 && newPage !== null) ? newPage : page) * ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage)),
      _limit: ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage),
      begins_at_gte: today.getTime(),
      ends_at_lt: todayEnd.getTime()
    })
    const params = { filter: query }

    const count = await servicesJourney.exec.countJourneys(params)
    if (!count?.errMessage) {
      setTableJourneysTotalItems(count)

      if (count > 0) {
        const response = await servicesJourney.exec.getJourneys(params)
        if (!response?.errMessage) {
          setTableJourneys(response)
          setSelectedTableJourneys(response?.[0] || null)
        } else {
          setTableJourneys([])
        }
      } else {
        setTableJourneys([])
      }
    } else {
      setTableJourneysTotalItems(0)
      setTableJourneys([])
    }

    setLoadingTable(false)
    // eslint-disable-next-line
  }, [servicesJourney])

  const handleChangePageQuantity = (selected) => {
    getTableJourneysInfo({ newItemsPerPage: Number(selected) })
  }

  const handleChangePage = ({ selected }) => {
    getTableJourneysInfo({ newPage: selected })
  }

  useEffect(() => {
    getChartHoursInfo()
    getChartHelpsInfo()
    getTableJourneysInfo(0)
  }, [getChartHoursInfo, getChartHelpsInfo, getTableJourneysInfo])

  const columnsTable = [
    {
      label: 'ID',
      cell: row => (
        <span>
          #{row.id}
        </span>
      )
    },
    {
      label: 'Nome',
      cell: row => (
        <span>
          {row.userPermissions.name}
        </span>
      )
    },
    {
      label: 'Jornada',
      cell: row => (
        <span className="fw-bold">
          {
            row.ends_at ? (
              <span className="text-success">Encerrada</span>
            ) : (
              <span className="text-info">Em curso</span>
            )
          }
        </span>
      )
    },
  ]

  return (
    <LayoutMain>
      <PageTitle />

      <Row>
        <Col xs="4">
          <CardCount
            title="Motoristas"
            icon="person"
            service={servicesUser.exec.countDrivers}
            toLabel="Ver motoristas"
            toPath="/motoristas"
          />
        </Col>

        <Col xs="4">
          <CardCount
            title="Horas do dia"
            icon="report"
            service={countTodayHours}
            toLabel="Ver horas"
            toPath="/horas"
          />
        </Col>

        <Col xs="4">
          <CardCount
            title="Ocorrências em aberto"
            icon="help"
            service={servicesJourney.exec.countHelpsWaiting}
            toLabel="Ver ocorrências"
            toPath="/ocorrencias"
          />
        </Col>
      </Row>

      <Row>
        <Col xs="6">
          <CardChart
            title="Horas do mês"
            loading={loadingChartHours}
            labels={chartHours?.labels || []}
            series={chartHours?.series || []}
          />
        </Col>
        <Col xs="6">
          <CardChart
            title="Ocorrências do mês"
            loading={loadingChartHelps}
            labels={chartHelps?.labels || []}
            series={chartHelps?.series || []}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mt-3">
          <PageTitle
            icon="locationDuo"
            title="Jornadas do dia"
            className="mb-0"
          />
        </Col>
        <Col xs="6">
          <Table
            columns={columnsTable}
            items={tableJourneys}
            loading={loadingTable}
            selectedRow={selectedTableJourneys}
            onRowClick={row => setSelectedTableJourneys(row)}
          />
        </Col>
        <Col xs="6">
          {
            selectedTableJourneys &&
            <DashboardMap
              item={selectedTableJourneys}
            />
          }
        </Col>
      </Row>
      {
        (!loadingTable && tableJourneysTotalItems) ? (
          <Row className="mt-3">
            <Col xs="3" className="d-flex align-items-center text-gray-dark">
              Exibindo
              <div className="mx-2">
                <InputSelect
                  items={paginationOptions}
                  value={itemsPerPage}
                  onChange={handleChangePageQuantity}
                />
              </div>
              de {tableJourneysTotalItems}
            </Col>
            <Col xs="9" className="d-flex justify-content-end align-items-center">
              <Pagination
                currentPage={page}
                pageCount={Math.ceil(tableJourneysTotalItems / itemsPerPage)}
                onChange={handleChangePage}
              />
            </Col>
          </Row>
        ) : null
      }
    </LayoutMain>
  )
}

export default Dashboard
