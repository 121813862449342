import React, { useContext, useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useHistory } from 'react-router'

import { UserContext } from '~/contexts'
import { Storage } from '~/services'

const RouteNonLogged = (props) => {
  const history = useHistory()

  const servicesUser = useContext(UserContext)

  const [isLogged, setIsLogged] = useState(true)

  useEffect(() => {
    if (!!Storage.get({ key: 'sessionUser' })?.jwt) {
      history.replace('/')
      return null
    }
    setIsLogged(false)
  }, [servicesUser, history])

  return (
    !isLogged &&
    <Route {...props} />
  )
}

export default RouteNonLogged
