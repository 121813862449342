import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'

import Icon from '~/components/Icon'

import './styles.scss'

const CardCount = ({
  service,
  title,
  icon,
  sufixCounter,
  toLabel,
  toPath
}) => {
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    service()
      .then(res => setCount(res))
      .catch(err => console.log(err))
      .then(() => setLoading(false))
  }, [service])

  return (
    <div className="card-count">
      <div className="card-count-header">
        <h4 className="card-title">
          { title }
        </h4>
        <Icon name={icon} size="larger" color="primary" />
      </div>
      <div className="card-count-footer">
        {
          loading ? (
            <Spinner
              size="lg"
              color="dark"
            />
          ) : (
            <h2 className="card-value">
              {count}{sufixCounter && <small>{sufixCounter}</small>}
            </h2>
          )
        }
        <Link to={toPath} className="card-link">
          { toLabel }
          <Icon name="arrowRight" size="larger" color="grayDark" />
        </Link>
      </div>
    </div>
  )
}

export default CardCount
