import React, { useEffect, useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'

import Button from '~/components/Button'
import Input from '~/components/Input'

import { JourneyContext } from '~/contexts'
import { exportData, format, validation, Blocker } from '~/utils'

import CrudAdmins from '../CrudAdmins'

import './styles.scss'

const Detail = ({ content, createItem, onRefresh }) => {
  const blocker = new Blocker()

  const servicesJourney = useContext(JourneyContext)

  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [form, setForm] = useState({
    legalName: '',
    fantasyName: '',
    cnpj: '',
    email: '',
    driversLimit: ''
  })

  const handleChangeForm = (field, value) => {
    setForm({ ...form, [field]: value })
  }

  const handleExport = () => {
    exportData(content, `falta-${content.userPermissions.name.replaceAll(' ', '_')}-${format.dateToApi(new Date(content.created_at))}`)
  }

  const onSubmit = () => {
    setLoadingSubmit(true)

    const body = {
      ...form,
      cnpj: format.removeMask(form.cnpj)
    }

    if(content?.id) {
      servicesJourney.exec.editCompany({ body, id: content.id })
        .then(res => {
          toast.success('Empresa editada com sucesso!')
          onRefresh(res)
        })
        .catch(err => toast.error('Não foi possível editar a empresa!'))
        .then(() => setLoadingSubmit(false))
    } else {
      servicesJourney.exec.createCompany({ body })
        .then(res => {
          toast.success('Empresa criada com sucesso!')
          onRefresh(res)
        })
        .catch(err => toast.error('Não foi possível criar a empresa!'))
        .then(() => setLoadingSubmit(false))
    }

  }

  useEffect(() => {
    if(content?.id) {
      const { fantasyName, legalName, cnpj, email, driversLimit } = content
      setForm({ fantasyName, legalName, cnpj, email, driversLimit })
    } else {
      setForm({
        legalName: '',
        fantasyName: '',
        cnpj: '',
        email: '',
        driversLimit: ''
      })
    }
  }, [content, createItem])

  useEffect(() => {
    setDisabledSubmit(blocker?.value !== false)
  }, [blocker])

  return (
    <div className="company-detail">
      <div className="user">
        <div className="user-detail">
          <h3 className="user-name">
            {
              content?.id ? (
                <>
                  {content.legalName}
                  <br />
                  #{content.id}
                </>
              ) : (
                <>Adicionar empresa</>
              )
            }
          </h3>
        </div>
        <div className="user-action">
          {
            content?.id &&
            <Button
              outline
              label="Exportar"
              icon="download"
              color="primary"
              onClick={() => handleExport()}
            />
          }
        </div>
      </div>

      <Row className="my-3">
        <Col xs="8">
          <Input
            label="Razão Social"
            value={form.legalName || ''}
            onChange={value => handleChangeForm('legalName', value)}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs="4">
          <Input
            mask="99.999.999-9999/99"
            label="CNPJ"
            value={form.cnpj}
            onChange={value => handleChangeForm('cnpj', value)}
            onValidate={[validation.cnpj, blocker.validation]}
          />
        </Col>
        <Col xs="8">
          <Input
            label="Nome Fantasia"
            value={form.fantasyName || ''}
            onChange={value => handleChangeForm('fantasyName', value)}
            onValidate={[validation.required, blocker.validation]}
          />
        </Col>
        <Col xs="4">
          <Input
            label="Código"
            value={content?.code || 'Gerado automáticamente'}
            disabled
          />
        </Col>
        <Col xs="8">
          <Input
            label="E-mail"
            value={form.email || ''}
            onChange={value => handleChangeForm('email', value)}
            onValidate={[validation.email, blocker.validation]}
          />
        </Col>
        <Col xs="4">
          <Input
            type="number"
            label="Limite de motoristas"
            value={String(form.driversLimit || 0)}
            onChange={value => handleChangeForm('driversLimit', value)}
            onValidate={[validation.driversLimit, blocker.validation]}
          />
        </Col>
        <Col xs={{ offset: 8, size: 4 }} className="d-flex flex-column mt-3">
          <Button
            label={`${content?.id ? 'Salvar edição' : 'Adicionar'}`}
            color="primary"
            loading={loadingSubmit}
            disabled={disabledSubmit}
            onClick={onSubmit}
          />
        </Col>
      </Row>

      {
        content?.id &&
        <CrudAdmins
          company={content}
        />
      }
    </div>
  )
}

export default Detail
