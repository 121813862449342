import React from 'react'

const arrowDown = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9840" data-name="Group 9840" transform="translate(-6793 17301)">
      <path id="Path_12049" data-name="Path 12049" d="M0,0H22V22H0Z" transform="translate(6793 -17301)" fill="none"/>
      <g id="Group_9841" data-name="Group 9841" transform="translate(6750 -17274.25) rotate(-90)">
        <path id="Union_10" data-name="Union 10" d="M4.586,9.831.094,5.316a.393.393,0,0,1-.089-.322.405.405,0,0,1,.143-.32L4.591.119A.542.542,0,0,1,5.3.145.419.419,0,0,1,5.3.781L1.17,5.02,5.405,9.276A.42.42,0,0,1,5.29,9.9a.565.565,0,0,1-.318.1A.5.5,0,0,1,4.586,9.831Z" transform="translate(13 49)" className={`fill-${color}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      </g>
    </g>
  </svg>
)

export default arrowDown
