import React from 'react'
import { Button, Spinner } from 'reactstrap'

import Icon from '~/components/Icon'

import './styles.scss'

const _Button = ({
  block,
  className,
  color,
  label,
  onClick,
  size,
  type,
  outline,
  disabled,
  loading,
  icon,
  ...rest
}) => {
  const withLightLoader = ['primary', 'secondary', 'secondary-dark', 'black', 'gray-dark', 'info', 'success', 'danger']

  return (
    <Button
      type={type}
      block={block}
      className={`
        ${color ? outline ? `button-outline-color-${color}` : `button-color-${color}` : ''}
        ${size && `button-size-${size}`}
        ${outline && 'button-outline'}
        ${className || ''}
      `}
      disabled={disabled || loading}
      onClick={() => onClick?.()}
      {...rest}
    >
      {
        (loading && !icon)
          ? <Spinner
              size="sm"
              color={outline ? color : withLightLoader.find(item => item === color) ? 'light' : 'dark'}
            />
          : label
      }

      {
        icon && (
          loading ? (
            <Spinner
              size="sm"
              color={outline ? color : withLightLoader.find(item => item === color) ? 'light' : 'dark'}
              className={ label ? 'ms-2' : '' }
            />
          ) : (
            <Icon
              name={icon}
              size={size}
              color={outline ? color : withLightLoader.find(item => item === color) ? 'white' : 'black'}
              className={ label ? 'ms-2' : '' }
            />
          )
        )
      }
    </Button>
  )
}

export default _Button
