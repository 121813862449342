import React from 'react'

import './styles.scss'

const Checkbox = ({ label, checked, onChange, disabled, className }) => (
  <label className={`checkbox-container ${disabled ? 'disabled' : ''} ${className ? className : ''}`}>
    { label }
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={e => onChange?.(e.target.checked)}
    />
    <span className="checkmark"></span>
  </label>
)

export default Checkbox
