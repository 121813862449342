import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'

import Input from '~/components/Input'
import Checkbox from '~/components/Checkbox'
import Icon from '~/components/Icon'
import Button from '~/components/Button'

import avatar from '~/images/avatar.jpg'

import { UserContext } from '~/contexts'
import { format, DateUtils } from '~/utils'

import './styles.scss'

const Detail = ({ content, onRefreshAll, onClose }) => {
  const weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

  const servicesUser = useContext(UserContext)

  const [item] = useState(content)
  const [driver, setDriver] = useState(content)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingDecline, setLoadingDecline] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)

  const onDecline = () => {
    setLoadingDecline(true)

    servicesUser.exec.deleteTimeSetting({ id: item.id })
      .then(res => {
        toast.success('Solicitação de ajuste de horas reprovado!')
        onRefreshAll()
        onClose()
      })
      .catch(err => toast.error('Não foi possível reprovar a solicitação!'))
      .then(() => setLoadingDecline(false))
  }

  const onApprove = async () => {
    setLoadingApprove(true)

    const body = {
      cpf: driver.cpf,
      cnh: driver.cnh,
      phone: driver.phone,
      email: driver.email,
      username: driver.username,
      profileCategory: { id: item.profileCategory.id },
      profileWorkDays: [...item.profileWorkDays.map(item => ({ id: item.id }))],
      profileShifts: [{ id: item.profileShifts[0].id }],
      lunchStart: item.lunchStart,
      lunchEnd: item.lunchEnd,
      workHourStart: item.workHourStart,
      workHourEnd: item.workHourEnd
    }

    const response = await servicesUser.exec.updateDriver({ body, id: driver.id })

    if(!response?.id) {
      toast.error('Não foi possível aprovar a solicitação de ajuste de horas!')
      return
    }

    servicesUser.exec.deleteTimeSetting({ id: item.id })
      .then(res => {
        toast.success('Solicitação de ajuste de horas aprovada com sucesso!')
        onRefreshAll()
        onClose()
      })
      .catch(err => console.log(err))
      .then(() => setLoadingApprove(false))
  }

  useEffect(() => {
    servicesUser.exec.getDriver({ id: item.userPermissions.id })
      .then(res => {
        setDriver(res)
        setLoadingData(false)
      })
      .catch(err => onClose())
  }, [item, onClose, servicesUser])

  return (
    <div className="time-setting-detail">
      <div className="user">
        <div className="user-detail">
          <div className="user-avatar">
            <img
              src={
                item.userPermissions?.avatarImage?.url
                  ? format.urlFile(item.userPermissions?.avatarImage?.url)
                  : avatar
              }
              alt={item.userPermissions.name}
            />
          </div>
          <h3 className="user-name">
            {item.userPermissions.name}
            <br />
            #{item.id}
          </h3>
        </div>
      </div>

      {
        loadingData ? (
          <div className="empty-state">
            <Spinner size="lg" color="dark" />
          </div>
        ) : (
          <>
            <Row className="mt-4">
              <Col xs="6">
                <Col xs="12" className="d-flex flex-row align-items-center mb-2">
                  <Icon name="timeSetting" color="primary" />
                  <h5 className="text-primary fw-bold ms-2 mb-0">Configuração atual</h5>
                </Col>
                <Col xs="12">
                  <Input
                    label="Categoria"
                    value={driver?.profileCategory?.name}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <Input
                    label="Turno"
                    value={driver?.profileShifts?.[0]?.name}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <Input
                    label="Carga horária"
                    value={(driver?.workHourStart && driver?.workHourEnd) ? `${DateUtils.hourMinutes(driver?.workHourStart)} - ${DateUtils.hourMinutes(driver?.workHourEnd)}` : ''}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <Input
                    label="Horário de refeição"
                    value={(driver?.lunchStart && driver?.lunchEnd) ? `${DateUtils.hourMinutes(driver?.lunchStart)} - ${DateUtils.hourMinutes(driver?.lunchEnd)}` : ''}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <p className="text-black text-medium mt-2">Dias de trabalho</p>
                </Col>
                {
                  weekDays.map((_item, key) => (
                    <Col xs="12" key={key}>
                      <Checkbox
                        label={_item}
                        checked={driver?.profileWorkDays ? driver.profileWorkDays.find(__item => __item.name === _item) : false}
                        disabled
                      />
                    </Col>
                  ))
                }
              </Col>
              <Col xs="6">
                <Col xs="12" className="d-flex flex-row align-items-center mb-2">
                  <Icon name="timeSetting" color="primary" />
                  <h5 className="text-primary fw-bold ms-2 mb-0">Solicitação de ajuste</h5>
                </Col>
                <Col xs="12">
                  <Input
                    label="Categoria"
                    value={item?.profileCategory?.name}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <Input
                    label="Turno"
                    value={item?.profileShifts?.[0]?.name}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <Input
                    label="Carga horária"
                    value={(item?.workHourStart && item?.workHourEnd) ? `${DateUtils.hourMinutes(item?.workHourStart)} - ${DateUtils.hourMinutes(item?.workHourEnd)}` : ''}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <Input
                    label="Horário de refeição"
                    value={(item?.lunchStart && item?.lunchEnd) ? `${DateUtils.hourMinutes(item?.lunchStart)} - ${DateUtils.hourMinutes(item?.lunchEnd)}` : ''}
                    disabled
                  />
                </Col>
                <Col xs="12">
                  <p className="text-black text-medium mt-2">Dias de trabalho</p>
                </Col>
                {
                  weekDays.map((_item, key) => (
                    <Col xs="12" key={key}>
                      <Checkbox
                        label={_item}
                        checked={item?.profileWorkDays ? item.profileWorkDays.find(__item => __item.name === _item) : false}
                        disabled
                      />
                    </Col>
                  ))
                }
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs="12" className="text-end">
                <Button
                  outline
                  label="Reprovar"
                  color="primary"
                  loading={loadingDecline}
                  onClick={() => onDecline()}
                  className="me-2 px-5"
                />

                <Button
                  label="Aprovar"
                  color="primary"
                  loading={loadingApprove}
                  onClick={() => onApprove()}
                  className="px-5"
                />
              </Col>
            </Row>
          </>
        )
      }

    </div>
  )
}

export default Detail
