import React from 'react'

import Icon from '~/components/Icon'

import './styles.scss'

const SideContent = ({ opened, onClose, large, children }) => {
  return (
    <div
      className={`side-content-overlay ${opened ? 'opened' : ''}`}
      onClick={() => onClose?.()}
    >
      <div
        className={`side-content-wrapper ${large ? 'large' : ''} ${opened ? 'opened' : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="d-flex justify-content-end">
          <div
            className="side-close"
            onClick={() => onClose?.()}
          >
            <Icon
              name="close"
              color="secondary"
              size="larger"
            />
          </div>
        </div>

        <div className="side-content">
          { children }
        </div>
      </div>
    </div>
  )
}

export default SideContent
