import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Spinner } from 'reactstrap'

import LayoutTerms from '~/components/LayoutTerms'

import { AppContext } from '~/contexts'

const TermsOfUse = () => {
  const servicesApp = useContext(AppContext)

  const [terms, setTerms] = useState(null)

  const getData = async () => {
    servicesApp.exec.getTerms()
      .then(res => {
        if(typeof res === 'string') {
          setTerms(res)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(getData, [getData])

  return (
    <LayoutTerms>
      <Row>
        <Col xs="12">
          {
            terms ? (
              <pre>
                { terms }
              </pre>
            ) : (
              <div className="text-center mt-5">
                <Spinner size="lg" color="dark" />
              </div>
            )
          }
        </Col>
      </Row>
    </LayoutTerms>
  )
}

export default TermsOfUse
