import React from 'react'
import { Spinner } from 'reactstrap'
import Chart from 'react-apexcharts'

import './styles.scss'

const CardChart = ({ title, loading, labels, series }) => {
  const options = {
    chart: {
      id: 'hours',
      toolbar: {
        show: false,
      }
    },
    grid: {
      show: true
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        barHeight: 40,
        horizontal: true
      }
    },
    colors: ['#7690D3'],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#1D306D']
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex]
      },
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    },
    xaxis: {
      categories: labels
    }
  }

  const optionsSeries = [
    {
      data: series
    }
  ]

  return (
    <div className="card-chart">
      <div className="card-chart-header">
        <h4 className="card-title">
          {title}
        </h4>
      </div>
      <div className="card-chart-content">
        {
          loading ? (
              <div className="empty-state">
                <Spinner size="lg" color="dark" />
              </div>
          ) : (
              <Chart
                options={options}
                series={optionsSeries}
                type="bar"
                width="100%"
                height="300"
              />
          )
        }
      </div>
    </div>
  )
}

export default CardChart
