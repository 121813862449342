import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'

import Button from '~/components/Button'
import Input from '~/components/Input'

import avatar from '~/images/avatar.jpg'

import { exportData, format, DateUtils } from '~/utils'

import './styles.scss'

const Detail = ({ content }) => {
  const [item] = useState(content)

  const handleExport = () => {
    exportData(item, `falta-${item.userPermissions.name.replaceAll(' ', '_')}-${format.dateToApi(new Date(item.created_at))}`)
  }

  return (
    <div className="absence-detail">
      <div className="user">
        <div className="user-detail">
          <div className="user-avatar">
            <img
              src={
                item.userPermissions?.avatarImage?.url
                  ? format.urlFile(item.userPermissions?.avatarImage?.url)
                  : avatar
              }
              alt={item.userPermissions.name}
            />
          </div>
          <h3 className="user-name">
            {item.userPermissions.name}
            <br />
            #{item.id}
          </h3>
        </div>
        <div className="user-action">
          <Button
            outline
            label="Exportar"
            icon="download"
            color="primary"
            onClick={() => handleExport()}
          />
        </div>
      </div>

      <Row>
        <Col xs="6">
          <Input
            label="CPF"
            value={format.cpf(item.userPermissions?.cpf || '')}
            disabled
          />

        </Col>
        <Col xs="6">
          <Input
            label="Email"
            value={item.userPermissions?.email}
            disabled
          />
        </Col>
        <Col xs="6">
          <Input
            label="Data"
            value={DateUtils.fullDate(new Date(item.created_at))}
            disabled
          />

        </Col>
        <Col xs="6">
          <Input
            label="Ocorrência"
            value={item.doctor ? 'Médica' : 'Outras' }
            disabled
          />
        </Col>
        <Col xs="12">
          <Input
            textarea
            label="Ocorrência"
            value={item?.description}
            disabled
          />
        </Col>
      </Row>

      <Row className="docs">
        <Col xs="12">
          <p className="text-black text-medium">Anexos</p>
        </Col>
        <Col xs="12">
          {
            item.documents?.length ? (
              item.documents.map((_item, key) => (
                <Col xs="12" key={key} className="doc-item">
                  <div className="doc-label">
                    {_item.label}
                  </div>
                  <div className="detail">
                    Tipo de arquivo: {_item.file.ext.replace('.', '').toUpperCase()}
                  </div>
                  <div className="detail">
                    Enviado em: {DateUtils.formatDate(new Date(_item.file.created_at), "dd/MM/yyyy 'às' HH:mm")}
                  </div>
                  <a
                    href={format.urlFile(_item.file.url)}
                    download={_item.file.name}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      label="Baixar documento"
                      color="primary"
                      icon="download"
                    />
                  </a>
                </Col>
              ))
            ) : (
              <Col xs="12">Nenhum documento enviado pelo motorista!</Col>
            )
          }
        </Col>
      </Row>
    </div>
  )
}

export default Detail
