import absence from './absence'
import arrowDown from './arrowDown'
import arrowRight from './arrowRight'
import camera from './camera'
import check from './check'
import dashboard from './dashboard'
import download from './download'
import driver from './driver'
import gift from './gift'
import help from './help'
import journey from './journey'
import locationDuo from './locationDuo'
import locationPin from './locationPin'
import phone from './phone'
import report from './report'
import search from './search'
import timeSetting from './timeSetting'
import training from './training'
import trash from './trash'
import upload from './upload'
import more from './more'
import play from './play'
import close from './close'
import pin from './pin'
import person from './person'
import chart from './chart'

const Icons = {
  absence,
  arrowDown,
  arrowRight,
  camera,
  check,
  dashboard,
  download,
  driver,
  gift,
  help,
  journey,
  locationDuo,
  locationPin,
  phone,
  report,
  search,
  timeSetting,
  training,
  trash,
  upload,
  more,
  play,
  close,
  pin,
  person,
  chart
}

export default Icons
