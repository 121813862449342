import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import RouteNonLogged from './RouteNonLogged'
import RouteLogged from './RouteLogged'

import AuthLogin from '~/screens/Auth/Login'
import AuthForgotPassword from '~/screens/Auth/ForgotPassword'
import AuthResetPassword from '~/screens/Auth/ResetPassword'

import Dashboard from '~/screens/Dashboard'
import Companies from '~/screens/Companies'
import Driver from '~/screens/Driver'
import Journey from '~/screens/Journey'
import Report from '~/screens/Report'
import TimeSetting from '~/screens/TimeSetting'
import Absence from '~/screens/Absence'
import Help from '~/screens/Help'
import Training from '~/screens/Training'
import Gift from '~/screens/Gift'

import GiftRules from '~/screens/GiftRules'

import TermsOfUse from '~/screens/Terms/TermsOfUse'
import PrivacyPolicy from '~/screens/Terms/PrivacyPolicy'
import Support from '~/screens/Terms/Support'
import Chart from '../screens/Chart'

const Navigation = () => (
  <BrowserRouter basename="/">
    <Switch>

      <RouteNonLogged exact path="/auth/login" component={AuthLogin} />
      <RouteNonLogged exact path="/auth/recuperar-senha" component={AuthForgotPassword} />
      <RouteNonLogged exact path="/auth/redefinir-senha/:code" component={AuthResetPassword} />

      <RouteLogged exact path="/" component={Dashboard} />
      <RouteLogged exact path="/empresas" component={Companies} role={[3]} />
      <RouteLogged exact path="/motoristas" component={Driver} />
      <RouteLogged exact path="/jornadas" component={Journey} />
      <RouteLogged exact path="/relatorio" component={Chart} />
      <RouteLogged exact path="/horas" component={Report} />
      <RouteLogged exact path="/ajuste-de-horas" component={TimeSetting} />
      <RouteLogged exact path="/faltas" component={Absence} />
      <RouteLogged exact path="/ocorrencias" component={Help} />
      <RouteLogged exact path="/treinamentos" component={Training} role={[3]} />
      <RouteLogged exact path="/brindes" component={Gift} role={[3]} />

      <RouteLogged exact path="/brindes/regras" component={GiftRules} />

      <Route exact path="/termos-de-uso" component={TermsOfUse} />
      <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />
      <Route exact path="/suporte" component={Support} />

      <Route exact path="/*" component={() => <Redirect to="/" />} />

    </Switch>
  </BrowserRouter>
)

export default Navigation
