import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import qs from 'qs'

import LayoutMain from '~/components/LayoutMain'
import PageTitle from '~/components/PageTitle'
import Table from '~/components/Table'
import Input from '~/components/Input'
import InputSelect from '~/components/InputSelect'
import Pagination from '~/components/Pagination'
import SideContent from '~/components/SideContent'

import { UserContext } from '~/contexts'
import { paginationOptions } from '~/data'
import { format } from '~/utils'

import Detail from './Detail'

const Chart = () => {
  const servicesUser = useContext(UserContext)

  const [loading, setLoading] = useState(true)

  const [items, setItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [selectedItem, setSelectedItem] = useState(null)

  const [filter, setFilter] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const columnsTable = [
    {
      label: 'ID',
      cell: row => (
        <span>
          #{row.id}
        </span>
      )
    },
    {
      label: 'Nome',
      selector: 'name'
    },
    {
      label: 'Email',
      selector: 'email'
    },
    {
      label: 'CPF',
      cell: row => (
        <span>
          {row?.cpf ? format.cpf(row.cpf) || 'Não informado' : 'Não informado'}
        </span>
      )
    }
  ]

  const getData = useCallback(async ({ newPage, newItemsPerPage }) => {
    setLoading(true)

    if (newPage >= 0 && newPage !== null) {
      setPage(newPage)
    }

    if (newItemsPerPage >= 0 && newItemsPerPage !== null) {
      setItemsPerPage(newItemsPerPage)
    }

    let paramsFilter = []

    if (filter) {
      paramsFilter = [
        { name_contains: filter },
        { cpf_contains: filter },
        { id: filter }
      ]
    }

    const paramsPagination = {
      _start: Math.ceil(((newPage >= 0 && newPage !== null) ? newPage : page) * ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage)),
      _limit: ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage)
    }

    const query = qs.stringify({ _where: { _or: paramsFilter }, ...paramsPagination })
    const params = { filter: query }

    const count = await servicesUser.exec.countDrivers(params)
    if (!count?.errMessage) {
      setTotalItems(count)

      if (count > 0) {
        const response = await servicesUser.exec.getDrivers(params)
        if (!response?.errMessage) {
          setItems(response)
        } else {
          setItems([])
        }
      } else {
        setItems([])
      }
    } else {
      setTotalItems(0)
      setItems([])
    }

    setLoading(false)
  }, [servicesUser, page, itemsPerPage, filter])

  const handleChangePage = ({ selected }) => {
    getData({ newPage: selected })
  }

  const handleChangePageQuantity = (selected) => {
    getData({ newItemsPerPage: Number(selected) })
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      getData({ newPage: 0 })
    }, 500)

    return () => clearTimeout(delay)
    // eslint-disable-next-line
  }, [filter])

  return (
    <LayoutMain>
      <PageTitle />

      <Row>
        <Col xs="3">
          <Input
            label="Pesquisar (ID, nome ou CPF)"
            placeholder="Procurar motoristas"
            value={filter}
            onChange={setFilter}
            icon="search"
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table
            columns={columnsTable}
            items={items}
            loading={loading}
            onRowClick={row => setSelectedItem(row)}
          />
        </Col>
      </Row>

      {
        (!loading && totalItems) ? (
          <Row className="mt-3">
            <Col xs="3" className="d-flex align-items-center text-gray-dark">
              Exibindo
              <div className="mx-2">
                <InputSelect
                  items={paginationOptions}
                  value={itemsPerPage}
                  onChange={handleChangePageQuantity}
                />
              </div>
              de {totalItems}
            </Col>
            <Col xs="9" className="d-flex justify-content-end align-items-center">
              <Pagination
                currentPage={page}
                pageCount={Math.ceil(totalItems / itemsPerPage)}
                onChange={handleChangePage}
              />
            </Col>
          </Row>
        ) : null
      }

      <SideContent
        opened={!!selectedItem}
        large
        onClose={() => setSelectedItem(null)}
      >
        {
          selectedItem &&
          <Detail
            content={selectedItem}
            onRefreshAll={() => getData(0)}
            onClose={() => setSelectedItem(null)}
          />
        }
      </SideContent>
    </LayoutMain>
  )
}

export default Chart
