import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import qs from 'qs'

import LayoutMain from '~/components/LayoutMain'
import PageTitle from '~/components/PageTitle'
import Button from '~/components/Button'
import Table from '~/components/Table'
import Input from '~/components/Input'
import InputSelect from '~/components/InputSelect'
import Pagination from '~/components/Pagination'
import SideContent from '~/components/SideContent'

import { JourneyContext } from '~/contexts'
import { paginationOptions } from '~/data'
import { exportData, format } from '~/utils'

import Detail from './Detail'

const Companies = () => {
  const servicesJourney = useContext(JourneyContext)

  const [loading, setLoading] = useState(true)
  const [loadingExport, setLoadingExport] = useState(false)

  const [items, setItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [editItem, setEditItem] = useState(null)
  const [createItem, setCreateItem] = useState(false)

  const [filter, setFilter] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const columnsTable = [
    {
      label: 'ID',
      cell: row => (
        <span>
          #{row.id}
        </span>
      )
    },
    {
      label: 'Nome fantasia',
      selector: 'fantasyName'

    },
    {
      label: 'CNPJ',
      cell: row => (
        <span>
          {format.cnpj(row.cnpj)}
        </span>
      )
    },
    {
      label: 'Código',
      selector: 'code'

    }
  ]

  const getData = useCallback(async ({ newPage, newItemsPerPage }) => {
    setLoading(true)

    if (newPage >= 0 && newPage !== null) {
      setPage(newPage)
    }

    if (newItemsPerPage >= 0 && newItemsPerPage !== null) {
      setItemsPerPage(newItemsPerPage)
    }

    let paramsFilter = []

    if (filter) {
      paramsFilter = [
        { 'id': filter },
        { 'code': filter },
        { 'legalName_contains': filter },
        { 'fantasyName_contains': filter },
        { 'cnpj_contains': filter }
      ]
    }

    const moreParams = {
      _start: Math.ceil(((newPage >= 0 && newPage !== null) ? newPage : page) * ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage)),
      _limit: ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage)
    }

    const query = qs.stringify({
      _where: { _or: paramsFilter },
      ...moreParams
    })
    const params = { filter: query }

    const count = await servicesJourney.exec.countCompanies(params)
    if (!count?.errMessage) {
      setTotalItems(count)

      if (count > 0) {
        const response = await servicesJourney.exec.getCompanies(params)
        if (!response?.errMessage) {
          setItems(response)
        } else {
          setItems([])
        }
      } else {
        setItems([])
      }
    } else {
      setTotalItems(0)
      setItems([])
    }

    setLoading(false)
  }, [servicesJourney, page, itemsPerPage, filter])

  const handleChangePage = ({ selected }) => {
    getData({ newPage: selected })
  }

  const handleChangePageQuantity = (selected) => {
    getData({ newItemsPerPage: Number(selected) })
  }

  const handleExport = () => {
    setLoadingExport(true)

    servicesJourney.exec.getCompanies()
      .then(res => {
        exportData(res, 'empresas')
      })
      .catch(err => console.log(err))
      .then(() => setLoadingExport(false))
  }

  const onCloseSideContent = () => {
    setEditItem(null)
    setCreateItem(false)
  }

  const onRefreshSideContent = selected => {
    getData({ newPage: 0 })
    setEditItem(selected)
    setCreateItem(false)
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      getData({ newPage: 0 })
    }, 500)

    return () => clearTimeout(delay)
    // eslint-disable-next-line
  }, [filter])

  return (
    <LayoutMain>
      <PageTitle />

      <Row>
        <Col xs="3">
          <Input
            label="Pesquisar (ID, Nome, CNPJ ou Código)"
            placeholder="Procurar empresas"
            value={filter}
            onChange={setFilter}
            icon="search"
          />
        </Col>
        <Col xs="9" className="text-end d-flex justify-content-end align-items-end pb-2">
          <Button
            outline
            label="Adicionar"
            icon="more"
            color="primary"
            loading={loadingExport}
            onClick={() => setCreateItem(true)}
            className="me-3"
          />
          <Button
            outline
            label="Exportar"
            icon="download"
            color="primary"
            loading={loadingExport}
            onClick={() => handleExport()}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table
            columns={columnsTable}
            items={items}
            loading={loading}
            onRowClick={row => setEditItem(row)}
          />
        </Col>
      </Row>
      {
        (!loading && totalItems) ? (
          <Row className="mt-3">
            <Col xs="3" className="d-flex align-items-center text-gray-dark">
              Exibindo
              <div className="mx-2">
                <InputSelect
                  items={paginationOptions}
                  value={itemsPerPage}
                  onChange={handleChangePageQuantity}
                />
              </div>
              de {totalItems}
            </Col>
            <Col xs="9" className="d-flex justify-content-end align-items-center">
              <Pagination
                currentPage={page}
                pageCount={Math.ceil(totalItems / itemsPerPage)}
                onChange={handleChangePage}
              />
            </Col>
          </Row>
        ) : null
      }

      <SideContent
        opened={!!editItem || createItem}
        onClose={() => onCloseSideContent()}
      >
        {
          (editItem?.id || createItem) &&
          <Detail
            content={editItem}
            createItem={createItem}
            onRefresh={res => onRefreshSideContent(res)}
          />
        }
      </SideContent>
    </LayoutMain>
  )
}

export default Companies
