export const screens = [
  {
    icon: 'dashboard',
    label: 'Painel Geral',
    path: '/'
  },
  {
    icon: 'driver',
    label: 'Empresas',
    path: '/empresas',
    role: [3]
  },
  {
    icon: 'person',
    label: 'Motoristas',
    path: '/motoristas'
  },
  {
    icon: 'journey',
    label: 'Jornadas',
    path: '/jornadas'
  },
  {
    icon: 'chart',
    label: 'Relatório',
    path: '/relatorio'
  },
  {
    icon: 'report',
    label: 'Horas',
    path: '/horas'
  },
  {
    icon: 'timeSetting',
    label: 'Ajuste de Horas',
    path: '/ajuste-de-horas'
  },
  {
    icon: 'absence',
    label: 'Faltas',
    path: '/faltas'
  },
  {
    icon: 'help',
    label: 'S.O.S',
    path: '/ocorrencias'
  },
  {
    icon: 'training',
    label: 'Treinamentos',
    path: '/treinamentos',
    role: [3]
  },
  {
    icon: 'gift',
    label: 'Brindes',
    path: '/brindes',
    role: [3]
  },
]
