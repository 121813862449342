import React from 'react'

import logo from '~/images/logo.png'

import './styles.scss'

const LayoutTerms = ({ children }) => (
  <div className="terms-layout">
    <header>
      <div className="container header-content">
        <div>
          <img src={logo} alt="Bino" className="logo" />
        </div>
      </div>
    </header>
    <div className="terms-content container">
      {children}
    </div>
  </div>
)

export default LayoutTerms
