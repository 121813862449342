import React from 'react'

import './styles.scss'

const InputSelect = ({
  className,
  id,
  label,
  name,
  onChange,
  placeholder,
  required,
  theme = 'light',
  items = [],
  value,
  disabled,
  onValidate = [],
  ...rest
}) => {
  const errorMessage = onValidate.reduce((hasError, condition) => {
    return condition(value, hasError) || hasError
  }, false)

  return (
    <div className={`input-select input-${theme} ${className || ''}`}>
      {
        label &&
        <label htmlFor={id}>{ label }</label>
      }

      <select
        id={id}
        name={name}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        value={value || ''}
        onChange={e => onChange(e.target.value)}
      >
        {
          items.map((item, key) => (
            <option value={item.value} key={key}>
              { item.label }
            </option>
          ))
        }
      </select>

      {
        (errorMessage && value !== '')
          ? <div className="error-message">{ errorMessage }</div>
          : null
      }
    </div>
  )
}

export default InputSelect
