import React from 'react'

const help = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={`size-${size}`}>
    <g id="Group_9838" data-name="Group 9838" transform="translate(5186 7813)">
      <path id="Path_12048" data-name="Path 12048" d="M0,0H22V22H0Z" transform="translate(-5186 -7813)" fill="none"/>
      <path id="Union_33" data-name="Union 33" d="M5107,6094a1,1,0,0,1-1-1v-5h-5a1,1,0,0,1-1-1v-4a1,1,0,0,1,1-1h5v-5a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v5h5a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1h-5v5a1,1,0,0,1-1,1Z" transform="translate(-10284 -13887)" className={`fill-${color}`}/>
    </g>
  </svg>
)

export default help
