import { STORAGE_KEY } from '~/config'

const getItem = key => {
  try {
    return localStorage.getItem(`${STORAGE_KEY}${key}`)
  } catch (e) {
    return sessionStorage.getItem(`${STORAGE_KEY}${key}`)
  }
}

export const Storage = {
  save: ({ key, value }) => {
    try {
      return localStorage.setItem(`${STORAGE_KEY}${key}`, JSON.stringify(value))
    } catch(e) {
      return sessionStorage.setItem(`${STORAGE_KEY}${key}`, JSON.stringify(value))
    }
  },

  get: ({ key }) => JSON.parse(getItem(key)),

  delete: ({ key }) => {
    try {
      return localStorage.setItem(`${STORAGE_KEY}${key}`, null)
    } catch (e) {
      return sessionStorage.setItem(`${STORAGE_KEY}${key}`, null)
    }
  }
}
