import React from 'react'
import { Row, Col } from 'reactstrap'
import GoogleMapReact from 'google-map-react'

import Icon from '~/components/Icon'

import { API_KEY_GOOGLE_MAPS } from '~/config'
import { format, DateUtils, numerize } from '~/utils'

import avatar from '~/images/avatar.jpg'

import './styles.scss'

const DashboardMap = ({ item }) => {
  const getTotalJourneyTime = () => {
    let total = 0

    for (let i = 0; i < item.steps.length; i++) {
      total += ((item.steps[i]?.ends_at || new Date().getTime()) - item.steps[i].begins_at)
    }

    if(total > 0) {
      const parsedTotal = DateUtils.intervalToDuration({ end: total })
      return `${numerize(parsedTotal.hours)}h${numerize(parsedTotal.minutes)}`
    } else {
      return '0h'
    }
  }

  return (
    <div className="dashboard-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY_GOOGLE_MAPS }}
        defaultCenter={{
          lat: item.steps[0]?.locationBegin?.latitude,
          lng: item.steps[0]?.locationBegin?.longitude,
        }}
        defaultZoom={11}
      >
        {
          item.locationsCoords.map((_item, _key) => (
            <Icon
              lat={_item?.latitude}
              lng={_item?.longitude}
              name="pin"
              color="primary"
              key={_key}
            />
          ))
        }
        <Icon
          lat={item.steps[0]?.locationBegin?.latitude}
          lng={item.steps[0]?.locationBegin?.longitude}
          name="locationPin"
          color="primary"
        />
        <Icon
          lat={item.steps[item.steps.length - 1]?.locationEnd?.latitude}
          lng={item.steps[item.steps.length - 1]?.locationEnd?.longitude}
          name="locationPin"
          color="primary"
        />
      </GoogleMapReact>

      <div className="detail">
        <Row>
          <Col xs="8">
            <div className="user-detail">
              <div className="user-avatar">
                <img
                  src={
                    item.userPermissions?.avatarImage?.url
                      ? format.urlFile(item.userPermissions?.avatarImage?.url)
                      : avatar
                  }
                  alt={item.userPermissions.name}
                />
              </div>
              <h3 className="user-name">
                {item.userPermissions.name}
                <br />
                #{item.id}
              </h3>
            </div>
          </Col>
          <Col xs="4">
            <div className="journey-total">
              <h4>
                {
                  item.ends_at ? 'Total' : 'Jornada em curso'
                }
              </h4>
              <h3>
                {
                  getTotalJourneyTime()
                }
              </h3>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DashboardMap
