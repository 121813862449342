import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Row, Col } from 'reactstrap'
import qs from 'qs'
import { toast } from 'react-toastify'

import LayoutMain from '~/components/LayoutMain'
import PageTitle from '~/components/PageTitle'
import Button from '~/components/Button'
import Table from '~/components/Table'
import Input from '~/components/Input'
import DatePicker from '~/components/Datepicker'
import InputSelect from '~/components/InputSelect'
import Pagination from '~/components/Pagination'
import SideContent from '~/components/SideContent'
import moment from 'moment'
import { JourneyContext } from '~/contexts'
import { paginationOptions } from '~/data'
import { exportData, format, DateUtils, formatCreateCostReport } from '~/utils'

import Detail from './Detail'
import Edit from './Edit'
import { generateAddLogModel, generateSascarModel } from '../../utils/generate-xlsx'
import Report from './Report'

const Journey = () => {
  const servicesJourney = useContext(JourneyContext)

  const [loading, setLoading] = useState(true)
  const [loadingExport, setLoadingExport] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [editingItem, setEditingItem] = useState(null)
  const [reportOpen, setReportOpen] = useState(null)

  const [items, setItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)

  const [filter, setFilter] = useState('')
  const [dateFilter, setDateFilter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const columnsTable = [
    {
      label: 'ID',
      cell: row => (
        <span>
          #{row.id}
        </span>
      )
    },
    {
      label: 'Nome',
      cell: row => (
        <span>
          {row.userPermissions.name}
        </span>
      )
    },
    {
      label: 'Email',
      cell: row => (
        <span>
          {row.userPermissions.email}
        </span>
      )
    },
    {
      label: 'CPF',
      cell: row => (
        <span>
          {row?.cpf ? format.cpf(row.cpf) : 'Não informado'}
        </span>
      )
    },
    {
      label: 'Início da jornada',
      cell: row => (
        <span className="text-capitalize">
          {DateUtils.formatDateWithTime(new Date(row.begins_at))}
        </span>
      )
    },
    {
      label: 'Final da jornada',
      cell: row => (
        <span className="text-capitalize">
          {DateUtils.formatDateWithTime(new Date(row.ends_at))}
        </span>
      )
    }
  ]

  const getData = useCallback(async ({ newPage, newItemsPerPage }) => {
    setLoading(true)

    if (newPage >= 0 && newPage !== null) {
      setPage(newPage)
    }

    if (newItemsPerPage >= 0 && newItemsPerPage !== null) {
      setItemsPerPage(newItemsPerPage)
    }

    let paramsFilter = []

    if (filter) {
      paramsFilter = [
        { 'userPermissions.name_contains': filter },
        { 'userPermissions.cpf_contains': filter },
        { 'userPermissions.id': filter }
      ]
    }

    const moreParams = {
      _start: Math.ceil(((newPage >= 0 && newPage !== null) ? newPage : page) * ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage)),
      _limit: ((newItemsPerPage >= 0 && newItemsPerPage !== null) ? newItemsPerPage : itemsPerPage),
      _begins_at_null: false,
      _ends_at_null: false
    }

    if (dateFilter) {
      let endDateFilter = new Date(dateFilter)
      endDateFilter.setDate(endDateFilter.getDate() + 1)

      moreParams['begins_at_gte'] = dateFilter.getTime()
      moreParams['ends_at_lt'] = endDateFilter.getTime()
    }

    const query = qs.stringify({
      _where: { _or: paramsFilter },
      ...moreParams
    })
    const params = { filter: query }

    const count = await servicesJourney.exec.countJourneys(params)
    if (!count?.errMessage) {
      setTotalItems(count)

      if (count > 0) {
        const response = await servicesJourney.exec.getJourneys(params)
        if (!response?.errMessage) {
          setItems(response)
        } else {
          setItems([])
        }
      } else {
        setItems([])
      }
    } else {
      setTotalItems(0)
      setItems([])
    }

    setLoading(false)
  }, [servicesJourney, page, itemsPerPage, filter, dateFilter])

  const handleChangePage = ({ selected }) => {
    getData({ newPage: selected })
  }

  const handleChangePageQuantity = (selected) => {
    getData({ newItemsPerPage: Number(selected) })
  }

  const handleChangeDateFilter = (date) => {
    if (date) {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
    }

    setDateFilter(date)
  }

  const handleExportSecondModel = () => {
    setLoadingExport(true)

    servicesJourney.exec.getJourneys()
      .then(res => {
        // exportData(res, 'jornadas')
        // generateAddLogModel(res)
        generateSascarModel(res, `modelo_2_jornadas_geral_${moment().format('DD-MM-yyyy')}`)
      })
      .catch(err => console.log(err))
      .then(() => setLoadingExport(false))
  }

  const handleReport = () => {
    setReportOpen(true)
  }

  const handleExport = () => {
    setLoadingExport(true)

    servicesJourney.exec.getJourneys()
      .then(res => {
        // exportData(res, 'jornadas')
        generateAddLogModel(res, `modelo_1_jornadas_geral_${moment().format('DD-MM-yyyy')}`)
      })
      .catch(err => console.log(err))
      .then(() => setLoadingExport(false))
  }

  const onEdit = async (body, id) => {
    const response = await servicesJourney.exec.editJourney({ body, id })

    if (response?.id) {
      const moreParams = {
        'journey.id': response.id
      }
      const query = qs.stringify(moreParams)
      const params = { filter: query }

      const costReport = await servicesJourney.exec.getCostReport(params)
      if (costReport?.[0]?.id) {
        const idCost = costReport?.[0]?.id
        const bodyCost = formatCreateCostReport(body)

        await servicesJourney.exec.editCostReport({
          body: {
            ...bodyCost,
            journey: response.id,
            user: response?.userPermissions?.id,
            company: response?.userPermissions?.company
          }, id: idCost
        })
      } else {
        const bodyCost = formatCreateCostReport(body)
        await servicesJourney.exec.newCostReport({
          body: {
            ...bodyCost,
            journey: response.id,
            user: response?.userPermissions?.id,
            company: response?.userPermissions?.company
          }
        })
      }

      toast.success('Jornada atualizada com sucesso!')
      return response
    } else {
      toast.error('Um erro ocorreu ao tentar atualizar a jornada!')
      return null
    }
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      getData({ newPage: 0 })
    }, 500)

    return () => clearTimeout(delay)
    // eslint-disable-next-line
  }, [filter, dateFilter])

  return (
    <LayoutMain>
      <PageTitle />

      <Row>
        <Col xs="3">
          <Input
            label="Pesquisar (ID, nome ou CPF)"
            placeholder="Procurar motoristas"
            value={filter}
            onChange={setFilter}
            icon="search"
          />
        </Col>
        <Col xs="2">
          <DatePicker
            label="Filtrar por dia"
            placeholder="Filtrar jornadas do dia"
            value={dateFilter}
            onChange={handleChangeDateFilter}
            dateFormat="dd MMMM yyyy"
          />
        </Col>
        <Col xs={{ size: 4, offset: 3 }} className="text-end d-flex justify-content-end align-items-end pb-2">
          <div style={{
            display: 'flex',
            columnGap: '10px'
          }}>
            <Button
              outline
              label="Modelo 1"
              icon="download"
              color="primary"
              loading={loadingExport}
              onClick={handleExport}
            />
            <Button
              outline
              label="Modelo 2"
              icon="download"
              color="primary"
              loading={loadingExport}
              onClick={handleExportSecondModel}
            />
            <Button
              outline
              label="Relatório de Tempo de Direção"
              icon="download"
              color="primary"
              loading={loadingExport}
              onClick={handleReport}
            />
          </div>

        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table
            columns={columnsTable}
            items={items}
            loading={loading}
            onRowClick={row => setSelectedItem(row)}
          />
        </Col>
      </Row>
      {
        (!loading && totalItems) ? (
          <Row className="mt-3">
            <Col xs="3" className="d-flex align-items-center text-gray-dark">
              Exibindo
              <div className="mx-2">
                <InputSelect
                  items={paginationOptions}
                  value={itemsPerPage}
                  onChange={handleChangePageQuantity}
                />
              </div>
              de {totalItems}
            </Col>
            <Col xs="9" className="d-flex justify-content-end align-items-center">
              <Pagination
                currentPage={page}
                pageCount={Math.ceil(totalItems / itemsPerPage)}
                onChange={handleChangePage}
              />
            </Col>
          </Row>
        ) : null
      }

      <SideContent
        opened={reportOpen}
        onClose={() => setReportOpen(false)}
      >          
          <Report/>
      </SideContent>

     {/*  <SideContent
        opened={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      >
        {
          selectedItem?.id &&
          <Detail
            content={selectedItem}
            onRefreshAll={() => getData(0)}
            onClose={() => setSelectedItem(null)}
            handleToEdit={() => setEditingItem(selectedItem)}
          />
        }
      </SideContent>

      <SideContent
        opened={!!editingItem}
        onClose={() => setEditingItem(null)}
      >
        {
          editingItem?.id &&
          <Edit
            content={selectedItem}
            onRefreshAll={() => getData(0)}
            onClose={() => { setEditingItem(null); setSelectedItem(null) }}
            onEdit={(body, id) => onEdit(body, id)}
          />
        }
      </SideContent> */}
    </LayoutMain>
  )
}

export default Journey
