import React from 'react'
import { useLocation } from 'react-router-dom'

import Icon from '~/components/Icon'

import { screens } from '~/data'

import './styles.scss'

const PageTitle = ({ icon, title, className }) => {
  const location = useLocation()

  const currentPage = screens.find(item => item.path.match(location.pathname))

  return (
    <div className={`page-title ${ className ? className : '' }`}>
      <Icon name={icon || currentPage?.icon} size="larger" color="secondary" />
      <h1 className="label">
        { title || currentPage?.label }
      </h1>
    </div>
  )
}

export default PageTitle
