import React from 'react'

const pin = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 5" width="10" height="10">
    <defs>
      <clipPath id="clip-path">
        <rect width="5" height="5" fill="none"/>
      </clipPath>
    </defs>
    <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clipPath="url(#clip-path)">
      <g transform="translate(-10902 10243)">
        <g id="Path_12049" data-name="Path 12049" transform="translate(10902 -10243)" className={`fill-${color}`}>
          <path d="M 2.5 4.5 C 1.397199988365173 4.5 0.5 3.602799892425537 0.5 2.5 C 0.5 1.397199988365173 1.397199988365173 0.5 2.5 0.5 C 3.602799892425537 0.5 4.5 1.397199988365173 4.5 2.5 C 4.5 3.602799892425537 3.602799892425537 4.5 2.5 4.5 Z" stroke="none"/>
          <path d="M 2.5 1 C 1.672899961471558 1 1 1.672899961471558 1 2.5 C 1 3.327100038528442 1.672899961471558 4 2.5 4 C 3.327100038528442 4 4 3.327100038528442 4 2.5 C 4 1.672899961471558 3.327100038528442 1 2.5 1 M 2.5 0 C 3.880710124969482 0 5 1.119290113449097 5 2.5 C 5 3.880710124969482 3.880710124969482 5 2.5 5 C 1.119290113449097 5 0 3.880710124969482 0 2.5 C 0 1.119290113449097 1.119290113449097 0 2.5 0 Z" stroke="none" className={`fill-${color}`}/>
        </g>
      </g>
    </g>
  </svg>
)

export default pin
